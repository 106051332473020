import { CloseCircleOutlined } from '@ant-design/icons';
import { useBoolean } from 'ahooks';
import { Button, Card, Form, Input } from 'antd';
import * as Yup from 'yup';
import { Scraper } from '../../generated/graphql';
import { ScraperSelect } from '../ScraperSelect';
import { ValidationSchema } from './ValidationSchema';

export interface RowProps {
  item: {
    query: string;
    scrapers: Array<Scraper>;
  };
  onRemove: () => void;
  onUpdate: (value: RowProps['item']) => void;
}

export function Row(props: RowProps) {
  const [touched, { setTrue: setTouched }] = useBoolean(false);

  function validateQueryField(value: string) {
    try {
      Yup.reach(ValidationSchema, `query`).validateSync(value);
      return false;
    } catch (e) {
      return (e as Error).message;
    }
  }

  function validateScrapersField(value: Array<Scraper>) {
    try {
      Yup.reach(ValidationSchema, `scrapers`).validateSync(value);
      return false;
    } catch (e) {
      return (e as Error).message;
    }
  }

  function onChange(newValue: RowProps['item']) {
    setTouched();
    props.onUpdate(newValue);
  }

  return (
    <Card
      size={'small'}
      extra={[
        <Button type="link" key={`remove-btn`} onClick={props.onRemove}>
          <CloseCircleOutlined />
        </Button>
      ]}
    >
      <Form.Item
        label={'Word or sentence'}
        help={touched && validateQueryField(props.item.query)}
        validateStatus={
          touched && validateQueryField(props.item.query) ? 'error' : 'success'
        }
      >
        <Input
          value={props.item.query}
          onChange={(event) =>
            onChange({
              ...props.item,
              query: event.target.value
            })
          }
          placeholder={'Word or sentence'}
        />
      </Form.Item>
      <Form.Item
        label="Scrapers"
        style={{ width: '50%' }}
        help={touched && validateScrapersField(props.item.scrapers)}
        validateStatus={
          touched && validateScrapersField(props.item.scrapers)
            ? 'error'
            : 'success'
        }
      >
        <ScraperSelect
          value={props.item.scrapers}
          onChange={(scrapers) =>
            onChange({
              ...props.item,
              scrapers
            })
          }
        />
      </Form.Item>
    </Card>
  );
}
