import { Select, SelectProps } from 'antd';
import { TopicState } from '../../generated/graphql';

const options: SelectProps['options'] = [
  {
    label: 'Done',
    value: TopicState.Done
  },
  {
    label: 'In Progress',
    value: TopicState.InProgress
  },
  {
    label: 'Not started',
    value: TopicState.NotStarted
  },
  {
    label: 'On hold',
    value: TopicState.OnHold
  },
  {
    label: 'Not available',
    value: TopicState.Na
  }
];

interface TopicStateSelectorProps {
  value: TopicState;
  onChange(value: TopicState): void;

  loading?: boolean;
}

export function TopicStateSelector(props: TopicStateSelectorProps) {
  return (
    <Select
      style={{ width: 120 }}
      loading={props.loading}
      options={options}
      value={props.value}
      onChange={props.onChange}
    />
  );
}
