import {
  BulbOutlined,
  CloudSyncOutlined,
  CodeOutlined,
  FlagOutlined,
  HomeOutlined,
  LogoutOutlined,
  SettingOutlined
} from '@ant-design/icons';
import { useCreation } from 'ahooks';
import { Divider, Layout, Menu, SubMenuProps, Typography } from 'antd';
import React, { useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Logo } from '../components/Logo';
import { Me } from '../components/Me';
import { useGetStagesQuery } from '../generated/graphql';
import { useLogout } from '../hooks/useLogout';

const { Sider, Content } = Layout;

export function MainPageLayout() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { data: stages } = useGetStagesQuery();
  const logout = useLogout();

  async function handleMenuClick(info: any) {
    navigate(info.key);
  }

  const selectedKeys = useCreation(() => {
    let root: string = pathname.split('/').filter(Boolean)[0];
    root = root === undefined ? `/` : `/${root}`;
    return [root];
  }, [pathname]);

  const menuItems = useMemo(
    () => [
      {
        key: '/',
        icon: <HomeOutlined />,
        label: 'Home'
      },
      {
        key: '/topics',
        icon: <BulbOutlined />,
        label: 'Topics'
      },
      {
        key: 'stages',
        icon: <FlagOutlined />,
        label: 'Stages',
        children: stages?.stages.map(({ title }) => ({
          key: `/stages/${title}`,
          label: title
        }))
      },
      {
        key: '/supermetrics',
        icon: <CloudSyncOutlined />,
        label: 'Supermetrics'
      },
      {
        key: '/settings',
        icon: <SettingOutlined />,
        label: 'Settings'
      },
      {
        key: '',
        icon: <LogoutOutlined />,
        label: 'Logout',
        onClick: logout
      }
    ],
    [stages, logout]
  );

  const subMenuItems = [
    {
      key: 'automation',
      icon: <CodeOutlined />,
      label: 'Automation',
      children: [
        {
          key: 'airflow',
          label: (
            <Typography.Link href="https://airflow.bynyk.com/" target="_blank">
              Airflow
            </Typography.Link>
          )
        },
        {
          key: 'trino',
          label: (
            <Typography.Link href="https://trino.bynyk.com/" target="_blank">
              Trino
            </Typography.Link>
          )
        }
      ]
    }
  ];

  return (
    <>
      <Layout hasSider>
        <Sider
          theme={'light'}
          style={{
            overflow: 'auto',
            minHeight: '100vh',
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '100vh'
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Logo height={100} />

              <Divider key="me-divider-start" style={{ marginTop: 0 }} />

              <Me />

              <Divider key="me-divider-end" />
              <Menu
                onClick={
                  menuItems[0].label === 'Logout' ? logout : handleMenuClick
                }
                theme={'light'}
                mode="inline"
                selectedKeys={selectedKeys}
                items={menuItems}
              />
            </div>
            <Menu
              theme={'light'}
              mode="inline"
              items={subMenuItems}
              expandIcon={(props: SubMenuProps & { isSubmenu: boolean }) =>
                null
              }
            />
          </div>
        </Sider>
      </Layout>
      <Layout style={{ marginLeft: '200px' }}>
        <Content
          style={{
            minHeight: '100vh'
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </>
  );
}
