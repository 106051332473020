import { BulbOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useBoolean } from 'ahooks';
import {
  Button,
  Divider,
  Drawer,
  PageHeader,
  Space,
  Table,
  TableProps,
  Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { GlobalSearchInput } from '../components/GlobalSearchInput';
import { NewTopicForm } from '../components/NewTopicForm';
import {
  GetTopicsQuery,
  Topic,
  TopicSort,
  useGetTopicsQuery
} from '../generated/graphql';
import { useDraftTopics } from '../hooks/useDraftTopics';
import '../index.css';
import { topicFilters, topicFiltersForQuery } from '../state';
import { TopicPage } from './TopicPage';

const tableColumns: ColumnsType<GetTopicsQuery['getTopics']['items'][number]> =
  [
    {
      title: 'Name',
      key: 'id',
      dataIndex: 'title',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (title: Topic['title'], topic) => {
        return <Link to={`/topics/${topic.id}`}>{title}</Link>;
      }
    },
    {
      title: 'Step name',
      dataIndex: 'status',
      sorter: true,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Status',
      dataIndex: 'state',
      sorter: true,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (date: Date) => {
        return new Date(date).toLocaleString();
      }
    }
  ];

export function TopicsPage() {
  const navigate = useNavigate();
  const filtersForQuery = useRecoilValue(topicFiltersForQuery);
  const [
    newTopicDialogVisible,
    { setTrue: showNewTopicDialog, setFalse: hideNewTopicDialog }
  ] = useBoolean(false);
  const [filters, setFilters] =
    useRecoilState<{ type: string; value: any }[]>(topicFilters);
  const { topicId } = useParams<string>();
  const [, { add }] = useDraftTopics();
  const limitTopic: number = 11;
  const [skip, setSkip] = useState(0);
  const [topicSort, setTopicSort] = useState(TopicSort.NameAsc);

  const {
    data,
    loading: fetching,
    refetch
  } = useGetTopicsQuery({
    variables: {
      filter: filtersForQuery,
      sort: topicSort,
      skip: skip,
      limit: limitTopic,
      withAuthor: false
    },
    fetchPolicy: 'no-cache'
  });

  async function newDraft() {
    await add({});
    showNewTopicDialog();
  }

  async function goToNext() {
    if (limitTopic - 1 < data?.getTopics.items.length!) {
      setSkip(skip + (limitTopic - 1));
    }
  }

  function goToPrev() {
    if (skip >= limitTopic - 1) {
      setSkip(skip - (limitTopic - 1));
    }
  }

  const disablePrev = skip === 0;
  const disableNext = data?.getTopics.items.length! <= limitTopic - 1;

  const onChange: TableProps<
    GetTopicsQuery['getTopics']['items'][number]
  >['onChange'] = (pagination, filters, sorter) => {
    const matrix = {
      title: {
        ascend: TopicSort.NameAsc,
        descend: TopicSort.NameDesc
      },
      status: {
        ascend: TopicSort.StatusAsc,
        descend: TopicSort.StatusDesc
      },
      createdAt: {
        ascend: TopicSort.CreationDateAsc,
        descend: TopicSort.CreationDateDesc
      }
    } as Record<
      'title' | 'status' | 'createdAt',
      Record<'ascend' | 'descend', TopicSort>
    >;

    if (!Array.isArray(sorter) && sorter.order !== null) {
      const field = sorter.field as unknown as 'title' | 'status' | 'createdAt';
      const order = sorter.order as unknown as 'ascend' | 'descend';
      setTopicSort(matrix[field][order]);
      setSkip(0);
    }
  };

  function onChangeFilter(values: { type: string; value: any }[]) {
    setFilters(values);
    setSkip(0);
  }

  return (
    <PageHeader
      title={
        <Typography.Title level={2}>
          <Space>
            <BulbOutlined />
            All topics
          </Space>
        </Typography.Title>
      }
      extra={[
        <Button key="add-topic-button" type="primary" onClick={newDraft}>
          New topic
        </Button>
      ]}
    >
      <NewTopicForm
        draftIndex={0}
        key="new-topic-form"
        visible={newTopicDialogVisible}
        onClose={hideNewTopicDialog}
      />
      <Drawer
        open={!!topicId}
        onClose={() => navigate('.')}
        width={window.innerWidth * 0.66}
      >
        {topicId && <TopicPage topicId={topicId} onDeleted={refetch} />}
      </Drawer>
      <GlobalSearchInput onChange={onChangeFilter} values={filters} />
      <Divider dashed />
      <Table
        bordered
        loading={fetching}
        columns={tableColumns}
        dataSource={data?.getTopics.items.slice(0, limitTopic - 1)}
        rowKey={'id'}
        size={'small'}
        pagination={false}
        scroll={{ y: window.innerHeight - 127 }}
        onChange={onChange}
      />
      <Space
        style={{
          marginTop: 25,
          float: 'right'
        }}
      >
        <Button
          icon={<LeftOutlined />}
          onClick={goToPrev}
          className="paginationButton"
          disabled={disablePrev}
        />
        <Button
          icon={<RightOutlined />}
          onClick={goToNext}
          className="paginationButton"
          disabled={disableNext}
        />
      </Space>
    </PageHeader>
  );
}
