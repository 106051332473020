import {Select, SelectProps} from 'antd';

const {Option} = Select;

interface CourseLevelProps
  extends SelectProps<Array<'Beginners' | 'Intermediate' | 'Advanced'>> {}

export function CourseLevel(props: CourseLevelProps) {
  return (
    <Select
      {...props}
      mode="multiple"
      placeholder={'Select course difficult level'}
    >
      <Option value="Beginners">Beginners</Option>
      <Option value="Intermediate">Intermediate</Option>
      <Option value="Advanced">Advanced</Option>
    </Select>
  );
}
