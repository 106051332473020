import { grey } from '@ant-design/colors';
import { SyncOutlined } from '@ant-design/icons';
import { useDrag } from 'ahooks';
import { Card, Checkbox, Space, Tag, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useMemo, useRef, useState } from 'react';
import { ClusterBuilderStatus, GetTopicsQuery } from '../../generated/graphql';
import { UserAvatar } from '../UserAvatar';
import draggerIcon from './dragger.png';
import styles from './Topic.module.css';

interface TopicParams {
  item: GetTopicsQuery['getTopics']['items'][number];
  onSelect: () => void;
  onUnselect: () => void;
  selected: boolean;
  locked: boolean;
}

function ClusterBuilderStatusShow(status: ClusterBuilderStatus) {
  let clusterStatus;
  switch (status) {
    case ClusterBuilderStatus.Processing:
      clusterStatus = (
        <Tag icon={<SyncOutlined spin />} color="processing">
          calculating cluster
        </Tag>
      );
      break;
    case ClusterBuilderStatus.Created:
      clusterStatus = <Tag color="success">cluster created</Tag>;
      break;
    default:
      clusterStatus = null;
      break;
  }
  return clusterStatus;
}

export function Topic({
  item,
  onSelect,
  onUnselect,
  selected,
  locked
}: TopicParams) {
  const dragRef = useRef<any>(null);
  const dragData = locked ? null : [item];
  useDrag(dragData, locked ? null : dragRef);
  const clusterStatus = useMemo(
    () => ClusterBuilderStatusShow(item.clusterBuilderStatus),
    [item.clusterBuilderStatus]
  );
  const [visibleCheckbox, setVisibleCheckbox] = useState(false);

  function showCheckbox() {
    if (locked) return;
    else setVisibleCheckbox(true);
  }

  function hideCheckbox() {
    if (selected) return;
    else setVisibleCheckbox(false);
  }

  function onChange(event: CheckboxChangeEvent) {
    if (!locked) {
      if (event.target.checked) onSelect();
      else onUnselect();
    }
  }

  return (
    <div className={styles.card} ref={dragRef}>
      <Card
        onMouseEnter={showCheckbox}
        onMouseLeave={hideCheckbox}
        hoverable
        style={{
          width: '100%',
          cursor: 'pointer',
          borderColor: grey[0],
          borderRadius: 5,
          paddingTop: '0px'
        }}
        size="small"
        bordered
        bodyStyle={{ padding: '5px' }}
      >
        <Space direction="horizontal" align="start">
          <Checkbox
            onChange={onChange}
            checked={selected}
            onClick={(event) => event.stopPropagation()}
            style={{
              visibility: visibleCheckbox || selected ? 'visible' : 'hidden',
              marginTop: '5px'
            }}
          />
          <Space
            direction="vertical"
            style={{
              margin: '5px',
              backgroundImage: `url${draggerIcon}`,
              display: 'flex',
              justifyContent: 'around-between',
              width: '100%',
              alignItems: 'flex-start',
              marginLeft: '0px'
            }}
            size={0}
          >
            <Typography.Text>{item.title}</Typography.Text>
            <Space
              direction="horizontal"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%'
              }}
              size={15}
            >
              <span
                title={`${item.createdBy?.fullName || 'User'} at ${new Date(
                  item.createdAt
                ).toLocaleString()}`}
              >
                <UserAvatar
                  size={15}
                  src={item.createdBy?.picture}
                  name={
                    item.createdBy?.fullName ? item.createdBy?.fullName : 'User'
                  }
                />
              </span>
              <div>{clusterStatus}</div>
            </Space>
            {/*<Typography.Text italic type="secondary">*/}
            {/*  <TopicStateLabel value={item.state} />*/}
            {/*</Typography.Text>*/}
          </Space>
        </Space>
      </Card>
    </div>
  );
}
