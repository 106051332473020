import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { useBoolean } from 'ahooks';
import {
  Button,
  Card,
  Descriptions,
  List,
  Modal,
  notification,
  Popconfirm
} from 'antd';
import {
  GetTopicDocument,
  KeywordCluster,
  Scalars,
  Topic,
  useCreateClusterMutation,
  useDeleteClusterMutation
} from '../../generated/graphql';
import { ClusterForm, OnSubmitParams } from '../ClusterForm';

export interface ClusterProps {
  topicId: Topic['id'];
  value: KeywordCluster;
  version: number;
}

export function Cluster(props: ClusterProps) {
  const [deleteCluster] = useDeleteClusterMutation();
  const [visibleModal, { setTrue: openCloneDialog, setFalse: hideModal }] =
    useBoolean(false);
  const [create] = useCreateClusterMutation();
  const [creating, { setFalse: unlockModal, setTrue: lockModal }] =
    useBoolean(false);

  function handleRemoveCluster(clusterId: Scalars['ID']['input']) {
    return () =>
      deleteCluster({
        variables: {
          input: {
            clusterId
          }
        },
        refetchQueries: [
          {
            query: GetTopicDocument,
            variables: {
              id: props.topicId
            }
          }
        ],
        onError: (error) =>
          notification.error({
            message: error
          })
      });
  }

  async function createCluster(keywords: OnSubmitParams) {
    lockModal();
    await create({
      variables: {
        input: {
          topic: props.topicId,
          keywords
        }
      },
      refetchQueries: [GetTopicDocument],
      onError: (error) => {
        notification.error({
          message: error.message
        });
      },
      onCompleted: () => {
        hideModal();
        unlockModal();
      }
    });
  }

  return (
    <Card
      title={`Version: ${props.version}`}
      extra={[
        <Button key="copy-button" onClick={openCloneDialog}>
          <CopyOutlined />
        </Button>,
        <Popconfirm
          key="delete-button"
          title="Are you sure to delete this cluster?"
          onConfirm={handleRemoveCluster(props.value.id)}
          okText="Yes"
          cancelText="No"
        >
          <Button key="remove-btn" style={{ color: 'red' }}>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      ]}
    >
      <Descriptions>
        <Descriptions.Item label={'Created at'}>
          {props.value.version}
        </Descriptions.Item>
      </Descriptions>
      <List
        dataSource={props.value.keywords}
        renderItem={(item) => {
          return (
            <List.Item title={item.title}>
              <List.Item.Meta
                description={`Scrapers: ${item.scrapers
                  .join(', ')
                  .toLowerCase()}`}
                title={item.title}
              />
            </List.Item>
          );
        }}
      />

      <Modal
        open={visibleModal}
        title={'Create cluster'}
        onCancel={hideModal}
        footer={null}
        width={'50%'}
        destroyOnClose
      >
        <ClusterForm
          disabled={creating}
          onSubmit={createCluster}
          initialValues={props.value.keywords.map((kw) => ({
            query: kw.title,
            scrapers: kw.scrapers
          }))}
        />
      </Modal>
    </Card>
  );
}
