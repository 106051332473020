import { Space, Typography } from 'antd';
import { useMeQuery } from '../../generated/graphql';
import { UserAvatar } from '../UserAvatar';

export function Me() {
  const { data, loading } = useMeQuery();

  if (loading) {
    return <UserAvatar name={'IAM'} />;
  }

  return (
    <Space>
      <Typography.Text strong>Hi 👋, {data?.me?.firstName}!</Typography.Text>
      <UserAvatar
        src={data?.me?.picture}
        name={`${data?.me?.firstName[0]} ${data?.me?.lastName[0]}`}
      />
    </Space>
  );
}
