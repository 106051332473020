import {Button, Col, Form, Input, Modal, notification, Row, Space, Typography} from 'antd';
import {MeDocument, useMeQuery, useUpdateUserProfileMutation} from '../../generated/graphql';
import {useLogout} from '../../hooks/useLogout';

export function ConfirmProfileInfoDialog() {
  const {
    data,
    loading
  } = useMeQuery();

  const [updateUserProfile, {loading: updating}] =
    useUpdateUserProfileMutation();
  const logout = useLogout();

  function handleConfirmation(values: any) {
    updateUserProfile({
      variables: {
        input: values
      },
      refetchQueries: [
        {
          query: MeDocument
        }
      ]
    })
      .then(() => notification.info({message: 'Thanks'}))
      .catch(() =>
        notification.error({message: 'Something goes wrong. Please retry.'})
      );
  }

  if (loading) {
    return null;
  }

  return (
    <Modal open={!data?.me?.created} footer={false} closable={false}>
      <Typography.Title level={3}>Welcome to TMS</Typography.Title>
      <Typography.Paragraph>
        Please, review and confirm your info{' '}
      </Typography.Paragraph>
      <Form
        onFinish={handleConfirmation}
        initialValues={data?.me || {}}
        autoComplete="off"
      >
        <Form.Item
          label={'First name'}
          name={'firstName'}
          rules={[
            {
              required: true,
              message:  'Please input your First name!'
            }
          ]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label={'Last name'}
          name={'lastName'}
          rules={[
            {
              required: true,
              message:  'Please input your Last name!'
            }
          ]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label={'Email'}
          name={'email'}
          rules={[
            {
              required: true,
              message:  'Please input your Email!'
            }
          ]}
        >
          <Input disabled/>
        </Form.Item>
        <Row justify={'center'}>
          <Col>
            <Space>
              <Button
                disabled={updating}
                type={'primary'}
                size={'large'}
                htmlType="submit"
              >
                All fine
              </Button>
            </Space>
          </Col>
          <Col>
            <Space>
              <Button
                disabled={updating}
                type={'ghost'}
                size={'large'}
                onClick={logout}
              >
                Logout
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
