import { ID } from '@bynyk/domain';
import { Button, message, notification, Popconfirm } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  GetTopicsDocument,
  useDeleteTopicMutation
} from '../../generated/graphql';
import { topicFiltersForQuery } from '../../state';

interface DeleteTopicProps {
  topicId: ID;
  onDeleted?: () => void;
}

export function DeleteTopic(props: DeleteTopicProps) {
  const navigate = useNavigate();
  const filtersForQuery = useRecoilValue(topicFiltersForQuery);
  const [deleteTopic] = useDeleteTopicMutation({
    onCompleted: () => {
      props.onDeleted?.();
    },
    onError: (error) => {
      notification.error({ message: error.message });
    }
  });
  async function handleConfirm(id: string) {
    try {
      await deleteTopic({
        variables: { id: id },
        refetchQueries: [
          {
            query: GetTopicsDocument,
            variables: {
              filter: filtersForQuery,
              withAuthor: false
            }
          }
        ]
      });
      await message.success('Topic deleted');
      navigate(-1);
    } catch (e) {
      console.error(e);
      notification.error({ message: "Can't delete topic." });
    }
  }

  return (
    <Popconfirm
      key="Are you sure want to delete topic?"
      title="Are you sure want to delete topic?"
      okText="Delete"
      placement="right"
      onConfirm={async () => {
        await handleConfirm(props.topicId);
      }}
    >
      <Button type="primary" danger>
        Delete topic
      </Button>
    </Popconfirm>
  );
}
