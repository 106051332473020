import { Statistic } from 'antd';
import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import {
  TopicsCountPeriod,
  useGetTopicsCountQuery
} from '../../generated/graphql';
import { topicFilters } from '../../state';

export function TotalTopics() {
  const setFilter = useSetRecoilState(topicFilters);
  const { data, loading } = useGetTopicsCountQuery({
    variables: {
      period: TopicsCountPeriod.All
    }
  });

  function applyFilter() {
    setFilter([]);
  }

  return (
    <Link to="/topics/" onClick={applyFilter}>
      <Statistic
        loading={loading}
        title={'Topics count'}
        value={data?.topicsCount}
      />
    </Link>
  );
}
