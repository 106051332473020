import { SupermetricsTask } from '@bynyk/domain';
import { DatePicker, Modal, ModalProps, notification } from 'antd';
import {
  useGetSupermetricsTaskLazyQuery,
  useRunSupermetricsTaskNowMutation
} from '../../generated/graphql';
import { useEffect, useMemo, useState } from 'react';

export interface RustTaskNowModalProps extends ModalProps {
  taskId?: SupermetricsTask['id'];
  onOk?: () => void;
}

export function RunTaskNowModal(props: RustTaskNowModalProps) {
  const [fetch, { data, loading }] = useGetSupermetricsTaskLazyQuery();
  const [run] = useRunSupermetricsTaskNowMutation({
    onCompleted: () => {
      notification.success({
        message: 'Task has been scheduled'
      });
      props.onOk?.();
    },
    onError: (error) => {
      notification.error({
        message: 'Task has not been scheduled',
        description: error.message
      });
    }
  });
  const [dateRange, setDateRange] = useState<
    [Date | undefined, Date | undefined]
  >([new Date(), new Date()]);

  useEffect(() => {
    if (props.taskId) {
      fetch({
        variables: {
          id: props.taskId
        }
      });
    }
  }, [props.taskId]);

  const title = useMemo(() => {
    if (loading) {
      return 'Loading...';
    }
    return `Run manually ${data?.getSupermetricsTask?.title}`;
  }, [data?.getSupermetricsTask?.title]);

  return (
    <Modal
      {...props}
      title={title}
      okText="Run"
      destroyOnClose
      okButtonProps={{
        disabled: loading || !props.taskId || !dateRange[0] || !dateRange[1]
      }}
      onOk={async () => {
        if (props.taskId && dateRange[0] && dateRange[1]) {
          await run({
            variables: {
              taskId: props.taskId,
              dateRange: {
                from: dateRange[0],
                to: dateRange[1]
              }
            }
          });
        }
      }}
    >
      <DatePicker.RangePicker
        allowClear
        onCalendarChange={(values) =>
          setDateRange([values?.[0]?.toDate(), values?.[1]?.toDate()])
        }
      />
    </Modal>
  );
}
