import { FlagOutlined } from '@ant-design/icons';
import { Col, Divider, Drawer, PageHeader, Row, Space, Typography } from 'antd';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { GlobalSearchInput } from '../components/GlobalSearchInput';
import { Loader } from '../components/Loader';
import { SelectedTopics } from '../components/SelectedTopics';
import { TopicList } from '../components/TopicList';
import {
  TopicsCountPeriod,
  useGetStagesQuery,
  useGetTopicsCountQuery,
  useGetTopicsLazyQuery
} from '../generated/graphql';
import { useSelectedTopics } from '../hooks/useSelectedTopics';
import { topicFilters, topicFiltersForQuery } from '../state';
import { TopicPage } from './TopicPage';

export function StagePage() {
  const navigate = useNavigate();
  const { stage, topicId } = useParams<string>();
  const filtersForQuery = useRecoilValue(topicFiltersForQuery);
  const [filters, setFilters] = useRecoilState(topicFilters);
  const { data: stages } = useGetStagesQuery({});
  const [selectedTopics, { add, remove, reset }] = useSelectedTopics();

  const currentStage = stages?.stages.find(({ title }) => title === stage);
  const { data: topicCount } = useGetTopicsCountQuery({
    variables: {
      period: TopicsCountPeriod.All
    }
  });
  const [fetchTopics, { data, loading }] = useGetTopicsLazyQuery({
    pollInterval: 10000,
    variables: {
      filter: {
        ...filtersForQuery,
        statuses: currentStage?.states
      },
      skip: 0,
      limit: topicCount?.topicsCount,
      withAuthor: true
    }
  });

  useEffect(() => {
    currentStage?.states &&
      fetchTopics({
        variables: {
          withAuthor: true,
          filter: {
            ...filtersForQuery,
            statuses: currentStage?.states
          },
          skip: 0,
          limit: topicCount?.topicsCount
        }
      });
  }, [
    currentStage?.states,
    fetchTopics,
    filtersForQuery,
    topicCount?.topicsCount
  ]);

  function stateIsLocked(lockedStates: string[], state: string): boolean {
    return lockedStates.some((lockedState) => lockedState === state);
  }

  return (
    <PageHeader
      style={{
        marginBottom: 0,
        paddingBottom: 0
      }}
      title={
        <Typography.Title level={2}>
          <Space>
            <FlagOutlined />
            {currentStage?.title}
          </Space>
        </Typography.Title>
      }
    >
      <GlobalSearchInput onChange={setFilters} values={filters} />
      <Divider dashed />
      <SelectedTopics
        topics={selectedTopics}
        visible={selectedTopics.length > 0}
        states={currentStage?.states}
        key={selectedTopics.length}
        onDrop={reset}
      />
      <div
        style={{
          overflow: 'hidden',
          overflowX: 'auto',
          display: 'flex'
        }}
      >
        {!currentStage || loading ? (
          <Space
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <Loader />
          </Space>
        ) : (
          <Row style={{ flexFlow: 'row' }} gutter={20}>
            {currentStage?.states.map((state) => {
              const topics = data?.getTopics.items.filter(
                ({ status }) => status === state
              );
              return (
                <Col
                  key={state}
                  style={{
                    flexShrink: 0,
                    width: 250
                  }}
                >
                  <TopicList
                    topics={topics}
                    locked={stateIsLocked(currentStage?.lockedStates, state)}
                    status={state}
                    onSelectTopics={add}
                    onUnSelectTopics={remove}
                    selectedTopics={selectedTopics}
                  />
                </Col>
              );
            })}
          </Row>
        )}
      </div>
      <Drawer
        open={!!topicId}
        onClose={() => navigate('.')}
        width={window.innerWidth * 0.66}
      >
        {topicId && <TopicPage topicId={topicId} />}
      </Drawer>
    </PageHeader>
  );
}
