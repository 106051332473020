import { Drawer } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteTopicMutation } from './generated/graphql';
import { TopicPage } from './pages';

export function FastTopicPreview() {
  let { topicId } = useParams();
  let navigate = useNavigate();
  let [deleteTopic] = useDeleteTopicMutation();
  return (
    <Drawer open onClose={() => navigate('/')} width={window.innerWidth * 0.66}>
      {topicId && (
        <TopicPage
          topicId={topicId}
          onDeleted={() => {
            deleteTopic({ variables: { id: topicId! } });
          }}
        />
      )}
    </Drawer>
  );
}
