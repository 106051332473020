import { CloudSyncOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Badge,
  BadgeProps,
  Button,
  Divider,
  Empty,
  PageHeader,
  Popconfirm,
  Space,
  Switch,
  Table,
  TableColumnsType,
  Typography,
  notification
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { RunTaskNowModal } from '../components/RunTaskNowModal';
import {
  GetActiveSupermetricsTaskDocument,
  GetSupermetricsTasksDocument,
  SupermetricsSyncTask,
  SupermetricsSyncTaskStatus,
  useCancelTaskMutation,
  useDeleteSupermetricsTaskMutation,
  useGetActiveSupermetricsTaskQuery,
  useGetSupermetricsTasksQuery,
  useRunSupermetricsTaskNowMutation,
  useScheduleTaskMutation,
  useStopSupermetircsTaskMutation,
  useUnscheduleTaskMutation
} from '../generated/graphql';

const JOB_STATUS_MATRIX: Record<
  SupermetricsSyncTaskStatus,
  BadgeProps['status']
> = {
  NEW: 'default',
  FAILURE: 'error',
  QUEUED: 'default',
  RUNNING: 'processing',
  SCHEDULED: 'default',
  STOPPED: 'warning',
  SUCCESS: 'success',
  WORKING: 'processing',
  WAITING: 'default',
  IN_PROGRESS: 'processing'
};

export function SupermetricsPage() {
  const navigate = useNavigate();
  const [runTaskModalTaskId, setRunTaskModalTaskId] = useState<
    SupermetricsSyncTask['id'] | undefined
  >(undefined);

  const {
    data,
    error,
    loading: loadingTasks
  } = useGetSupermetricsTasksQuery({
    pollInterval: 5000
  });

  const [stopTask, { loading: stoppingTask }] =
    useStopSupermetircsTaskMutation();

  const { data: activeTasks, loading: loadingActiveTasks } =
    useGetActiveSupermetricsTaskQuery({
      pollInterval: 5000
    });

  const [deleteTask] = useDeleteSupermetricsTaskMutation({});
  const [schedule] = useScheduleTaskMutation({});
  const [unschedule] = useUnscheduleTaskMutation({});

  const [cancelTask] = useCancelTaskMutation({
    refetchQueries: [
      {
        query: GetActiveSupermetricsTaskDocument
      },
      {
        query: GetSupermetricsTasksDocument
      }
    ]
  });

  useEffect(() => {
    if (error) {
      notification.error(error);
      console.error(error);
    }
  }, [error]);
  const [runTask, { loading: taskRunning }] = useRunSupermetricsTaskNowMutation(
    {
      refetchQueries: [
        {
          query: GetActiveSupermetricsTaskDocument
        },
        {
          query: GetSupermetricsTasksDocument
        }
      ],
      onCompleted: () => {
        notification.success({ message: 'Task planned' });
      }
    }
  );

  async function handleSwitchChange(
    task: Pick<SupermetricsSyncTask, 'scheduled' | 'id'>
  ) {
    if (task.scheduled) {
      await unschedule({
        variables: {
          taskId: task.id
        }
      });
      notification.success({ message: 'Unscheduled' });
    } else {
      await schedule({
        variables: {
          taskId: task.id
        }
      });
      notification.success({ message: 'Scheduled' });
    }
  }

  const taskColumns: TableColumnsType<
    Omit<SupermetricsSyncTask, 'history' | 'log' | 'request'>
  > = [
    {
      key: 'title',
      title: 'Title',
      dataIndex: 'title',
      render(value, record) {
        return (
          <Space direction="vertical">
            <Typography.Text>{record.title}</Typography.Text>
            <Typography.Text type="secondary">
              {record.description}
            </Typography.Text>
          </Space>
        );
      }
    },
    {
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      title: 'Last execution',
      render(value: Date) {
        return value && new Date(value).toLocaleString();
      }
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render(value: SupermetricsSyncTaskStatus) {
        return <Badge status={JOB_STATUS_MATRIX[value]} text={value} />;
      }
    },
    {
      key: 'schedule',
      title: 'Schedule',
      render(_, record) {
        return (
          <Switch
            checked={record.scheduled}
            onChange={() => handleSwitchChange(record)}
          />
        );
      }
    },
    {
      key: 'info',
      title: 'Info',
      dataIndex: 'id',
      render(taskId) {
        return (
          <Button
            onClick={() => navigate(`/supermetrics/tasks/${taskId}/request`)}
          >
            info
          </Button>
        );
      }
    },
    {
      key: 'ops',
      title: 'Actions',
      render(_, record) {
        return (
          <Space>
            <Button
              type="primary"
              onClick={() => {
                setRunTaskModalTaskId(record.id);
                // runTask({
                //   variables: {
                //     taskId: record.id
                //   }
                // });
              }}
              disabled={taskRunning}
            >
              Run it now
            </Button>
            <Button
              disabled={stoppingTask}
              onClick={() => handleStopTask(record.id)}
            >
              Stop
            </Button>
          </Space>
        );
      }
    },
    {
      key: 'danger',
      title: 'Delete',
      render(_, record) {
        return (
          <Popconfirm
            title="Delete task?"
            onConfirm={() =>
              deleteTask({
                variables: {
                  taskId: record.id
                }
              })
            }
          >
            <Button danger>
              <DeleteOutlined color={'red'} />
            </Button>
          </Popconfirm>
        );
      }
    }
  ];
  const activeTaskColumns: TableColumnsType<SupermetricsSyncTask> = [
    {
      key: 'title',
      title: 'Title',
      dataIndex: 'title',
      render(value, record) {
        return (
          <Space direction="vertical">
            <Typography.Text>{record.title}</Typography.Text>
            <Typography.Text type="secondary">
              {record.description}
            </Typography.Text>
          </Space>
        );
      }
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render(value: SupermetricsSyncTaskStatus) {
        return <Badge status={JOB_STATUS_MATRIX[value]} text={value} />;
      }
    },
    {
      key: 'info',
      title: 'Info',
      dataIndex: 'id',
      render(taskId) {
        return (
          <Button
            onClick={() => navigate(`/supermetrics/tasks/${taskId}/request`)}
          >
            info
          </Button>
        );
      }
    },
    {
      key: 'actions',
      dataIndex: 'id',
      render(taskId) {
        return (
          <Button
            type="default"
            onClick={() => cancelTask({ variables: { taskId } })}
          >
            Cancel
          </Button>
        );
      }
    }
  ];

  async function handleStopTask(taskId: SupermetricsSyncTask['id']) {
    try {
      await stopTask({
        variables: {
          taskId
        }
      });
      notification.info({ message: 'Task stopped' });
    } catch {
      notification.error({ message: 'Task did not stopped' });
    }
  }

  return (
    <>
      <PageHeader
        title={
          <Title level={2}>
            <Space>
              <CloudSyncOutlined />
              Supermetrics
            </Space>
          </Title>
        }
      >
        {data?.getSupermetricsTasks.length === 0 ? (
          <Empty description="No one queues exist. Create new one" />
        ) : (
          <>
            <Typography.Title level={3}>Active tasks</Typography.Title>
            <Table
              loading={loadingActiveTasks}
              dataSource={activeTasks?.getActiveSupermetricsTask}
              columns={activeTaskColumns}
              rowKey={'id'}
              showHeader={false}
              pagination={false}
            />
            <Divider />
            <Table
              loading={loadingTasks}
              dataSource={data?.getSupermetricsTasks}
              columns={taskColumns}
              rowKey={'id'}
            />
          </>
        )}
      </PageHeader>
      <Outlet />
      <RunTaskNowModal
        open={!!runTaskModalTaskId}
        onCancel={() => setRunTaskModalTaskId(undefined)}
        onOk={() => setRunTaskModalTaskId(undefined)}
        taskId={runTaskModalTaskId}
      />
    </>
  );
}
