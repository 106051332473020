import { useEffect, useRef } from 'react';
import styles from './ContentEditableText.module.css';

interface ContentEditableTextProps {
  defaultValue: string;
  onChange(text: string): void;
}

function textAreaAdjust(element: any) {
  element.style.height = '1px';
  element.style.height = element.scrollHeight + 'px';
}

export function ContentEditableText(props: ContentEditableTextProps) {
  const ref = useRef<HTMLTextAreaElement | null>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = ref.current?.scrollHeight + 'px';
    }
  }, [props.defaultValue]);

  return (
    <textarea
      ref={ref}
      onKeyUp={(event) => textAreaAdjust(event.target)}
      className={styles.root}
      defaultValue={props.defaultValue}
      onChange={(event) => props.onChange(event.target.value)}
    />
  );
}
