import { Cascader } from 'antd';
import { useGetCategoriesQuery } from '../../generated/graphql';
import styles from './TopicCategoryFilter.module.css';

interface TopicCategoryFilterProps {
  value?: string[];
  onChange: (value: { type: string; value: string[] }) => void;
}

export function TopicCategoryFilter(props: TopicCategoryFilterProps) {
  const { data, loading } = useGetCategoriesQuery();

  function onChange(value: string[]) {
    props.onChange({
      type: TopicCategoryFilter.type,
      value
    });
  }

  return (
    <div className={styles.root}>
      <Cascader
        changeOnSelect
        defaultValue={props.value}
        size="small"
        bordered={false}
        options={data?.categories}
        loading={loading}
        //@ts-ignore
        onChange={onChange}
        showSearch={{ matchInputWidth: true }}
      />
    </div>
  );
}

TopicCategoryFilter.type = 'TOPIC_CATEGORY';
TopicCategoryFilter.label = 'category';
