import { atom, selector } from 'recoil';
import { CreatedByFilter } from '../components/CreatedByFilter';
import { SubmittedAtFilter } from '../components/SubmittedAtFilter';
import { TopicCategoryFilter } from '../components/TopicCategoryFilter';
import { TopicIdFilter } from '../components/TopicIdFilter';
import { TopicStateFilter } from '../components/TopicStateFilter';
import { TopicTitleFilter } from '../components/TopicTitleFilter';

export const topicFilters = atom<{ type: string; value: any }[]>({
  key: 'topicFilters',
  default: []
});

export const preReleaseMode = atom<boolean>({
  key: 'preProductionMode',
  default: false
});

export const topicFiltersForQuery = selector({
  key: 'topicFiltersForQuery',
  get: ({ get }) => {
    const filters = get(topicFilters);
    return {
      excludeDeleted: true,
      state: filters.find((filter) => filter.type === TopicStateFilter.type)
        ?.value,
      createdBy: filters.find((filter) => filter.type === CreatedByFilter.type)
        ?.value,
      submittedAt: filters.find(
        (filter) => filter.type === SubmittedAtFilter.type
      )?.value,
      topicTitle: filters.find(
        (filter) => filter.type === TopicTitleFilter.type
      )?.value,
      category: filters.find(
        (filter) => filter.type === TopicCategoryFilter.type
      )?.value,
      topicId: filters.find((filter) => filter.type === TopicIdFilter.type)
        ?.value
    };
  }
});

export const authToken = atom<string | null>({
  key: 'authToken',
  default: localStorage.getItem('token'),
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        if (newValue !== null) {
          localStorage.setItem('token', newValue);
        } else {
          localStorage.removeItem('token');
        }
      });
    }
  ]
});

export const authState = selector<boolean>({
  key: 'authState',
  get: async ({ get }) => {
    const token = get(authToken);
    if (!!token) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}`, {
          method: 'post',
          headers: {
            authentication: `Bearer ${token}`,
            'Content-type': 'application/json'
          },
          body: JSON.stringify({
            operationName: 'me',
            query: 'query me { me { id  }}',
            variables: {}
          })
        });
        return response.status === 200;
      } catch {
        return false;
      }
    } else {
      return false;
    }
  }
});

export const connectionToServerState = selector({
  key: 'connectionToServerState',
  get: async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL!}/healthcheck`
      );
      return response.status === 200;
    } catch (e) {
      console.error((e as Error).message, e);
      return false;
    }
  }
});

export const userSettings = atom<{
  defaultTopicsPageSize: number;
}>({
  key: 'userSettings',
  default: {
    defaultTopicsPageSize: 20
  },
  effects: [
    ({ setSelf, onSet }) => {
      onSet((newValue) => {
        localStorage.setItem('user-settings', JSON.stringify(newValue));
      });
      const settings = JSON.parse(
        localStorage.getItem('user-settings') || '{}'
      );
      setSelf(settings);
    }
  ]
});

export const topicsPerPage = selector<number>({
  key: 'topic-per-page',
  get: ({ get }) => get(userSettings).defaultTopicsPageSize,
  set: ({ set, get }, newValue) =>
    set(userSettings, {
      ...get(userSettings),
      defaultTopicsPageSize: newValue as number
    })
});
