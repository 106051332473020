import { MentionProps, Select, SelectProps } from 'antd';
import { useGetUsersListQuery } from '../../generated/graphql';
import styles from './CreatedByFilter.module.css';

interface CreatedByFilterProps {
  value?: string;
  onChange: (value: { type: string; value: any }) => void;
}

export function CreatedByFilter(props: CreatedByFilterProps) {
  const { data, loading } = useGetUsersListQuery();

  const onChange: MentionProps['onChange'] = (value) =>
    props.onChange({
      type: CreatedByFilter.type,
      value: value
    });

  const filterOption: SelectProps['filterOption'] = (inputValue, option) => {
    return !!option?.label?.toString().match(new RegExp(inputValue, 'gi'));
  };

  if (loading) {
    return null;
  }

  const defaultValue: any = data?.users.find(
    ({ id }) => id === props.value
  )?.fullName;

  return (
    <Select
      onChange={onChange}
      showSearch
      autoFocus
      loading={loading}
      className={styles.input}
      dropdownMatchSelectWidth={false}
      options={data?.users.map(({ id: value, fullName: label }) => ({
        label,
        value
      }))}
      filterOption={filterOption}
      bordered={false}
      defaultValue={defaultValue}
    />
  );
}

CreatedByFilter.type = 'CREATED_BY';
CreatedByFilter.label = 'created by';
