import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment';
import styles from './SubmittedAtFilter.module.css';

const { RangePicker } = DatePicker;

interface SubmittedAtFilterProps {
  value?: [Date, Date];
  onChange: (value: { type: string; value: any }) => void;
}

export function SubmittedAtFilter(props: SubmittedAtFilterProps) {
  const onChange: RangePickerProps['onChange'] = (value) => {
    if (value?.length === 2) {
      const begin = value[0]!.startOf('day').toISOString();
      const end = value[1]!.endOf('day').toISOString();
      props.onChange({
        type: SubmittedAtFilter.type,
        value: [
          begin,
          end
        ]
      });
    }
  };

  return (
    <div className={styles.root}>
      <RangePicker
        picker="date"
        size="small"
        autoFocus
        onChange={onChange}
        bordered={false}
        className={styles.input}
        defaultValue={[
          moment(props.value?.[0]),
          moment(props.value?.[1])
        ]}
      />
    </div>
  );
}

SubmittedAtFilter.type = 'SUBMITTED_AT';
SubmittedAtFilter.label = 'submitted at';
