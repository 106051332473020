import { Select, SelectProps } from 'antd';
import { TopicState } from '../../generated/graphql';
import styles from './TopicStateFilter.module.css';

interface TopicStateFilterProps {
  value?: string;
  onChange: (value: { type: string; value: any }) => void;
}

const options: SelectProps['options'] = [
  {
    label: 'Done',
    value: TopicState.Done
  },
  {
    label: 'In Progress',
    value: TopicState.InProgress
  },
  {
    label: 'Not Available',
    value: TopicState.Na
  },
  {
    label: 'Not Started',
    value: TopicState.NotStarted
  },
  {
    label: 'On Hold',
    value: TopicState.OnHold
  }
];
export function TopicStateFilter(props: TopicStateFilterProps) {
  const onChange: SelectProps['onChange'] = (value: string) => {
    props.onChange({
      type: TopicStateFilter.type,
      value
    });
  };

  return (
    <Select
      options={options}
      onChange={onChange}
      defaultValue={props.value || ''}
      className={styles.root}
      bordered={false}
    ></Select>
  );
}

TopicStateFilter.type = 'TOPIC_STATE';
TopicStateFilter.label = 'topic status';
