import {Input, InputProps} from 'antd';
import styles from './TopicTitleFilter.module.css';

interface TopicTitleFilterProps {
  value?: string;
  onChange: (value: { type: string; value: any }) => void;
}

export function TopicTitleFilter(props: TopicTitleFilterProps) {
  const onChange: InputProps['onChange'] = ({target: {value}}) => {
    props.onChange({
      type: TopicTitleFilter.type,
      value
    });
  };

  return (
    <div className={styles.root}>
      <Input
        autoFocus
        onChange={onChange}
        bordered={false}
        className={styles.input}
        defaultValue={props.value}
      />
    </div>
  );
}

TopicTitleFilter.type = 'TOPIC_TITLE';
TopicTitleFilter.label = 'topic title';
