import { ID } from '@bynyk/domain';
import { Descriptions, Spin, Timeline } from 'antd';
import { useEffect } from 'react';
import { useGetTopicHistoryLazyQuery } from '../../generated/graphql';

interface ChangesListProps {
  topicId: ID;
}

export function ChangesList(props: ChangesListProps) {
  const [fetch, { data, loading }] = useGetTopicHistoryLazyQuery({
    variables: { id: props.topicId! },
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    fetch().then().catch();
  }, [fetch, props.topicId]);

  return (
    <Spin spinning={loading} delay={500}>
      <div
        id="scrollableDiv"
        style={{
          margin: 20,
          height: '100%',
          overflow: 'auto',
          padding: '0 16px',
          border: 'none'
        }}
      >
        <Timeline
          style={{
            padding: '20px',
            color: 'gray'
          }}
        >
          {data?.getTopic?.history.map((item) => {
            return (
              <Timeline.Item key={item.id}>
                <Descriptions>
                  <Descriptions.Item
                    contentStyle={{ color: 'grey' }}
                    label={new Date(item.date).toLocaleString()}
                  >
                    {item.description}
                  </Descriptions.Item>
                </Descriptions>
              </Timeline.Item>
            );
          })}
        </Timeline>
      </div>
    </Spin>
  );
}
