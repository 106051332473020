import { useBoolean } from 'ahooks';
import {
  Button,
  Checkbox,
  Collapse,
  List,
  Modal,
  notification,
  Popconfirm,
  Select,
  Space,
  Tabs,
  Typography
} from 'antd';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from '../components/Loader';
import {
  useApplyHistoryRequestMutation,
  useChangeTaskSchedulingMutation,
  useDeleteRequestFromHistoryMutation,
  useGetSupermetricsTaskLazyQuery
} from '../generated/graphql';

export function SupermetricsTask() {
  const navigate = useNavigate();
  const { taskId } = useParams<string>();
  const { dialogTab } = useParams<string>();
  const [deleteRequestFromHistory, { loading: deletingRequest }] =
    useDeleteRequestFromHistoryMutation();
  const [showDeleted, { toggle: toggleShowDeleted }] = useBoolean(false);
  const [applyHistoryRequest] = useApplyHistoryRequestMutation();
  const [fetch, { data, loading }] = useGetSupermetricsTaskLazyQuery();
  const [changeTaskScheduling, { loading: updating }] =
    useChangeTaskSchedulingMutation();
  useEffect(() => {
    taskId && fetch({ variables: { id: taskId } });
  }, [fetch, taskId]);

  function handleTabClick(params: string) {
    navigate(`/supermetrics/tasks/${taskId}/${params}`);
  }
  function changeRequest(taskId: string, request: string) {
    applyHistoryRequest({
      variables: {
        input: {
          taskId,
          request
        }
      }
    })
      .then(() => notification.success({ message: 'Applied' }))
      .catch((e) => notification.error(e));
  }

  async function deleteRequest(taskId: string, requestIndex: number) {
    await deleteRequestFromHistory({
      variables: {
        input: {
          taskId,
          requestIndex
        }
      }
    });
  }

  if (loading) {
    return (
      <Modal
        open
        footer={null}
        centered
        closable={false}
        style={{ textAlign: 'center' }}
      >
        <Space align="center">
          <Loader />
        </Space>
      </Modal>
    );
  }

  function changeSchedulingTask(value: string) {
    return (
      taskId &&
      changeTaskScheduling({
        variables: {
          taskId: taskId,
          crontab: value
        }
      })
    );
  }

  return (
    <Modal
      open
      onCancel={() => navigate(`/supermetrics/`)}
      title={data?.getSupermetricsTask?.title}
      footer={null}
      centered
    >
      <Typography.Text type="secondary">
        Task ID:&nbsp;
        <Typography.Text copyable>
          {data?.getSupermetricsTask?.id}
        </Typography.Text>
      </Typography.Text>
      <Tabs activeKey={dialogTab} onTabClick={handleTabClick}>
        <Tabs.TabPane key="request" tab="Request">
          <pre>
            {JSON.stringify(data?.getSupermetricsTask?.request, null, 2)}
          </pre>
        </Tabs.TabPane>
        <Tabs.TabPane key="logs" tab="Logs">
          <List
            style={{ height: '70vh', overflow: 'scroll' }}
            itemLayout={'vertical'}
            dataSource={data?.getSupermetricsTask?.log}
            renderItem={(logItem) => (
              <List.Item>
                <pre style={{ whiteSpace: 'normal' }}>{logItem}</pre>
              </List.Item>
            )}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="versions" tab="Versions">
          <Space>
            <Checkbox
              checked={showDeleted}
              onChange={() => toggleShowDeleted()}
            >
              Show deleted
            </Checkbox>
          </Space>
          <Collapse defaultActiveKey={[0]}>
            {data?.getSupermetricsTask?.history
              .filter((record) => {
                if (showDeleted) {
                  return true;
                } else {
                  return record.deletedAt === undefined;
                }
              })
              .map((record, index) => {
                return (
                  <Collapse.Panel
                    collapsible={'header'}
                    key={index}
                    header={
                      index === 0
                        ? 'Current'
                        : `Previous ${
                            record.deletedAt !== undefined && '(Deleted)'
                          }`
                    }
                    extra={[
                      <Button
                        disabled={deletingRequest}
                        onClick={() =>
                          changeRequest(data?.getSupermetricsTask!.id, record)
                        }
                      >
                        Apply
                      </Button>,

                      undefined === record.deletedAt && (
                        <Popconfirm
                          onConfirm={() =>
                            deleteRequest(data?.getSupermetricsTask!.id, index)
                          }
                          title="Delete request from history?"
                        >
                          <Button loading={deletingRequest} danger>
                            Delete
                          </Button>
                        </Popconfirm>
                      )
                    ]}
                  >
                    <pre
                      style={{
                        justifyContent: 'space-between',
                        width: '100%'
                      }}
                    >
                      {JSON.stringify(record, null, 2)}
                    </pre>
                  </Collapse.Panel>
                );
              })}
          </Collapse>
        </Tabs.TabPane>
        <Tabs.TabPane key="cron" tab="Scheduling">
          <Space>
            <Typography.Text>Periodicity of task execution: </Typography.Text>
            <Select
              value={data?.getSupermetricsTask?.crontab}
              loading={updating}
              onChange={changeSchedulingTask}
              style={{ width: 150 }}
              autoFocus
              defaultActiveFirstOption
              options={[
                { label: 'every 10 mins', value: '*/10 * * * *' },
                { label: 'every 15 mins', value: '*/15 * * * *' },
                { label: 'every 20 mins', value: '*/20 * * * *' },
                { label: 'every 25 mins', value: '*/25 * * * *' },
                { label: 'every 30 mins', value: '*/30 * * * *' },
                { label: 'Hourly', value: '0 * * * *' },
                { label: 'Daily', value: '0 1 * * *' }
              ]}
            />
          </Space>
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
}
