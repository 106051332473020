import { grey } from '@ant-design/colors';
import { Col, Divider, Layout, notification, Row, Typography } from 'antd';
import React, {
  PropsWithChildren,
  useEffect,
  useLayoutEffect,
  useRef
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { MagicLinkLogin } from '../../pages';
import { authState, authToken } from '../../state';
import { CheckConnection } from '../CheckConnection';
import { Loader } from '../Loader';
import { Logo } from '../Logo';

declare const google: any;

export default function AuthForm(props: PropsWithChildren<any>) {
  const isAuthenticated = useRecoilValue(authState);
  const setToken = useSetRecoilState(authToken);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const googleButton = useRef(null);

  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    if (!isAuthenticated) {
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        cancel_on_tap_outside: false,
        callback: (responseData: { credential: string }) => {
          if (responseData.credential) {
            setToken(responseData.credential);
          }
        }
      });
      google.accounts.id.renderButton(googleButton.current, {
        type: 'outline'
      });
    }
  }, [isAuthenticated, setToken]);

  useEffect(() => {
    if (searchParams.has('token')) {
      const magicLinkToken = searchParams.get('token');
      fetch(`/api/token/${magicLinkToken}`)
        .then((resp) => {
          if (resp.status !== 200) {
            throw new Error('Login declined. Try send magic link again');
          }
          return resp;
        })
        .then((resp) => resp.text())
        .then((jwtToken) => {
          setToken(jwtToken);

          navigate('/', {
            replace: true
          });
        })
        .catch((err) => {
          notification.warn({ message: err.message });
          setToken(null);
        });
    }
  }, [navigate, searchParams, setToken]);

  if (!isAuthenticated) {
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Row justify="center" style={{ height: '100vh' }} align="middle">
          <Col
            style={{
              borderRight: `1px solid ${grey[0]}`,
              marginRight: '30px'
            }}
          >
            <Logo height={200} />
          </Col>
          <Col>
            <div ref={googleButton}></div>
            {/* <Divider>
              <Typography.Text italic type={'secondary'}>
                or
              </Typography.Text>
            </Divider>
            <CheckConnection fallback={<Loader size={'large'} />}>
              <MagicLinkLogin />
            </CheckConnection> */}
          </Col>
        </Row>
      </Layout>
    );
  } else {
    return props.children;
  }
}
