import { useState } from 'react';
import { GetTopicsQuery } from '../generated/graphql';

type SelectedTopicsHandler = [
  GetTopicsQuery['getTopics']['items'],
  {
    add: (topics: GetTopicsQuery['getTopics']['items']) => void;
    remove: (topicsToUnselect: GetTopicsQuery['getTopics']['items']) => void;
    reset: () => void;
  }
];

export function useSelectedTopics(): SelectedTopicsHandler {
  const [selectedTopics, setSelectedTopics] = useState<
    GetTopicsQuery['getTopics']['items']
  >([] as GetTopicsQuery['getTopics']['items']);

  function onSelectTopics(topics: GetTopicsQuery['getTopics']['items']): void {
    setSelectedTopics([
      ...selectedTopics,
      ...topics.filter(
        (topic) =>
          selectedTopics.filter((item) => item.id === topic.id).length === 0
      )
    ]);
  }

  function onUnSelectTopics(
    topicsToUnselect: GetTopicsQuery['getTopics']['items']
  ): void {
    const newSelectedTopics = selectedTopics.filter((topic) => {
      const leaveInList = !(
        topicsToUnselect.filter((item) => topic.id === item.id).length > 0
      );
      return leaveInList;
    });
    setSelectedTopics(newSelectedTopics);
  }

  function clearSelectedTopics() {
    setSelectedTopics([]);
  }

  return [
    selectedTopics,
    {
      add: onSelectTopics,
      remove: onUnSelectTopics,
      reset: clearSelectedTopics
    }
  ];
}
