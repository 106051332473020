import { Cascader } from 'antd';
import { TopicCategory, useGetCategoriesQuery } from '../../generated/graphql';

interface CategorySelectorProps {
  value?: Array<TopicCategory['value']>;

  onChange(value: Array<TopicCategory['value']>): void;
}

export function CategorySelector(props: CategorySelectorProps) {
  const { data, loading } = useGetCategoriesQuery();

  function onChange(value: any) {
    props.onChange(value);
  }

  return (
    <Cascader
      changeOnSelect
      loading={loading}
      value={props.value}
      options={data?.categories}
      style={{
        width: '100%'
      }}
      onChange={onChange}
    />
  );
}
