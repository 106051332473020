import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  /** ISO 8601 Date format */
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  LogRecord: { input: any; output: any; }
  State: { input: string; output: string; }
};

export type ActivateUserInput = {
  id: Scalars['ID']['input'];
};

export type AddCommentInput = {
  text: Scalars['String']['input'];
  topicId: Scalars['ID']['input'];
};

export type AddTopicInput = {
  category: Array<Scalars['String']['input']>;
  courseSkill: Scalars['String']['input'];
  creatorsLinks?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * Give some context to the idea, explain your subject in 5-10 words:
   * A course that will help you get decent sound results, without having to really deep dive into mixing
   */
  description?: InputMaybe<Scalars['String']['input']>;
  howDidYouComeUp?: InputMaybe<Scalars['String']['input']>;
  howTos?: InputMaybe<Scalars['String']['input']>;
  /** You can insert youtube links, instagram profiles, interesting blogs and from where you got this idea. */
  importantLinks?: InputMaybe<Array<Scalars['String']['input']>>;
  redditLink?: InputMaybe<Scalars['String']['input']>;
  seedKeywords: Array<Scalars['String']['input']>;
  /** Name of the topic */
  title: Scalars['String']['input'];
  /** If you know on specific skills or tools required for this course */
  toolsAndSkills?: InputMaybe<Scalars['String']['input']>;
};

export type AddUserInput = {
  email: Scalars['String']['input'];
  sendMagicLink?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ApplyHistoryRequestInput = {
  request: Scalars['JSON']['input'];
  taskId: Scalars['ID']['input'];
};

export enum CsvType {
  Growth = 'GROWTH',
  Month = 'MONTH'
}

export type CancelSupermetricsTaskInput = {
  taskId: Scalars['ID']['input'];
};

export type ChangeClusterStatusInput = {
  clusterId: Scalars['ID']['input'];
  status: Scalars['String']['input'];
};

export type ChangeTaskSchedulingInput = {
  /** Cron task pattern */
  crontab: Scalars['String']['input'];
  taskId: Scalars['ID']['input'];
};

export type ChangeTopicCategoryInput = {
  category: Array<Scalars['String']['input']>;
  topicId: Scalars['ID']['input'];
};

export type ChangeTopicCourseSkillInput = {
  courseSkill: Scalars['String']['input'];
  topicId: Scalars['ID']['input'];
};

export type ChangeTopicCourseTypeInput = {
  courseType: Array<Scalars['String']['input']>;
  topicId: Scalars['ID']['input'];
};

export type ChangeTopicCreatorsLinksInput = {
  creatorsLinks: Array<Scalars['String']['input']>;
  topicId: Scalars['ID']['input'];
};

export type ChangeTopicDescriptionInput = {
  description: Scalars['String']['input'];
  topicId: Scalars['ID']['input'];
};

export type ChangeTopicHowDidYouComeToItInput = {
  howDidYouComeUp: Scalars['String']['input'];
  topicId: Scalars['ID']['input'];
};

export type ChangeTopicHowTosInput = {
  howTos: Scalars['String']['input'];
  topicId: Scalars['ID']['input'];
};

export type ChangeTopicImportantLinksInput = {
  importantLinks: Array<Scalars['String']['input']>;
  topicId: Scalars['ID']['input'];
};

export type ChangeTopicMarketingCampaignInput = {
  campaigns: Array<Scalars['String']['input']>;
  topicId: Scalars['ID']['input'];
};

export type ChangeTopicRedditLinkInput = {
  redditLink: Scalars['String']['input'];
  topicId: Scalars['ID']['input'];
};

export type ChangeTopicSeedKeywordsInput = {
  seedKeywords: Array<Scalars['String']['input']>;
  topicId: Scalars['ID']['input'];
};

export type ChangeTopicStateInput = {
  reason: Scalars['String']['input'];
  state: TopicState;
  topicId: Scalars['ID']['input'];
};

export type ChangeTopicStatusInput = {
  newStatus: Scalars['String']['input'];
  topicId: Scalars['ID']['input'];
};

export type ChangeTopicTitleInput = {
  title: Scalars['String']['input'];
  topicId: Scalars['ID']['input'];
};

export enum ClusterBuilderStatus {
  Created = 'CREATED',
  Never = 'NEVER',
  Processing = 'PROCESSING'
}

export type Comment = {
  __typename?: 'Comment';
  author: User;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  text: Scalars['String']['output'];
};

export type CreateClusterInput = {
  keywords: Array<CreateClusterKeywordsInput>;
  topic: Scalars['ID']['input'];
};

export type CreateClusterKeywordsInput = {
  query: Scalars['String']['input'];
  scrapers: Array<Scraper>;
};

export type CreateSettingInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type CreateSupermetricsTaskInput = {
  request: Scalars['JSON']['input'];
};

export type DateRange = {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
};

export type DeleteClusterInput = {
  clusterId: Scalars['ID']['input'];
};

export type DeleteCommentInput = {
  commentId: Scalars['ID']['input'];
};

export type DeleteRequestFromHistoryInput = {
  requestIndex: Scalars['Int']['input'];
  taskId: Scalars['ID']['input'];
};

export type DeleteScheduledDateInput = {
  id: Array<Scalars['ID']['input']>;
};

export type DeleteSettingInput = {
  key: Scalars['String']['input'];
};

export type DeleteSupermetricsTaskInput = {
  taskId: Scalars['ID']['input'];
};

export type DisableUserInput = {
  id: Scalars['ID']['input'];
};

export type Downloadable = {
  __typename?: 'Downloadable';
  error?: Maybe<Error>;
  source?: Maybe<Scalars['String']['output']>;
};

export type Error = {
  __typename?: 'Error';
  /** Description */
  message: Scalars['String']['output'];
};

export type Formula = {
  __typename?: 'Formula';
  content: Scalars['String']['output'];
  key: Scalars['String']['output'];
};

export enum FormulaProperty {
  Competition = 'COMPETITION',
  FinalScore = 'FINAL_SCORE',
  Interest = 'INTEREST',
  Relevance = 'RELEVANCE',
  Volume = 'VOLUME'
}

export type GenerateStatisticInput = {
  dateRange: DateRange;
};

export type GetCsvInput = {
  date: Scalars['DateTime']['input'];
  type: CsvType;
};

export type GetKeywordStatisticsFilter = {
  keywordId: Scalars['ID']['input'];
  scraper?: InputMaybe<Scraper>;
};

export type HistoryChange = {
  __typename?: 'HistoryChange';
  author: User;
  date: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type IsSubscribedToStateInput = {
  state: Scalars['String']['input'];
};

export type Keyword = {
  __typename?: 'Keyword';
  id: Scalars['ID']['output'];
  /** Scrapers used for this keyword */
  scrapers: Array<Scraper>;
  title: Scalars['String']['output'];
};

export type KeywordCluster = {
  __typename?: 'KeywordCluster';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  keywords: Array<Keyword>;
  version: Scalars['String']['output'];
};

export type KeywordStatistic = {
  __typename?: 'KeywordStatistic';
  data: Array<StatisticData>;
  keyword: Keyword;
  scraper: Scraper;
};

export type LogFilter = {
  app?: InputMaybe<Scalars['String']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['ID']['input']>;
  to?: InputMaybe<Scalars['ID']['input']>;
};

export type LoginInput = {
  email: Scalars['String']['input'];
};

export type MongoCollectionData = {
  __typename?: 'MongoCollectionData';
  rows: Array<Scalars['JSON']['output']>;
};

export type MongoCollectionInput = {
  collectionName: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateUser: User;
  addComment: Comment;
  addTopic: Topic;
  addUser: Result;
  applyHistoryRequest: Result;
  cancelSupermetricsTask: Result;
  changeTaskScheduling: Result;
  changeTopicCategory: Topic;
  changeTopicCourseSkill: Topic;
  changeTopicCourseType: Topic;
  changeTopicCreatorsLinks: Topic;
  changeTopicDescription: Topic;
  changeTopicHowDidYouComeToIt: Topic;
  changeTopicHowTos: Topic;
  changeTopicImportantLinks: Topic;
  changeTopicMarketingCampaign: Topic;
  changeTopicRedditLink: Topic;
  changeTopicSeedKeywords: Topic;
  changeTopicState: Result;
  changeTopicStatus?: Maybe<Query>;
  changeTopicTitle: Topic;
  createCluster: KeywordCluster;
  createSupermetricsTask: Result;
  deleteCluster: Scalars['Boolean']['output'];
  deleteComment?: Maybe<Scalars['Boolean']['output']>;
  deleteRequestFromHistory: Result;
  deleteScheduledDate: Result;
  deleteSupermetricsTask: Result;
  deleteTopic: Scalars['Boolean']['output'];
  disableUser: User;
  restoreTopic: Scalars['Boolean']['output'];
  runSupermetricsTaskNow: Result;
  saveDraftTopics: Result;
  scheduleTask: Result;
  stopSupermetricsTask: Result;
  /** Subscribe on state */
  subscribeOnState: Result;
  unscheduleTask: Result;
  unsubscribeFromState: Result;
  updateAllowedEmails?: Maybe<Query>;
  updateComment: Comment;
  /** @deprecated Use change precise fields mutation */
  updateTopicMeta: Topic;
  updateTopicStates: Result;
  /** @deprecated Use changeTopicTitle */
  updateTopicTitle: Topic;
  updateUserProfile?: Maybe<User>;
};


export type MutationActivateUserArgs = {
  input: ActivateUserInput;
};


export type MutationAddCommentArgs = {
  input: AddCommentInput;
};


export type MutationAddTopicArgs = {
  input: AddTopicInput;
};


export type MutationAddUserArgs = {
  input: AddUserInput;
};


export type MutationApplyHistoryRequestArgs = {
  input: ApplyHistoryRequestInput;
};


export type MutationCancelSupermetricsTaskArgs = {
  input: CancelSupermetricsTaskInput;
};


export type MutationChangeTaskSchedulingArgs = {
  input: ChangeTaskSchedulingInput;
};


export type MutationChangeTopicCategoryArgs = {
  input: ChangeTopicCategoryInput;
};


export type MutationChangeTopicCourseSkillArgs = {
  input: ChangeTopicCourseSkillInput;
};


export type MutationChangeTopicCourseTypeArgs = {
  input: ChangeTopicCourseTypeInput;
};


export type MutationChangeTopicCreatorsLinksArgs = {
  input: ChangeTopicCreatorsLinksInput;
};


export type MutationChangeTopicDescriptionArgs = {
  input: ChangeTopicDescriptionInput;
};


export type MutationChangeTopicHowDidYouComeToItArgs = {
  input: ChangeTopicHowDidYouComeToItInput;
};


export type MutationChangeTopicHowTosArgs = {
  input: ChangeTopicHowTosInput;
};


export type MutationChangeTopicImportantLinksArgs = {
  input: ChangeTopicImportantLinksInput;
};


export type MutationChangeTopicMarketingCampaignArgs = {
  input: ChangeTopicMarketingCampaignInput;
};


export type MutationChangeTopicRedditLinkArgs = {
  input: ChangeTopicRedditLinkInput;
};


export type MutationChangeTopicSeedKeywordsArgs = {
  input: ChangeTopicSeedKeywordsInput;
};


export type MutationChangeTopicStateArgs = {
  input: ChangeTopicStateInput;
};


export type MutationChangeTopicStatusArgs = {
  input: ChangeTopicStatusInput;
};


export type MutationChangeTopicTitleArgs = {
  input: ChangeTopicTitleInput;
};


export type MutationCreateClusterArgs = {
  input: CreateClusterInput;
};


export type MutationCreateSupermetricsTaskArgs = {
  input: CreateSupermetricsTaskInput;
};


export type MutationDeleteClusterArgs = {
  input: DeleteClusterInput;
};


export type MutationDeleteCommentArgs = {
  input: DeleteCommentInput;
};


export type MutationDeleteRequestFromHistoryArgs = {
  input: DeleteRequestFromHistoryInput;
};


export type MutationDeleteScheduledDateArgs = {
  input: DeleteScheduledDateInput;
};


export type MutationDeleteSupermetricsTaskArgs = {
  input: DeleteSupermetricsTaskInput;
};


export type MutationDeleteTopicArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDisableUserArgs = {
  input: DisableUserInput;
};


export type MutationRestoreTopicArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRunSupermetricsTaskNowArgs = {
  input: RunSupermetricsTaskNowInput;
};


export type MutationSaveDraftTopicsArgs = {
  input: SaveDraftTopicsInput;
};


export type MutationScheduleTaskArgs = {
  input: ScheduleTaskInput;
};


export type MutationStopSupermetricsTaskArgs = {
  input: StopSupermetricsTaskInput;
};


export type MutationSubscribeOnStateArgs = {
  input: SubscribeOnStateInput;
};


export type MutationUnscheduleTaskArgs = {
  input: UnscheduleTaskInput;
};


export type MutationUnsubscribeFromStateArgs = {
  input: UnsubscribeFromStateInput;
};


export type MutationUpdateAllowedEmailsArgs = {
  input: UpdateAllowedEmailsInput;
};


export type MutationUpdateCommentArgs = {
  input: UpdateCommentInput;
};


export type MutationUpdateTopicMetaArgs = {
  input: UpdateTopicMetaInput;
};


export type MutationUpdateTopicStatesArgs = {
  input: UpdateTopicStates;
};


export type MutationUpdateTopicTitleArgs = {
  input: UpdateTopicTitleInput;
};


export type MutationUpdateUserProfileArgs = {
  input: UpdateUserProfileInput;
};

export type NewKeyword = {
  scrapers: Array<Scraper>;
  title: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  /** Categories */
  categories: Array<TopicCategory>;
  drafts: Array<Scalars['JSON']['output']>;
  getActiveSupermetricsTask: Array<SupermetricsSyncTask>;
  getCluster?: Maybe<KeywordCluster>;
  getKeywordStatistics?: Maybe<KeywordStatistic>;
  getMongoDbCollectionData?: Maybe<MongoCollectionData>;
  /** Get list of dates from Redis, response on the generateStatistic */
  getScheduledDates: Array<ScheduledDate>;
  getSupermetricsTask?: Maybe<SupermetricsSyncTask>;
  getSupermetricsTasks: Array<SupermetricsSyncTask>;
  getTopic?: Maybe<Topic>;
  getTopics: TopicPagination;
  /** Check user subscription of state */
  isSubscribedToState: Scalars['Boolean']['output'];
  login: Scalars['Boolean']['output'];
  /** Authenticated user info */
  me?: Maybe<User>;
  /** Application settings */
  settings: Array<Setting>;
  /** Stages of topics. For example: Ideation - Market Test, Market Test - Talent Acquisition, Talent Acquisition - Live */
  stages: Array<Stage>;
  /** Test query */
  status: Scalars['String']['output'];
  /** @deprecated Use getTopics */
  topics: Array<Topic>;
  topicsCount: Scalars['Int']['output'];
  user?: Maybe<User>;
  /** All system user */
  users: Array<User>;
};


export type QueryGetClusterArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetKeywordStatisticsArgs = {
  filter: GetKeywordStatisticsFilter;
};


export type QueryGetMongoDbCollectionDataArgs = {
  input: MongoCollectionInput;
};


export type QueryGetSupermetricsTaskArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetTopicArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetTopicsArgs = {
  filter: TopicFilter;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<TopicSort>;
};


export type QueryIsSubscribedToStateArgs = {
  input: IsSubscribedToStateInput;
};


export type QueryLoginArgs = {
  input: LoginInput;
};


export type QueryTopicsArgs = {
  filter?: InputMaybe<TopicFilter>;
};


export type QueryTopicsCountArgs = {
  input: TopicsCountInput;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};

/** Interface describe mutation result */
export type Result = {
  __typename?: 'Result';
  error?: Maybe<Error>;
  query?: Maybe<Query>;
};

export type RunSupermetricsTaskNowInput = {
  dateRange: DateRange;
  taskId: Scalars['ID']['input'];
};

export type SaveDraftTopicsInput = {
  topics: Array<Scalars['JSON']['input']>;
};

export type ScheduleTaskInput = {
  taskId: Scalars['ID']['input'];
};

export type ScheduledDate = {
  __typename?: 'ScheduledDate';
  id: Scalars['ID']['output'];
  scheduledDate: Scalars['Date']['output'];
  status: ScheduledDateStatus;
};

export enum ScheduledDateStatus {
  Done = 'DONE',
  Fail = 'FAIL',
  Pending = 'PENDING'
}

export type Score = {
  __typename?: 'Score';
  /** Date used in normalization */
  date: Scalars['DateTime']['output'];
  /** Normalized value */
  score: Scalars['Float']['output'];
};

export enum Scraper {
  Amazonbooks = 'AMAZONBOOKS',
  Domestika = 'DOMESTIKA',
  Ebay = 'EBAY',
  Googleshopping = 'GOOGLESHOPPING',
  Instagram = 'INSTAGRAM',
  Pinterest = 'PINTEREST',
  Reddit = 'REDDIT',
  Searchvolume = 'SEARCHVOLUME',
  Skillshare = 'SKILLSHARE',
  Tiktok = 'TIKTOK',
  Twitter = 'TWITTER',
  Udemy = 'UDEMY',
  Youtube = 'YOUTUBE'
}

export type Setting = {
  __typename?: 'Setting';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Stage = {
  __typename?: 'Stage';
  /** States where user can't move topics from */
  lockedStates: Array<Scalars['State']['output']>;
  states: Array<Scalars['State']['output']>;
  title: Scalars['String']['output'];
};

export type StageInput = {
  lockedStates: Array<Scalars['String']['input']>;
  states: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type StatisticData = {
  __typename?: 'StatisticData';
  category: Scalars['String']['output'];
  datetime: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

export type StopSupermetricsTaskInput = {
  taskId: Scalars['ID']['input'];
};

export type SubscribeOnStateInput = {
  state: Scalars['String']['input'];
};

export type SupermetricsSyncTask = {
  __typename?: 'SupermetricsSyncTask';
  createdAt: Scalars['DateTime']['output'];
  crontab?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  history: Array<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  log: Array<Scalars['String']['output']>;
  request?: Maybe<Scalars['JSON']['output']>;
  requestId?: Maybe<Scalars['ID']['output']>;
  scheduleId?: Maybe<Scalars['ID']['output']>;
  scheduled: Scalars['Boolean']['output'];
  status: SupermetricsSyncTaskStatus;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum SupermetricsSyncTaskStatus {
  /** Query has stopped and failed */
  Failure = 'FAILURE',
  /** Query is in progress */
  InProgress = 'IN_PROGRESS',
  /** Query created and wait sending to API */
  New = 'NEW',
  /** Query is queued for execution */
  Queued = 'QUEUED',
  /** Query is currently running */
  Running = 'RUNNING',
  /** Query is scheduled and inactive */
  Scheduled = 'SCHEDULED',
  /** Query was aborted */
  Stopped = 'STOPPED',
  /** Query has stopped and was successful */
  Success = 'SUCCESS',
  /** Query wait for execution */
  Waiting = 'WAITING',
  /** Query ready to fetch */
  Working = 'WORKING'
}

export type Topic = {
  __typename?: 'Topic';
  asanaTaskLink?: Maybe<Scalars['String']['output']>;
  campaigns?: Maybe<Array<Scalars['String']['output']>>;
  category?: Maybe<Array<Scalars['String']['output']>>;
  /** Base64 encoded string with result JSON */
  clusterBuilderResult?: Maybe<Scalars['String']['output']>;
  clusterBuilderStatus: ClusterBuilderStatus;
  clusters: Array<KeywordCluster>;
  clustersCount: Scalars['Int']['output'];
  comments: Array<Comment>;
  courseSkill: Scalars['String']['output'];
  courseType: Array<Scalars['String']['output']>;
  cpc: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  creatorsLinks?: Maybe<Array<Scalars['String']['output']>>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** How long topic in current state in days */
  durationInState: Scalars['String']['output'];
  history: Array<HistoryChange>;
  howDidYouComeUp?: Maybe<Scalars['String']['output']>;
  howTos?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  importantLinks?: Maybe<Array<Scalars['String']['output']>>;
  redditLink?: Maybe<Scalars['String']['output']>;
  seedKeywords?: Maybe<Array<Scalars['String']['output']>>;
  state: TopicState;
  stateDescription: Scalars['String']['output'];
  status: Scalars['String']['output'];
  statusChangedAt?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
  toolsAndSkills?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TopicCategory = {
  __typename?: 'TopicCategory';
  children?: Maybe<Array<TopicCategory>>;
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type TopicFilter = {
  category?: InputMaybe<Array<Scalars['String']['input']>>;
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  excludeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<TopicState>;
  statuses?: InputMaybe<Array<Scalars['State']['input']>>;
  /** Range of dates. First element of array is begin of the range, second element of array is end of the range. */
  submittedAt?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  topicId?: InputMaybe<Scalars['ID']['input']>;
  topicTitle?: InputMaybe<Scalars['String']['input']>;
};

export type TopicPagination = {
  __typename?: 'TopicPagination';
  items: Array<Topic>;
};

export enum TopicSort {
  CreationDateAsc = 'CREATION_DATE_ASC',
  CreationDateDesc = 'CREATION_DATE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC'
}

export enum TopicState {
  Done = 'DONE',
  InProgress = 'IN_PROGRESS',
  Na = 'NA',
  NotStarted = 'NOT_STARTED',
  OnHold = 'ON_HOLD'
}

export type TopicsCountInput = {
  period: TopicsCountPeriod;
};

export enum TopicsCountPeriod {
  All = 'ALL',
  Last_30Days = 'LAST_30_DAYS'
}

export type UnscheduleTaskInput = {
  taskId: Scalars['ID']['input'];
};

export type UnsubscribeFromStateInput = {
  state: Scalars['String']['input'];
};

export type UpdateAllowedEmailsInput = {
  emails: Array<Scalars['String']['input']>;
};

export type UpdateCommentInput = {
  commentId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type UpdateSettingInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type UpdateTopicMetaInput = {
  campaigns?: InputMaybe<Array<Scalars['String']['input']>>;
  category?: InputMaybe<Array<Scalars['String']['input']>>;
  courseSkill?: InputMaybe<Scalars['String']['input']>;
  courseType?: InputMaybe<Array<Scalars['String']['input']>>;
  creatorsLinks?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  howDidYouComeUp?: InputMaybe<Scalars['String']['input']>;
  howTos?: InputMaybe<Scalars['String']['input']>;
  importantLinks?: InputMaybe<Array<Scalars['String']['input']>>;
  redditLink?: InputMaybe<Scalars['String']['input']>;
  seedKeywords?: InputMaybe<Array<Scalars['String']['input']>>;
  toolsAndSkills?: InputMaybe<Scalars['String']['input']>;
  topicId: Scalars['ID']['input'];
};

export type UpdateTopicStates = {
  states: Array<StageInput>;
};

export type UpdateTopicTitleInput = {
  title: Scalars['String']['input'];
  topicId: Scalars['ID']['input'];
};

export type UpdateUserProfileInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  /** Does user exists at TMS */
  created: Scalars['Boolean']['output'];
  /** Creation date */
  createdAt: Scalars['DateTime']['output'];
  /** Date of deactivation */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  /** Avatar image url */
  picture: Scalars['String']['output'];
  /** User's Subscriptions */
  subscriptions: Array<Scalars['State']['output']>;
};

export type ActivateUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ActivateUserMutation = { __typename?: 'Mutation', activateUser: (
    { __typename?: 'User' }
    & UserFragmentFragment
  ) };

export type AddCommentMutationVariables = Exact<{
  input: AddCommentInput;
}>;


export type AddCommentMutation = { __typename?: 'Mutation', addComment: { __typename?: 'Comment', id: string, createdAt: any, text: string } };

export type AddUserMutationVariables = Exact<{
  input: AddUserInput;
}>;


export type AddUserMutation = { __typename?: 'Mutation', addUser: { __typename?: 'Result', query?: { __typename?: 'Query', users: Array<(
        { __typename?: 'User' }
        & UserFragmentFragment
      )> } | undefined } };

export type ApplyHistoryRequestMutationVariables = Exact<{
  input: ApplyHistoryRequestInput;
}>;


export type ApplyHistoryRequestMutation = { __typename?: 'Mutation', applyHistoryRequest: { __typename?: 'Result', query?: { __typename?: 'Query', getActiveSupermetricsTask: Array<(
        { __typename?: 'SupermetricsSyncTask' }
        & SupermetricsSyncTaskFragmentFragment
      )>, getSupermetricsTasks: Array<(
        { __typename?: 'SupermetricsSyncTask' }
        & SupermetricsSyncTaskFragmentFragment
      )> } | undefined } };

export type CancelTaskMutationVariables = Exact<{
  taskId: Scalars['ID']['input'];
}>;


export type CancelTaskMutation = { __typename?: 'Mutation', cancelSupermetricsTask: { __typename?: 'Result', query?: { __typename?: 'Query', getActiveSupermetricsTask: Array<(
        { __typename?: 'SupermetricsSyncTask' }
        & SupermetricsSyncTaskFragmentFragment
      )>, getSupermetricsTasks: Array<(
        { __typename?: 'SupermetricsSyncTask' }
        & SupermetricsSyncTaskFragmentFragment
      )> } | undefined } };

export type CategoryFragmentFragment = { __typename?: 'TopicCategory', label: string, value: string };

export type ChangeTaskSchedulingMutationVariables = Exact<{
  taskId: Scalars['ID']['input'];
  crontab: Scalars['String']['input'];
}>;


export type ChangeTaskSchedulingMutation = { __typename?: 'Mutation', changeTaskScheduling: { __typename?: 'Result', query?: { __typename?: 'Query', getSupermetricsTask?: (
        { __typename?: 'SupermetricsSyncTask' }
        & SupermetricsSyncTaskFragmentFragment
      ) | undefined } | undefined, error?: { __typename: 'Error', message: string } | undefined } };

export type ChangeTopicCategoryMutationVariables = Exact<{
  input: ChangeTopicCategoryInput;
}>;


export type ChangeTopicCategoryMutation = { __typename?: 'Mutation', changeTopicCategory: (
    { __typename?: 'Topic' }
    & TopicFragmentFragment
  ) };

export type ChangeTopicCourseSkillMutationVariables = Exact<{
  input: ChangeTopicCourseSkillInput;
}>;


export type ChangeTopicCourseSkillMutation = { __typename?: 'Mutation', changeTopicCourseSkill: (
    { __typename?: 'Topic' }
    & TopicFragmentFragment
  ) };

export type ChangeTopicCourseTypeMutationVariables = Exact<{
  input: ChangeTopicCourseTypeInput;
}>;


export type ChangeTopicCourseTypeMutation = { __typename?: 'Mutation', changeTopicCourseType: (
    { __typename?: 'Topic' }
    & TopicFragmentFragment
  ) };

export type ChangeTopicCreatorsLinksMutationVariables = Exact<{
  input: ChangeTopicCreatorsLinksInput;
}>;


export type ChangeTopicCreatorsLinksMutation = { __typename?: 'Mutation', changeTopicCreatorsLinks: (
    { __typename?: 'Topic' }
    & TopicFragmentFragment
  ) };

export type ChangeTopicDescriptionMutationVariables = Exact<{
  input: ChangeTopicDescriptionInput;
}>;


export type ChangeTopicDescriptionMutation = { __typename?: 'Mutation', changeTopicDescription: (
    { __typename?: 'Topic' }
    & TopicFragmentFragment
  ) };

export type ChangeTopicHowDidYouComeToItMutationVariables = Exact<{
  input: ChangeTopicHowDidYouComeToItInput;
}>;


export type ChangeTopicHowDidYouComeToItMutation = { __typename?: 'Mutation', changeTopicHowDidYouComeToIt: (
    { __typename?: 'Topic' }
    & TopicFragmentFragment
  ) };

export type ChangeTopicHowTosMutationVariables = Exact<{
  input: ChangeTopicHowTosInput;
}>;


export type ChangeTopicHowTosMutation = { __typename?: 'Mutation', changeTopicHowTos: (
    { __typename?: 'Topic' }
    & TopicFragmentFragment
  ) };

export type ChangeTopicImportantLinksMutationVariables = Exact<{
  input: ChangeTopicImportantLinksInput;
}>;


export type ChangeTopicImportantLinksMutation = { __typename?: 'Mutation', changeTopicImportantLinks: (
    { __typename?: 'Topic' }
    & TopicFragmentFragment
  ) };

export type ChangeTopicMarketingCampaignMutationVariables = Exact<{
  input: ChangeTopicMarketingCampaignInput;
}>;


export type ChangeTopicMarketingCampaignMutation = { __typename?: 'Mutation', changeTopicMarketingCampaign: (
    { __typename?: 'Topic' }
    & TopicFragmentFragment
  ) };

export type ChangeTopicRedditLinkMutationVariables = Exact<{
  input: ChangeTopicRedditLinkInput;
}>;


export type ChangeTopicRedditLinkMutation = { __typename?: 'Mutation', changeTopicRedditLink: (
    { __typename?: 'Topic' }
    & TopicFragmentFragment
  ) };

export type ChangeTopicSeedKeywordsMutationVariables = Exact<{
  input: ChangeTopicSeedKeywordsInput;
}>;


export type ChangeTopicSeedKeywordsMutation = { __typename?: 'Mutation', changeTopicSeedKeywords: (
    { __typename?: 'Topic' }
    & TopicFragmentFragment
  ) };

export type ChangeTopicStateMutationVariables = Exact<{
  topicId: Scalars['ID']['input'];
  newState: TopicState;
  reason: Scalars['String']['input'];
}>;


export type ChangeTopicStateMutation = { __typename?: 'Mutation', changeTopicState: { __typename?: 'Result', error?: { __typename?: 'Error', message: string } | undefined } };

export type ChangeTopicStatusMutationVariables = Exact<{
  input: ChangeTopicStatusInput;
  filter: TopicFilter;
}>;


export type ChangeTopicStatusMutation = { __typename?: 'Mutation', changeTopicStatus?: { __typename?: 'Query', getTopics: { __typename?: 'TopicPagination', items: Array<{ __typename?: 'Topic', id: string, title: string, status: string }> } } | undefined };

export type ChangeTopicTitleMutationVariables = Exact<{
  input: ChangeTopicTitleInput;
}>;


export type ChangeTopicTitleMutation = { __typename?: 'Mutation', changeTopicTitle: (
    { __typename?: 'Topic' }
    & TopicFragmentFragment
  ) };

export type CommentFragmentFragment = { __typename?: 'Comment', id: string, text: string, createdAt: any, author: { __typename?: 'User', id: string, fullName: string } };

export type CreateClusterMutationVariables = Exact<{
  input: CreateClusterInput;
}>;


export type CreateClusterMutation = { __typename?: 'Mutation', createCluster: (
    { __typename?: 'KeywordCluster' }
    & KeywordClusterFragment
  ) };

export type CreateSupermetricsTaskMutationVariables = Exact<{
  input: CreateSupermetricsTaskInput;
}>;


export type CreateSupermetricsTaskMutation = { __typename?: 'Mutation', createSupermetricsTask: { __typename?: 'Result', query?: { __typename?: 'Query', getSupermetricsTasks: Array<(
        { __typename?: 'SupermetricsSyncTask' }
        & SupermetricsSyncTaskFragmentFragment
      )> } | undefined, error?: { __typename?: 'Error', message: string } | undefined } };

export type AddTopicMutationVariables = Exact<{
  input: AddTopicInput;
}>;


export type AddTopicMutation = { __typename?: 'Mutation', addTopic: (
    { __typename?: 'Topic' }
    & TopicFragmentFragment
  ) };

export type DeleteClusterMutationVariables = Exact<{
  input: DeleteClusterInput;
}>;


export type DeleteClusterMutation = { __typename?: 'Mutation', deleteCluster: boolean };

export type DeleteCommentMutationVariables = Exact<{
  input: DeleteCommentInput;
}>;


export type DeleteCommentMutation = { __typename?: 'Mutation', deleteComment?: boolean | undefined };

export type DeleteRequestFromHistoryMutationVariables = Exact<{
  input: DeleteRequestFromHistoryInput;
}>;


export type DeleteRequestFromHistoryMutation = { __typename?: 'Mutation', deleteRequestFromHistory: { __typename?: 'Result', query?: { __typename?: 'Query', getSupermetricsTasks: Array<(
        { __typename?: 'SupermetricsSyncTask' }
        & SupermetricsSyncTaskFragmentFragment
      )> } | undefined } };

export type DeleteScheduledDateMutationVariables = Exact<{
  input: DeleteScheduledDateInput;
}>;


export type DeleteScheduledDateMutation = { __typename?: 'Mutation', deleteScheduledDate: { __typename?: 'Result', error?: { __typename?: 'Error', message: string } | undefined } };

export type DeleteSupermetricsTaskMutationVariables = Exact<{
  taskId: Scalars['ID']['input'];
}>;


export type DeleteSupermetricsTaskMutation = { __typename?: 'Mutation', deleteSupermetricsTask: { __typename?: 'Result', query?: { __typename?: 'Query', getSupermetricsTasks: Array<(
        { __typename?: 'SupermetricsSyncTask' }
        & SupermetricsSyncTaskFragmentFragment
      )> } | undefined, error?: { __typename?: 'Error', message: string } | undefined } };

export type DeleteTopicMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteTopicMutation = { __typename?: 'Mutation', deleteTopic: boolean };

export type DisableUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DisableUserMutation = { __typename?: 'Mutation', disableUser: (
    { __typename?: 'User' }
    & UserFragmentFragment
  ) };

export type GetActiveSupermetricsTaskQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActiveSupermetricsTaskQuery = { __typename?: 'Query', getActiveSupermetricsTask: Array<(
    { __typename?: 'SupermetricsSyncTask' }
    & SupermetricsSyncTaskFragmentFragment
  )> };

export type GetCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCategoriesQuery = { __typename?: 'Query', categories: Array<(
    { __typename?: 'TopicCategory', children?: Array<(
      { __typename?: 'TopicCategory' }
      & CategoryFragmentFragment
    )> | undefined }
    & CategoryFragmentFragment
  )> };

export type GetClusterQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetClusterQuery = { __typename?: 'Query', getCluster?: (
    { __typename?: 'KeywordCluster', keywords: Array<{ __typename?: 'Keyword', id: string, title: string, scrapers: Array<Scraper> }> }
    & KeywordClusterFragment
  ) | undefined };

export type DimCoursesQueryVariables = Exact<{ [key: string]: never; }>;


export type DimCoursesQuery = { __typename?: 'Query', getMongoDbCollectionData?: { __typename?: 'MongoCollectionData', rows: Array<any> } | undefined };

export type GetTopicDraftsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTopicDraftsQuery = { __typename?: 'Query', drafts: Array<any> };

export type GetKeywordStatisticsQueryVariables = Exact<{
  keywordId: Scalars['ID']['input'];
  scraper: Scraper;
}>;


export type GetKeywordStatisticsQuery = { __typename?: 'Query', getKeywordStatistics?: { __typename?: 'KeywordStatistic', scraper: Scraper, keyword: { __typename?: 'Keyword', id: string, title: string }, data: Array<{ __typename?: 'StatisticData', datetime: any, value: number, category: string }> } | undefined };

export type GetScheduledDatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetScheduledDatesQuery = { __typename?: 'Query', getScheduledDates: Array<{ __typename?: 'ScheduledDate', scheduledDate: any, status: ScheduledDateStatus, id: string }> };

export type GetStagesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStagesQuery = { __typename?: 'Query', stages: Array<{ __typename?: 'Stage', title: string, states: Array<string>, lockedStates: Array<string> }> };

export type GetSupermetricsTaskQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetSupermetricsTaskQuery = { __typename?: 'Query', getSupermetricsTask?: (
    { __typename?: 'SupermetricsSyncTask' }
    & SupermetricsSyncTaskFragmentFragment
  ) | undefined };

export type GetSupermetricsTasksQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSupermetricsTasksQuery = { __typename?: 'Query', getSupermetricsTasks: Array<(
    { __typename?: 'SupermetricsSyncTask' }
    & SupermetricsSyncTaskFragmentFragment
  )> };

export type GetTopicQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetTopicQuery = { __typename?: 'Query', getTopic?: (
    { __typename?: 'Topic' }
    & TopicFragmentFragment
  ) | undefined };

export type GetTopicClustersQueryVariables = Exact<{
  topicId: Scalars['ID']['input'];
}>;


export type GetTopicClustersQuery = { __typename?: 'Query', getTopic?: { __typename?: 'Topic', clusterBuilderResult?: string | undefined, clusters: Array<{ __typename?: 'KeywordCluster', id: string, description?: string | undefined, version: string, keywords: Array<{ __typename?: 'Keyword', id: string, scrapers: Array<Scraper>, title: string }> }> } | undefined };

export type GetTopicCommentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetTopicCommentsQuery = { __typename?: 'Query', getTopic?: { __typename?: 'Topic', id: string, comments: Array<(
      { __typename?: 'Comment' }
      & CommentFragmentFragment
    )> } | undefined };

export type GetTopicHistoryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetTopicHistoryQuery = { __typename?: 'Query', getTopic?: { __typename?: 'Topic', id: string, history: Array<(
      { __typename?: 'HistoryChange' }
      & TopicHistoryFragmentFragment
    )> } | undefined };

export type GetTopicsQueryVariables = Exact<{
  filter: TopicFilter;
  withAuthor: Scalars['Boolean']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<TopicSort>;
}>;


export type GetTopicsQuery = { __typename?: 'Query', getTopics: { __typename?: 'TopicPagination', items: Array<{ __typename?: 'Topic', id: string, createdAt: any, title: string, status: string, clusterBuilderStatus: ClusterBuilderStatus, state: TopicState, createdBy?: { __typename?: 'User', id: string, fullName: string, picture: string } }> } };

export type GetTopicsCountQueryVariables = Exact<{
  period: TopicsCountPeriod;
}>;


export type GetTopicsCountQuery = { __typename?: 'Query', topicsCount: number };

export type GetUserQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetUserQuery = { __typename?: 'Query', user?: (
    { __typename?: 'User' }
    & UserFragmentFragment
  ) | undefined };

export type GetUsersListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersListQuery = { __typename?: 'Query', users: Array<(
    { __typename?: 'User' }
    & UserFragmentFragment
  )> };

export type IsSubscribedToStateQueryVariables = Exact<{
  state: Scalars['String']['input'];
}>;


export type IsSubscribedToStateQuery = { __typename?: 'Query', isSubscribedToState: boolean };

export type KeywordClusterFragment = { __typename?: 'KeywordCluster', id: string, description?: string | undefined, version: string };

export type LoginQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type LoginQuery = { __typename?: 'Query', login: boolean };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: (
    { __typename?: 'User' }
    & UserFragmentFragment
  ) | undefined };

export type RunSupermetricsTaskNowMutationVariables = Exact<{
  taskId: Scalars['ID']['input'];
  dateRange: DateRange;
}>;


export type RunSupermetricsTaskNowMutation = { __typename?: 'Mutation', runSupermetricsTaskNow: { __typename?: 'Result', query?: { __typename?: 'Query', getSupermetricsTasks: Array<(
        { __typename?: 'SupermetricsSyncTask' }
        & SupermetricsSyncTaskFragmentFragment
      )> } | undefined, error?: { __typename?: 'Error', message: string } | undefined } };

export type SaveDraftTopicsMutationVariables = Exact<{
  input: SaveDraftTopicsInput;
}>;


export type SaveDraftTopicsMutation = { __typename?: 'Mutation', saveDraftTopics: { __typename?: 'Result', query?: { __typename?: 'Query', drafts: Array<any> } | undefined } };

export type ScheduleTaskMutationVariables = Exact<{
  taskId: Scalars['ID']['input'];
}>;


export type ScheduleTaskMutation = { __typename?: 'Mutation', scheduleTask: { __typename?: 'Result', query?: { __typename?: 'Query', getSupermetricsTasks: Array<(
        { __typename?: 'SupermetricsSyncTask' }
        & SupermetricsSyncTaskFragmentFragment
      )> } | undefined } };

export type StopSupermetircsTaskMutationVariables = Exact<{
  taskId: Scalars['ID']['input'];
}>;


export type StopSupermetircsTaskMutation = { __typename?: 'Mutation', stopSupermetricsTask: { __typename?: 'Result', query?: { __typename?: 'Query', getSupermetricsTasks: Array<(
        { __typename?: 'SupermetricsSyncTask' }
        & SupermetricsSyncTaskFragmentFragment
      )> } | undefined } };

export type SubscribeOnStateMutationVariables = Exact<{
  state: Scalars['String']['input'];
}>;


export type SubscribeOnStateMutation = { __typename?: 'Mutation', subscribeOnState: { __typename?: 'Result', error?: { __typename?: 'Error', message: string } | undefined, query?: { __typename?: 'Query', me?: { __typename?: 'User', subscriptions: Array<string> } | undefined } | undefined } };

export type SupermetricsSyncTaskFragmentFragment = { __typename?: 'SupermetricsSyncTask', id: string, title?: string | undefined, description?: string | undefined, createdAt: any, requestId?: string | undefined, scheduleId?: string | undefined, status: SupermetricsSyncTaskStatus, request?: any | undefined, scheduled: boolean, log: Array<string>, updatedAt?: any | undefined, history: Array<any>, crontab?: string | undefined };

export type TopicFragmentFragment = { __typename?: 'Topic', id: string, createdAt: any, deletedAt?: any | undefined, updatedAt?: any | undefined, category?: Array<string> | undefined, campaigns?: Array<string> | undefined, status: string, statusChangedAt?: any | undefined, title: string, description?: string | undefined, courseType: Array<string>, howTos?: string | undefined, importantLinks?: Array<string> | undefined, redditLink?: string | undefined, creatorsLinks?: Array<string> | undefined, seedKeywords?: Array<string> | undefined, toolsAndSkills?: string | undefined, howDidYouComeUp?: string | undefined, courseSkill: string, clusterBuilderStatus: ClusterBuilderStatus, clusterBuilderResult?: string | undefined, asanaTaskLink?: string | undefined, state: TopicState, createdBy: (
    { __typename?: 'User' }
    & UserFragmentFragment
  ), clusters: Array<{ __typename?: 'KeywordCluster', id: string, version: string, keywords: Array<{ __typename?: 'Keyword', id: string, title: string, scrapers: Array<Scraper> }> }> };

export type GetTopicClustersCountQueryVariables = Exact<{
  topicId: Scalars['ID']['input'];
}>;


export type GetTopicClustersCountQuery = { __typename?: 'Query', getTopic?: { __typename?: 'Topic', id: string, clustersCount: number } | undefined };

export type TopicHistoryFragmentFragment = { __typename?: 'HistoryChange', id: string, date: any, description: string, author: { __typename?: 'User', id: string, fullName: string } };

export type UnscheduleTaskMutationVariables = Exact<{
  taskId: Scalars['ID']['input'];
}>;


export type UnscheduleTaskMutation = { __typename?: 'Mutation', unscheduleTask: { __typename?: 'Result', query?: { __typename?: 'Query', getSupermetricsTasks: Array<(
        { __typename?: 'SupermetricsSyncTask' }
        & SupermetricsSyncTaskFragmentFragment
      )> } | undefined } };

export type UnsubscribeFromStateMutationVariables = Exact<{
  state: Scalars['String']['input'];
}>;


export type UnsubscribeFromStateMutation = { __typename?: 'Mutation', unsubscribeFromState: { __typename?: 'Result', error?: { __typename?: 'Error', message: string } | undefined, query?: { __typename?: 'Query', me?: { __typename?: 'User', subscriptions: Array<string> } | undefined } | undefined } };

export type UpdateCommentMutationVariables = Exact<{
  input: UpdateCommentInput;
}>;


export type UpdateCommentMutation = { __typename?: 'Mutation', updateComment: { __typename?: 'Comment', id: string, text: string, createdAt: any } };

export type UpdateTopicMetaMutationVariables = Exact<{
  input: UpdateTopicMetaInput;
}>;


export type UpdateTopicMetaMutation = { __typename?: 'Mutation', updateTopicMeta: (
    { __typename?: 'Topic' }
    & TopicFragmentFragment
  ) };

export type UpdateTopicStagesMutationVariables = Exact<{
  input: UpdateTopicStates;
}>;


export type UpdateTopicStagesMutation = { __typename?: 'Mutation', updateTopicStates: { __typename?: 'Result', error?: { __typename?: 'Error', message: string } | undefined } };

export type UpdateUserProfileMutationVariables = Exact<{
  input: UpdateUserProfileInput;
}>;


export type UpdateUserProfileMutation = { __typename?: 'Mutation', updateUserProfile?: (
    { __typename?: 'User' }
    & UserFragmentFragment
  ) | undefined };

export type UserFragmentFragment = { __typename?: 'User', id: string, email: string, picture: string, firstName: string, lastName: string, fullName: string, created: boolean, createdAt: any, deletedAt?: any | undefined };

export const CategoryFragmentFragmentDoc = gql`
    fragment CategoryFragment on TopicCategory {
  label
  value
}
    `;
export const CommentFragmentFragmentDoc = gql`
    fragment CommentFragment on Comment {
  id
  text
  createdAt
  author {
    id
    fullName
  }
}
    `;
export const KeywordClusterFragmentDoc = gql`
    fragment KeywordCluster on KeywordCluster {
  id
  description
  version
}
    `;
export const SupermetricsSyncTaskFragmentFragmentDoc = gql`
    fragment SupermetricsSyncTaskFragment on SupermetricsSyncTask {
  id
  title
  description
  createdAt
  requestId
  scheduleId
  status
  request
  scheduled
  log
  updatedAt
  history
  crontab
}
    `;
export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on User {
  id
  email
  picture
  firstName
  lastName
  fullName
  created
  createdAt
  deletedAt
}
    `;
export const TopicFragmentFragmentDoc = gql`
    fragment TopicFragment on Topic {
  id
  createdAt
  deletedAt
  updatedAt
  category
  campaigns
  createdBy {
    ...UserFragment
  }
  status
  statusChangedAt
  title
  description
  courseType
  howTos
  importantLinks
  redditLink
  creatorsLinks
  seedKeywords
  toolsAndSkills
  howDidYouComeUp
  courseSkill
  clusterBuilderStatus
  clusterBuilderResult
  asanaTaskLink
  state
  clusters {
    id
    version
    keywords {
      id
      title
      scrapers
    }
  }
}
    ${UserFragmentFragmentDoc}`;
export const TopicHistoryFragmentFragmentDoc = gql`
    fragment TopicHistoryFragment on HistoryChange {
  id
  date
  author {
    id
    fullName
  }
  description
}
    `;
export const ActivateUserDocument = gql`
    mutation activateUser($id: ID!) {
  activateUser(input: {id: $id}) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type ActivateUserMutationFn = Apollo.MutationFunction<ActivateUserMutation, ActivateUserMutationVariables>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateUserMutation(baseOptions?: Apollo.MutationHookOptions<ActivateUserMutation, ActivateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateUserMutation, ActivateUserMutationVariables>(ActivateUserDocument, options);
      }
export type ActivateUserMutationHookResult = ReturnType<typeof useActivateUserMutation>;
export type ActivateUserMutationResult = Apollo.MutationResult<ActivateUserMutation>;
export type ActivateUserMutationOptions = Apollo.BaseMutationOptions<ActivateUserMutation, ActivateUserMutationVariables>;
export const AddCommentDocument = gql`
    mutation addComment($input: AddCommentInput!) {
  addComment(input: $input) {
    id
    createdAt
    text
  }
}
    `;
export type AddCommentMutationFn = Apollo.MutationFunction<AddCommentMutation, AddCommentMutationVariables>;

/**
 * __useAddCommentMutation__
 *
 * To run a mutation, you first call `useAddCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentMutation, { data, loading, error }] = useAddCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCommentMutation(baseOptions?: Apollo.MutationHookOptions<AddCommentMutation, AddCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCommentMutation, AddCommentMutationVariables>(AddCommentDocument, options);
      }
export type AddCommentMutationHookResult = ReturnType<typeof useAddCommentMutation>;
export type AddCommentMutationResult = Apollo.MutationResult<AddCommentMutation>;
export type AddCommentMutationOptions = Apollo.BaseMutationOptions<AddCommentMutation, AddCommentMutationVariables>;
export const AddUserDocument = gql`
    mutation addUser($input: AddUserInput!) {
  addUser(input: $input) {
    query {
      users {
        ...UserFragment
      }
    }
  }
}
    ${UserFragmentFragmentDoc}`;
export type AddUserMutationFn = Apollo.MutationFunction<AddUserMutation, AddUserMutationVariables>;

/**
 * __useAddUserMutation__
 *
 * To run a mutation, you first call `useAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutation, { data, loading, error }] = useAddUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserMutation(baseOptions?: Apollo.MutationHookOptions<AddUserMutation, AddUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserMutation, AddUserMutationVariables>(AddUserDocument, options);
      }
export type AddUserMutationHookResult = ReturnType<typeof useAddUserMutation>;
export type AddUserMutationResult = Apollo.MutationResult<AddUserMutation>;
export type AddUserMutationOptions = Apollo.BaseMutationOptions<AddUserMutation, AddUserMutationVariables>;
export const ApplyHistoryRequestDocument = gql`
    mutation applyHistoryRequest($input: ApplyHistoryRequestInput!) {
  applyHistoryRequest(input: $input) {
    query {
      getActiveSupermetricsTask {
        ...SupermetricsSyncTaskFragment
      }
      getSupermetricsTasks {
        ...SupermetricsSyncTaskFragment
      }
    }
  }
}
    ${SupermetricsSyncTaskFragmentFragmentDoc}`;
export type ApplyHistoryRequestMutationFn = Apollo.MutationFunction<ApplyHistoryRequestMutation, ApplyHistoryRequestMutationVariables>;

/**
 * __useApplyHistoryRequestMutation__
 *
 * To run a mutation, you first call `useApplyHistoryRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyHistoryRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyHistoryRequestMutation, { data, loading, error }] = useApplyHistoryRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyHistoryRequestMutation(baseOptions?: Apollo.MutationHookOptions<ApplyHistoryRequestMutation, ApplyHistoryRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyHistoryRequestMutation, ApplyHistoryRequestMutationVariables>(ApplyHistoryRequestDocument, options);
      }
export type ApplyHistoryRequestMutationHookResult = ReturnType<typeof useApplyHistoryRequestMutation>;
export type ApplyHistoryRequestMutationResult = Apollo.MutationResult<ApplyHistoryRequestMutation>;
export type ApplyHistoryRequestMutationOptions = Apollo.BaseMutationOptions<ApplyHistoryRequestMutation, ApplyHistoryRequestMutationVariables>;
export const CancelTaskDocument = gql`
    mutation cancelTask($taskId: ID!) {
  cancelSupermetricsTask(input: {taskId: $taskId}) {
    query {
      getActiveSupermetricsTask {
        ...SupermetricsSyncTaskFragment
      }
      getSupermetricsTasks {
        ...SupermetricsSyncTaskFragment
      }
    }
  }
}
    ${SupermetricsSyncTaskFragmentFragmentDoc}`;
export type CancelTaskMutationFn = Apollo.MutationFunction<CancelTaskMutation, CancelTaskMutationVariables>;

/**
 * __useCancelTaskMutation__
 *
 * To run a mutation, you first call `useCancelTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTaskMutation, { data, loading, error }] = useCancelTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useCancelTaskMutation(baseOptions?: Apollo.MutationHookOptions<CancelTaskMutation, CancelTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelTaskMutation, CancelTaskMutationVariables>(CancelTaskDocument, options);
      }
export type CancelTaskMutationHookResult = ReturnType<typeof useCancelTaskMutation>;
export type CancelTaskMutationResult = Apollo.MutationResult<CancelTaskMutation>;
export type CancelTaskMutationOptions = Apollo.BaseMutationOptions<CancelTaskMutation, CancelTaskMutationVariables>;
export const ChangeTaskSchedulingDocument = gql`
    mutation changeTaskScheduling($taskId: ID!, $crontab: String!) {
  changeTaskScheduling(input: {taskId: $taskId, crontab: $crontab}) {
    query {
      getSupermetricsTask(id: $taskId) {
        ...SupermetricsSyncTaskFragment
      }
    }
    error {
      __typename
      message
    }
  }
}
    ${SupermetricsSyncTaskFragmentFragmentDoc}`;
export type ChangeTaskSchedulingMutationFn = Apollo.MutationFunction<ChangeTaskSchedulingMutation, ChangeTaskSchedulingMutationVariables>;

/**
 * __useChangeTaskSchedulingMutation__
 *
 * To run a mutation, you first call `useChangeTaskSchedulingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTaskSchedulingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTaskSchedulingMutation, { data, loading, error }] = useChangeTaskSchedulingMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      crontab: // value for 'crontab'
 *   },
 * });
 */
export function useChangeTaskSchedulingMutation(baseOptions?: Apollo.MutationHookOptions<ChangeTaskSchedulingMutation, ChangeTaskSchedulingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeTaskSchedulingMutation, ChangeTaskSchedulingMutationVariables>(ChangeTaskSchedulingDocument, options);
      }
export type ChangeTaskSchedulingMutationHookResult = ReturnType<typeof useChangeTaskSchedulingMutation>;
export type ChangeTaskSchedulingMutationResult = Apollo.MutationResult<ChangeTaskSchedulingMutation>;
export type ChangeTaskSchedulingMutationOptions = Apollo.BaseMutationOptions<ChangeTaskSchedulingMutation, ChangeTaskSchedulingMutationVariables>;
export const ChangeTopicCategoryDocument = gql`
    mutation changeTopicCategory($input: ChangeTopicCategoryInput!) {
  changeTopicCategory(input: $input) {
    ...TopicFragment
  }
}
    ${TopicFragmentFragmentDoc}`;
export type ChangeTopicCategoryMutationFn = Apollo.MutationFunction<ChangeTopicCategoryMutation, ChangeTopicCategoryMutationVariables>;

/**
 * __useChangeTopicCategoryMutation__
 *
 * To run a mutation, you first call `useChangeTopicCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTopicCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTopicCategoryMutation, { data, loading, error }] = useChangeTopicCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeTopicCategoryMutation(baseOptions?: Apollo.MutationHookOptions<ChangeTopicCategoryMutation, ChangeTopicCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeTopicCategoryMutation, ChangeTopicCategoryMutationVariables>(ChangeTopicCategoryDocument, options);
      }
export type ChangeTopicCategoryMutationHookResult = ReturnType<typeof useChangeTopicCategoryMutation>;
export type ChangeTopicCategoryMutationResult = Apollo.MutationResult<ChangeTopicCategoryMutation>;
export type ChangeTopicCategoryMutationOptions = Apollo.BaseMutationOptions<ChangeTopicCategoryMutation, ChangeTopicCategoryMutationVariables>;
export const ChangeTopicCourseSkillDocument = gql`
    mutation changeTopicCourseSkill($input: ChangeTopicCourseSkillInput!) {
  changeTopicCourseSkill(input: $input) {
    ...TopicFragment
  }
}
    ${TopicFragmentFragmentDoc}`;
export type ChangeTopicCourseSkillMutationFn = Apollo.MutationFunction<ChangeTopicCourseSkillMutation, ChangeTopicCourseSkillMutationVariables>;

/**
 * __useChangeTopicCourseSkillMutation__
 *
 * To run a mutation, you first call `useChangeTopicCourseSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTopicCourseSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTopicCourseSkillMutation, { data, loading, error }] = useChangeTopicCourseSkillMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeTopicCourseSkillMutation(baseOptions?: Apollo.MutationHookOptions<ChangeTopicCourseSkillMutation, ChangeTopicCourseSkillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeTopicCourseSkillMutation, ChangeTopicCourseSkillMutationVariables>(ChangeTopicCourseSkillDocument, options);
      }
export type ChangeTopicCourseSkillMutationHookResult = ReturnType<typeof useChangeTopicCourseSkillMutation>;
export type ChangeTopicCourseSkillMutationResult = Apollo.MutationResult<ChangeTopicCourseSkillMutation>;
export type ChangeTopicCourseSkillMutationOptions = Apollo.BaseMutationOptions<ChangeTopicCourseSkillMutation, ChangeTopicCourseSkillMutationVariables>;
export const ChangeTopicCourseTypeDocument = gql`
    mutation changeTopicCourseType($input: ChangeTopicCourseTypeInput!) {
  changeTopicCourseType(input: $input) {
    ...TopicFragment
  }
}
    ${TopicFragmentFragmentDoc}`;
export type ChangeTopicCourseTypeMutationFn = Apollo.MutationFunction<ChangeTopicCourseTypeMutation, ChangeTopicCourseTypeMutationVariables>;

/**
 * __useChangeTopicCourseTypeMutation__
 *
 * To run a mutation, you first call `useChangeTopicCourseTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTopicCourseTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTopicCourseTypeMutation, { data, loading, error }] = useChangeTopicCourseTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeTopicCourseTypeMutation(baseOptions?: Apollo.MutationHookOptions<ChangeTopicCourseTypeMutation, ChangeTopicCourseTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeTopicCourseTypeMutation, ChangeTopicCourseTypeMutationVariables>(ChangeTopicCourseTypeDocument, options);
      }
export type ChangeTopicCourseTypeMutationHookResult = ReturnType<typeof useChangeTopicCourseTypeMutation>;
export type ChangeTopicCourseTypeMutationResult = Apollo.MutationResult<ChangeTopicCourseTypeMutation>;
export type ChangeTopicCourseTypeMutationOptions = Apollo.BaseMutationOptions<ChangeTopicCourseTypeMutation, ChangeTopicCourseTypeMutationVariables>;
export const ChangeTopicCreatorsLinksDocument = gql`
    mutation changeTopicCreatorsLinks($input: ChangeTopicCreatorsLinksInput!) {
  changeTopicCreatorsLinks(input: $input) {
    ...TopicFragment
  }
}
    ${TopicFragmentFragmentDoc}`;
export type ChangeTopicCreatorsLinksMutationFn = Apollo.MutationFunction<ChangeTopicCreatorsLinksMutation, ChangeTopicCreatorsLinksMutationVariables>;

/**
 * __useChangeTopicCreatorsLinksMutation__
 *
 * To run a mutation, you first call `useChangeTopicCreatorsLinksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTopicCreatorsLinksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTopicCreatorsLinksMutation, { data, loading, error }] = useChangeTopicCreatorsLinksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeTopicCreatorsLinksMutation(baseOptions?: Apollo.MutationHookOptions<ChangeTopicCreatorsLinksMutation, ChangeTopicCreatorsLinksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeTopicCreatorsLinksMutation, ChangeTopicCreatorsLinksMutationVariables>(ChangeTopicCreatorsLinksDocument, options);
      }
export type ChangeTopicCreatorsLinksMutationHookResult = ReturnType<typeof useChangeTopicCreatorsLinksMutation>;
export type ChangeTopicCreatorsLinksMutationResult = Apollo.MutationResult<ChangeTopicCreatorsLinksMutation>;
export type ChangeTopicCreatorsLinksMutationOptions = Apollo.BaseMutationOptions<ChangeTopicCreatorsLinksMutation, ChangeTopicCreatorsLinksMutationVariables>;
export const ChangeTopicDescriptionDocument = gql`
    mutation changeTopicDescription($input: ChangeTopicDescriptionInput!) {
  changeTopicDescription(input: $input) {
    ...TopicFragment
  }
}
    ${TopicFragmentFragmentDoc}`;
export type ChangeTopicDescriptionMutationFn = Apollo.MutationFunction<ChangeTopicDescriptionMutation, ChangeTopicDescriptionMutationVariables>;

/**
 * __useChangeTopicDescriptionMutation__
 *
 * To run a mutation, you first call `useChangeTopicDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTopicDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTopicDescriptionMutation, { data, loading, error }] = useChangeTopicDescriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeTopicDescriptionMutation(baseOptions?: Apollo.MutationHookOptions<ChangeTopicDescriptionMutation, ChangeTopicDescriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeTopicDescriptionMutation, ChangeTopicDescriptionMutationVariables>(ChangeTopicDescriptionDocument, options);
      }
export type ChangeTopicDescriptionMutationHookResult = ReturnType<typeof useChangeTopicDescriptionMutation>;
export type ChangeTopicDescriptionMutationResult = Apollo.MutationResult<ChangeTopicDescriptionMutation>;
export type ChangeTopicDescriptionMutationOptions = Apollo.BaseMutationOptions<ChangeTopicDescriptionMutation, ChangeTopicDescriptionMutationVariables>;
export const ChangeTopicHowDidYouComeToItDocument = gql`
    mutation changeTopicHowDidYouComeToIt($input: ChangeTopicHowDidYouComeToItInput!) {
  changeTopicHowDidYouComeToIt(input: $input) {
    ...TopicFragment
  }
}
    ${TopicFragmentFragmentDoc}`;
export type ChangeTopicHowDidYouComeToItMutationFn = Apollo.MutationFunction<ChangeTopicHowDidYouComeToItMutation, ChangeTopicHowDidYouComeToItMutationVariables>;

/**
 * __useChangeTopicHowDidYouComeToItMutation__
 *
 * To run a mutation, you first call `useChangeTopicHowDidYouComeToItMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTopicHowDidYouComeToItMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTopicHowDidYouComeToItMutation, { data, loading, error }] = useChangeTopicHowDidYouComeToItMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeTopicHowDidYouComeToItMutation(baseOptions?: Apollo.MutationHookOptions<ChangeTopicHowDidYouComeToItMutation, ChangeTopicHowDidYouComeToItMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeTopicHowDidYouComeToItMutation, ChangeTopicHowDidYouComeToItMutationVariables>(ChangeTopicHowDidYouComeToItDocument, options);
      }
export type ChangeTopicHowDidYouComeToItMutationHookResult = ReturnType<typeof useChangeTopicHowDidYouComeToItMutation>;
export type ChangeTopicHowDidYouComeToItMutationResult = Apollo.MutationResult<ChangeTopicHowDidYouComeToItMutation>;
export type ChangeTopicHowDidYouComeToItMutationOptions = Apollo.BaseMutationOptions<ChangeTopicHowDidYouComeToItMutation, ChangeTopicHowDidYouComeToItMutationVariables>;
export const ChangeTopicHowTosDocument = gql`
    mutation changeTopicHowTos($input: ChangeTopicHowTosInput!) {
  changeTopicHowTos(input: $input) {
    ...TopicFragment
  }
}
    ${TopicFragmentFragmentDoc}`;
export type ChangeTopicHowTosMutationFn = Apollo.MutationFunction<ChangeTopicHowTosMutation, ChangeTopicHowTosMutationVariables>;

/**
 * __useChangeTopicHowTosMutation__
 *
 * To run a mutation, you first call `useChangeTopicHowTosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTopicHowTosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTopicHowTosMutation, { data, loading, error }] = useChangeTopicHowTosMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeTopicHowTosMutation(baseOptions?: Apollo.MutationHookOptions<ChangeTopicHowTosMutation, ChangeTopicHowTosMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeTopicHowTosMutation, ChangeTopicHowTosMutationVariables>(ChangeTopicHowTosDocument, options);
      }
export type ChangeTopicHowTosMutationHookResult = ReturnType<typeof useChangeTopicHowTosMutation>;
export type ChangeTopicHowTosMutationResult = Apollo.MutationResult<ChangeTopicHowTosMutation>;
export type ChangeTopicHowTosMutationOptions = Apollo.BaseMutationOptions<ChangeTopicHowTosMutation, ChangeTopicHowTosMutationVariables>;
export const ChangeTopicImportantLinksDocument = gql`
    mutation changeTopicImportantLinks($input: ChangeTopicImportantLinksInput!) {
  changeTopicImportantLinks(input: $input) {
    ...TopicFragment
  }
}
    ${TopicFragmentFragmentDoc}`;
export type ChangeTopicImportantLinksMutationFn = Apollo.MutationFunction<ChangeTopicImportantLinksMutation, ChangeTopicImportantLinksMutationVariables>;

/**
 * __useChangeTopicImportantLinksMutation__
 *
 * To run a mutation, you first call `useChangeTopicImportantLinksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTopicImportantLinksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTopicImportantLinksMutation, { data, loading, error }] = useChangeTopicImportantLinksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeTopicImportantLinksMutation(baseOptions?: Apollo.MutationHookOptions<ChangeTopicImportantLinksMutation, ChangeTopicImportantLinksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeTopicImportantLinksMutation, ChangeTopicImportantLinksMutationVariables>(ChangeTopicImportantLinksDocument, options);
      }
export type ChangeTopicImportantLinksMutationHookResult = ReturnType<typeof useChangeTopicImportantLinksMutation>;
export type ChangeTopicImportantLinksMutationResult = Apollo.MutationResult<ChangeTopicImportantLinksMutation>;
export type ChangeTopicImportantLinksMutationOptions = Apollo.BaseMutationOptions<ChangeTopicImportantLinksMutation, ChangeTopicImportantLinksMutationVariables>;
export const ChangeTopicMarketingCampaignDocument = gql`
    mutation changeTopicMarketingCampaign($input: ChangeTopicMarketingCampaignInput!) {
  changeTopicMarketingCampaign(input: $input) {
    ...TopicFragment
  }
}
    ${TopicFragmentFragmentDoc}`;
export type ChangeTopicMarketingCampaignMutationFn = Apollo.MutationFunction<ChangeTopicMarketingCampaignMutation, ChangeTopicMarketingCampaignMutationVariables>;

/**
 * __useChangeTopicMarketingCampaignMutation__
 *
 * To run a mutation, you first call `useChangeTopicMarketingCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTopicMarketingCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTopicMarketingCampaignMutation, { data, loading, error }] = useChangeTopicMarketingCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeTopicMarketingCampaignMutation(baseOptions?: Apollo.MutationHookOptions<ChangeTopicMarketingCampaignMutation, ChangeTopicMarketingCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeTopicMarketingCampaignMutation, ChangeTopicMarketingCampaignMutationVariables>(ChangeTopicMarketingCampaignDocument, options);
      }
export type ChangeTopicMarketingCampaignMutationHookResult = ReturnType<typeof useChangeTopicMarketingCampaignMutation>;
export type ChangeTopicMarketingCampaignMutationResult = Apollo.MutationResult<ChangeTopicMarketingCampaignMutation>;
export type ChangeTopicMarketingCampaignMutationOptions = Apollo.BaseMutationOptions<ChangeTopicMarketingCampaignMutation, ChangeTopicMarketingCampaignMutationVariables>;
export const ChangeTopicRedditLinkDocument = gql`
    mutation changeTopicRedditLink($input: ChangeTopicRedditLinkInput!) {
  changeTopicRedditLink(input: $input) {
    ...TopicFragment
  }
}
    ${TopicFragmentFragmentDoc}`;
export type ChangeTopicRedditLinkMutationFn = Apollo.MutationFunction<ChangeTopicRedditLinkMutation, ChangeTopicRedditLinkMutationVariables>;

/**
 * __useChangeTopicRedditLinkMutation__
 *
 * To run a mutation, you first call `useChangeTopicRedditLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTopicRedditLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTopicRedditLinkMutation, { data, loading, error }] = useChangeTopicRedditLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeTopicRedditLinkMutation(baseOptions?: Apollo.MutationHookOptions<ChangeTopicRedditLinkMutation, ChangeTopicRedditLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeTopicRedditLinkMutation, ChangeTopicRedditLinkMutationVariables>(ChangeTopicRedditLinkDocument, options);
      }
export type ChangeTopicRedditLinkMutationHookResult = ReturnType<typeof useChangeTopicRedditLinkMutation>;
export type ChangeTopicRedditLinkMutationResult = Apollo.MutationResult<ChangeTopicRedditLinkMutation>;
export type ChangeTopicRedditLinkMutationOptions = Apollo.BaseMutationOptions<ChangeTopicRedditLinkMutation, ChangeTopicRedditLinkMutationVariables>;
export const ChangeTopicSeedKeywordsDocument = gql`
    mutation changeTopicSeedKeywords($input: ChangeTopicSeedKeywordsInput!) {
  changeTopicSeedKeywords(input: $input) {
    ...TopicFragment
  }
}
    ${TopicFragmentFragmentDoc}`;
export type ChangeTopicSeedKeywordsMutationFn = Apollo.MutationFunction<ChangeTopicSeedKeywordsMutation, ChangeTopicSeedKeywordsMutationVariables>;

/**
 * __useChangeTopicSeedKeywordsMutation__
 *
 * To run a mutation, you first call `useChangeTopicSeedKeywordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTopicSeedKeywordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTopicSeedKeywordsMutation, { data, loading, error }] = useChangeTopicSeedKeywordsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeTopicSeedKeywordsMutation(baseOptions?: Apollo.MutationHookOptions<ChangeTopicSeedKeywordsMutation, ChangeTopicSeedKeywordsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeTopicSeedKeywordsMutation, ChangeTopicSeedKeywordsMutationVariables>(ChangeTopicSeedKeywordsDocument, options);
      }
export type ChangeTopicSeedKeywordsMutationHookResult = ReturnType<typeof useChangeTopicSeedKeywordsMutation>;
export type ChangeTopicSeedKeywordsMutationResult = Apollo.MutationResult<ChangeTopicSeedKeywordsMutation>;
export type ChangeTopicSeedKeywordsMutationOptions = Apollo.BaseMutationOptions<ChangeTopicSeedKeywordsMutation, ChangeTopicSeedKeywordsMutationVariables>;
export const ChangeTopicStateDocument = gql`
    mutation changeTopicState($topicId: ID!, $newState: TopicState!, $reason: String!) {
  changeTopicState(input: {topicId: $topicId, state: $newState, reason: $reason}) {
    error {
      message
    }
  }
}
    `;
export type ChangeTopicStateMutationFn = Apollo.MutationFunction<ChangeTopicStateMutation, ChangeTopicStateMutationVariables>;

/**
 * __useChangeTopicStateMutation__
 *
 * To run a mutation, you first call `useChangeTopicStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTopicStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTopicStateMutation, { data, loading, error }] = useChangeTopicStateMutation({
 *   variables: {
 *      topicId: // value for 'topicId'
 *      newState: // value for 'newState'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useChangeTopicStateMutation(baseOptions?: Apollo.MutationHookOptions<ChangeTopicStateMutation, ChangeTopicStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeTopicStateMutation, ChangeTopicStateMutationVariables>(ChangeTopicStateDocument, options);
      }
export type ChangeTopicStateMutationHookResult = ReturnType<typeof useChangeTopicStateMutation>;
export type ChangeTopicStateMutationResult = Apollo.MutationResult<ChangeTopicStateMutation>;
export type ChangeTopicStateMutationOptions = Apollo.BaseMutationOptions<ChangeTopicStateMutation, ChangeTopicStateMutationVariables>;
export const ChangeTopicStatusDocument = gql`
    mutation changeTopicStatus($input: ChangeTopicStatusInput!, $filter: TopicFilter!) {
  changeTopicStatus(input: $input) {
    getTopics(filter: $filter) {
      items {
        id
        title
        status
      }
    }
  }
}
    `;
export type ChangeTopicStatusMutationFn = Apollo.MutationFunction<ChangeTopicStatusMutation, ChangeTopicStatusMutationVariables>;

/**
 * __useChangeTopicStatusMutation__
 *
 * To run a mutation, you first call `useChangeTopicStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTopicStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTopicStatusMutation, { data, loading, error }] = useChangeTopicStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useChangeTopicStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeTopicStatusMutation, ChangeTopicStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeTopicStatusMutation, ChangeTopicStatusMutationVariables>(ChangeTopicStatusDocument, options);
      }
export type ChangeTopicStatusMutationHookResult = ReturnType<typeof useChangeTopicStatusMutation>;
export type ChangeTopicStatusMutationResult = Apollo.MutationResult<ChangeTopicStatusMutation>;
export type ChangeTopicStatusMutationOptions = Apollo.BaseMutationOptions<ChangeTopicStatusMutation, ChangeTopicStatusMutationVariables>;
export const ChangeTopicTitleDocument = gql`
    mutation changeTopicTitle($input: ChangeTopicTitleInput!) {
  changeTopicTitle(input: $input) {
    ...TopicFragment
  }
}
    ${TopicFragmentFragmentDoc}`;
export type ChangeTopicTitleMutationFn = Apollo.MutationFunction<ChangeTopicTitleMutation, ChangeTopicTitleMutationVariables>;

/**
 * __useChangeTopicTitleMutation__
 *
 * To run a mutation, you first call `useChangeTopicTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTopicTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTopicTitleMutation, { data, loading, error }] = useChangeTopicTitleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeTopicTitleMutation(baseOptions?: Apollo.MutationHookOptions<ChangeTopicTitleMutation, ChangeTopicTitleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeTopicTitleMutation, ChangeTopicTitleMutationVariables>(ChangeTopicTitleDocument, options);
      }
export type ChangeTopicTitleMutationHookResult = ReturnType<typeof useChangeTopicTitleMutation>;
export type ChangeTopicTitleMutationResult = Apollo.MutationResult<ChangeTopicTitleMutation>;
export type ChangeTopicTitleMutationOptions = Apollo.BaseMutationOptions<ChangeTopicTitleMutation, ChangeTopicTitleMutationVariables>;
export const CreateClusterDocument = gql`
    mutation createCluster($input: CreateClusterInput!) {
  createCluster(input: $input) {
    ...KeywordCluster
  }
}
    ${KeywordClusterFragmentDoc}`;
export type CreateClusterMutationFn = Apollo.MutationFunction<CreateClusterMutation, CreateClusterMutationVariables>;

/**
 * __useCreateClusterMutation__
 *
 * To run a mutation, you first call `useCreateClusterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClusterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClusterMutation, { data, loading, error }] = useCreateClusterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClusterMutation(baseOptions?: Apollo.MutationHookOptions<CreateClusterMutation, CreateClusterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClusterMutation, CreateClusterMutationVariables>(CreateClusterDocument, options);
      }
export type CreateClusterMutationHookResult = ReturnType<typeof useCreateClusterMutation>;
export type CreateClusterMutationResult = Apollo.MutationResult<CreateClusterMutation>;
export type CreateClusterMutationOptions = Apollo.BaseMutationOptions<CreateClusterMutation, CreateClusterMutationVariables>;
export const CreateSupermetricsTaskDocument = gql`
    mutation createSupermetricsTask($input: CreateSupermetricsTaskInput!) {
  createSupermetricsTask(input: $input) {
    query {
      getSupermetricsTasks {
        ...SupermetricsSyncTaskFragment
      }
    }
    error {
      message
    }
  }
}
    ${SupermetricsSyncTaskFragmentFragmentDoc}`;
export type CreateSupermetricsTaskMutationFn = Apollo.MutationFunction<CreateSupermetricsTaskMutation, CreateSupermetricsTaskMutationVariables>;

/**
 * __useCreateSupermetricsTaskMutation__
 *
 * To run a mutation, you first call `useCreateSupermetricsTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupermetricsTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupermetricsTaskMutation, { data, loading, error }] = useCreateSupermetricsTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSupermetricsTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateSupermetricsTaskMutation, CreateSupermetricsTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSupermetricsTaskMutation, CreateSupermetricsTaskMutationVariables>(CreateSupermetricsTaskDocument, options);
      }
export type CreateSupermetricsTaskMutationHookResult = ReturnType<typeof useCreateSupermetricsTaskMutation>;
export type CreateSupermetricsTaskMutationResult = Apollo.MutationResult<CreateSupermetricsTaskMutation>;
export type CreateSupermetricsTaskMutationOptions = Apollo.BaseMutationOptions<CreateSupermetricsTaskMutation, CreateSupermetricsTaskMutationVariables>;
export const AddTopicDocument = gql`
    mutation addTopic($input: AddTopicInput!) {
  addTopic(input: $input) {
    ...TopicFragment
  }
}
    ${TopicFragmentFragmentDoc}`;
export type AddTopicMutationFn = Apollo.MutationFunction<AddTopicMutation, AddTopicMutationVariables>;

/**
 * __useAddTopicMutation__
 *
 * To run a mutation, you first call `useAddTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTopicMutation, { data, loading, error }] = useAddTopicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTopicMutation(baseOptions?: Apollo.MutationHookOptions<AddTopicMutation, AddTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTopicMutation, AddTopicMutationVariables>(AddTopicDocument, options);
      }
export type AddTopicMutationHookResult = ReturnType<typeof useAddTopicMutation>;
export type AddTopicMutationResult = Apollo.MutationResult<AddTopicMutation>;
export type AddTopicMutationOptions = Apollo.BaseMutationOptions<AddTopicMutation, AddTopicMutationVariables>;
export const DeleteClusterDocument = gql`
    mutation deleteCluster($input: DeleteClusterInput!) {
  deleteCluster(input: $input)
}
    `;
export type DeleteClusterMutationFn = Apollo.MutationFunction<DeleteClusterMutation, DeleteClusterMutationVariables>;

/**
 * __useDeleteClusterMutation__
 *
 * To run a mutation, you first call `useDeleteClusterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClusterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClusterMutation, { data, loading, error }] = useDeleteClusterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteClusterMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClusterMutation, DeleteClusterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClusterMutation, DeleteClusterMutationVariables>(DeleteClusterDocument, options);
      }
export type DeleteClusterMutationHookResult = ReturnType<typeof useDeleteClusterMutation>;
export type DeleteClusterMutationResult = Apollo.MutationResult<DeleteClusterMutation>;
export type DeleteClusterMutationOptions = Apollo.BaseMutationOptions<DeleteClusterMutation, DeleteClusterMutationVariables>;
export const DeleteCommentDocument = gql`
    mutation deleteComment($input: DeleteCommentInput!) {
  deleteComment(input: $input)
}
    `;
export type DeleteCommentMutationFn = Apollo.MutationFunction<DeleteCommentMutation, DeleteCommentMutationVariables>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommentMutation, DeleteCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(DeleteCommentDocument, options);
      }
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<DeleteCommentMutation, DeleteCommentMutationVariables>;
export const DeleteRequestFromHistoryDocument = gql`
    mutation deleteRequestFromHistory($input: DeleteRequestFromHistoryInput!) {
  deleteRequestFromHistory(input: $input) {
    query {
      getSupermetricsTasks {
        ...SupermetricsSyncTaskFragment
      }
    }
  }
}
    ${SupermetricsSyncTaskFragmentFragmentDoc}`;
export type DeleteRequestFromHistoryMutationFn = Apollo.MutationFunction<DeleteRequestFromHistoryMutation, DeleteRequestFromHistoryMutationVariables>;

/**
 * __useDeleteRequestFromHistoryMutation__
 *
 * To run a mutation, you first call `useDeleteRequestFromHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRequestFromHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRequestFromHistoryMutation, { data, loading, error }] = useDeleteRequestFromHistoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRequestFromHistoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRequestFromHistoryMutation, DeleteRequestFromHistoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRequestFromHistoryMutation, DeleteRequestFromHistoryMutationVariables>(DeleteRequestFromHistoryDocument, options);
      }
export type DeleteRequestFromHistoryMutationHookResult = ReturnType<typeof useDeleteRequestFromHistoryMutation>;
export type DeleteRequestFromHistoryMutationResult = Apollo.MutationResult<DeleteRequestFromHistoryMutation>;
export type DeleteRequestFromHistoryMutationOptions = Apollo.BaseMutationOptions<DeleteRequestFromHistoryMutation, DeleteRequestFromHistoryMutationVariables>;
export const DeleteScheduledDateDocument = gql`
    mutation deleteScheduledDate($input: DeleteScheduledDateInput!) {
  deleteScheduledDate(input: $input) {
    error {
      message
    }
  }
}
    `;
export type DeleteScheduledDateMutationFn = Apollo.MutationFunction<DeleteScheduledDateMutation, DeleteScheduledDateMutationVariables>;

/**
 * __useDeleteScheduledDateMutation__
 *
 * To run a mutation, you first call `useDeleteScheduledDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScheduledDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScheduledDateMutation, { data, loading, error }] = useDeleteScheduledDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteScheduledDateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteScheduledDateMutation, DeleteScheduledDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteScheduledDateMutation, DeleteScheduledDateMutationVariables>(DeleteScheduledDateDocument, options);
      }
export type DeleteScheduledDateMutationHookResult = ReturnType<typeof useDeleteScheduledDateMutation>;
export type DeleteScheduledDateMutationResult = Apollo.MutationResult<DeleteScheduledDateMutation>;
export type DeleteScheduledDateMutationOptions = Apollo.BaseMutationOptions<DeleteScheduledDateMutation, DeleteScheduledDateMutationVariables>;
export const DeleteSupermetricsTaskDocument = gql`
    mutation deleteSupermetricsTask($taskId: ID!) {
  deleteSupermetricsTask(input: {taskId: $taskId}) {
    query {
      getSupermetricsTasks {
        ...SupermetricsSyncTaskFragment
      }
    }
    error {
      message
    }
  }
}
    ${SupermetricsSyncTaskFragmentFragmentDoc}`;
export type DeleteSupermetricsTaskMutationFn = Apollo.MutationFunction<DeleteSupermetricsTaskMutation, DeleteSupermetricsTaskMutationVariables>;

/**
 * __useDeleteSupermetricsTaskMutation__
 *
 * To run a mutation, you first call `useDeleteSupermetricsTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSupermetricsTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSupermetricsTaskMutation, { data, loading, error }] = useDeleteSupermetricsTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useDeleteSupermetricsTaskMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSupermetricsTaskMutation, DeleteSupermetricsTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSupermetricsTaskMutation, DeleteSupermetricsTaskMutationVariables>(DeleteSupermetricsTaskDocument, options);
      }
export type DeleteSupermetricsTaskMutationHookResult = ReturnType<typeof useDeleteSupermetricsTaskMutation>;
export type DeleteSupermetricsTaskMutationResult = Apollo.MutationResult<DeleteSupermetricsTaskMutation>;
export type DeleteSupermetricsTaskMutationOptions = Apollo.BaseMutationOptions<DeleteSupermetricsTaskMutation, DeleteSupermetricsTaskMutationVariables>;
export const DeleteTopicDocument = gql`
    mutation deleteTopic($id: ID!) {
  deleteTopic(id: $id)
}
    `;
export type DeleteTopicMutationFn = Apollo.MutationFunction<DeleteTopicMutation, DeleteTopicMutationVariables>;

/**
 * __useDeleteTopicMutation__
 *
 * To run a mutation, you first call `useDeleteTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTopicMutation, { data, loading, error }] = useDeleteTopicMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTopicMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTopicMutation, DeleteTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTopicMutation, DeleteTopicMutationVariables>(DeleteTopicDocument, options);
      }
export type DeleteTopicMutationHookResult = ReturnType<typeof useDeleteTopicMutation>;
export type DeleteTopicMutationResult = Apollo.MutationResult<DeleteTopicMutation>;
export type DeleteTopicMutationOptions = Apollo.BaseMutationOptions<DeleteTopicMutation, DeleteTopicMutationVariables>;
export const DisableUserDocument = gql`
    mutation disableUser($id: ID!) {
  disableUser(input: {id: $id}) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type DisableUserMutationFn = Apollo.MutationFunction<DisableUserMutation, DisableUserMutationVariables>;

/**
 * __useDisableUserMutation__
 *
 * To run a mutation, you first call `useDisableUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableUserMutation, { data, loading, error }] = useDisableUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisableUserMutation(baseOptions?: Apollo.MutationHookOptions<DisableUserMutation, DisableUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableUserMutation, DisableUserMutationVariables>(DisableUserDocument, options);
      }
export type DisableUserMutationHookResult = ReturnType<typeof useDisableUserMutation>;
export type DisableUserMutationResult = Apollo.MutationResult<DisableUserMutation>;
export type DisableUserMutationOptions = Apollo.BaseMutationOptions<DisableUserMutation, DisableUserMutationVariables>;
export const GetActiveSupermetricsTaskDocument = gql`
    query getActiveSupermetricsTask {
  getActiveSupermetricsTask {
    ...SupermetricsSyncTaskFragment
  }
}
    ${SupermetricsSyncTaskFragmentFragmentDoc}`;

/**
 * __useGetActiveSupermetricsTaskQuery__
 *
 * To run a query within a React component, call `useGetActiveSupermetricsTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveSupermetricsTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveSupermetricsTaskQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveSupermetricsTaskQuery(baseOptions?: Apollo.QueryHookOptions<GetActiveSupermetricsTaskQuery, GetActiveSupermetricsTaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveSupermetricsTaskQuery, GetActiveSupermetricsTaskQueryVariables>(GetActiveSupermetricsTaskDocument, options);
      }
export function useGetActiveSupermetricsTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveSupermetricsTaskQuery, GetActiveSupermetricsTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveSupermetricsTaskQuery, GetActiveSupermetricsTaskQueryVariables>(GetActiveSupermetricsTaskDocument, options);
        }
export function useGetActiveSupermetricsTaskSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetActiveSupermetricsTaskQuery, GetActiveSupermetricsTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetActiveSupermetricsTaskQuery, GetActiveSupermetricsTaskQueryVariables>(GetActiveSupermetricsTaskDocument, options);
        }
export type GetActiveSupermetricsTaskQueryHookResult = ReturnType<typeof useGetActiveSupermetricsTaskQuery>;
export type GetActiveSupermetricsTaskLazyQueryHookResult = ReturnType<typeof useGetActiveSupermetricsTaskLazyQuery>;
export type GetActiveSupermetricsTaskSuspenseQueryHookResult = ReturnType<typeof useGetActiveSupermetricsTaskSuspenseQuery>;
export type GetActiveSupermetricsTaskQueryResult = Apollo.QueryResult<GetActiveSupermetricsTaskQuery, GetActiveSupermetricsTaskQueryVariables>;
export const GetCategoriesDocument = gql`
    query getCategories {
  categories {
    ...CategoryFragment
    children {
      ...CategoryFragment
    }
  }
}
    ${CategoryFragmentFragmentDoc}`;

/**
 * __useGetCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
      }
export function useGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
        }
export function useGetCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
        }
export type GetCategoriesQueryHookResult = ReturnType<typeof useGetCategoriesQuery>;
export type GetCategoriesLazyQueryHookResult = ReturnType<typeof useGetCategoriesLazyQuery>;
export type GetCategoriesSuspenseQueryHookResult = ReturnType<typeof useGetCategoriesSuspenseQuery>;
export type GetCategoriesQueryResult = Apollo.QueryResult<GetCategoriesQuery, GetCategoriesQueryVariables>;
export const GetClusterDocument = gql`
    query getCluster($id: ID!) {
  getCluster(id: $id) {
    ...KeywordCluster
    keywords {
      id
      title
      scrapers
    }
  }
}
    ${KeywordClusterFragmentDoc}`;

/**
 * __useGetClusterQuery__
 *
 * To run a query within a React component, call `useGetClusterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClusterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClusterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClusterQuery(baseOptions: Apollo.QueryHookOptions<GetClusterQuery, GetClusterQueryVariables> & ({ variables: GetClusterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClusterQuery, GetClusterQueryVariables>(GetClusterDocument, options);
      }
export function useGetClusterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClusterQuery, GetClusterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClusterQuery, GetClusterQueryVariables>(GetClusterDocument, options);
        }
export function useGetClusterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetClusterQuery, GetClusterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetClusterQuery, GetClusterQueryVariables>(GetClusterDocument, options);
        }
export type GetClusterQueryHookResult = ReturnType<typeof useGetClusterQuery>;
export type GetClusterLazyQueryHookResult = ReturnType<typeof useGetClusterLazyQuery>;
export type GetClusterSuspenseQueryHookResult = ReturnType<typeof useGetClusterSuspenseQuery>;
export type GetClusterQueryResult = Apollo.QueryResult<GetClusterQuery, GetClusterQueryVariables>;
export const DimCoursesDocument = gql`
    query dimCourses {
  getMongoDbCollectionData(input: {collectionName: "dim_courses"}) {
    rows
  }
}
    `;

/**
 * __useDimCoursesQuery__
 *
 * To run a query within a React component, call `useDimCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDimCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDimCoursesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDimCoursesQuery(baseOptions?: Apollo.QueryHookOptions<DimCoursesQuery, DimCoursesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DimCoursesQuery, DimCoursesQueryVariables>(DimCoursesDocument, options);
      }
export function useDimCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DimCoursesQuery, DimCoursesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DimCoursesQuery, DimCoursesQueryVariables>(DimCoursesDocument, options);
        }
export function useDimCoursesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DimCoursesQuery, DimCoursesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DimCoursesQuery, DimCoursesQueryVariables>(DimCoursesDocument, options);
        }
export type DimCoursesQueryHookResult = ReturnType<typeof useDimCoursesQuery>;
export type DimCoursesLazyQueryHookResult = ReturnType<typeof useDimCoursesLazyQuery>;
export type DimCoursesSuspenseQueryHookResult = ReturnType<typeof useDimCoursesSuspenseQuery>;
export type DimCoursesQueryResult = Apollo.QueryResult<DimCoursesQuery, DimCoursesQueryVariables>;
export const GetTopicDraftsDocument = gql`
    query getTopicDrafts {
  drafts
}
    `;

/**
 * __useGetTopicDraftsQuery__
 *
 * To run a query within a React component, call `useGetTopicDraftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopicDraftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopicDraftsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTopicDraftsQuery(baseOptions?: Apollo.QueryHookOptions<GetTopicDraftsQuery, GetTopicDraftsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTopicDraftsQuery, GetTopicDraftsQueryVariables>(GetTopicDraftsDocument, options);
      }
export function useGetTopicDraftsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopicDraftsQuery, GetTopicDraftsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTopicDraftsQuery, GetTopicDraftsQueryVariables>(GetTopicDraftsDocument, options);
        }
export function useGetTopicDraftsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTopicDraftsQuery, GetTopicDraftsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTopicDraftsQuery, GetTopicDraftsQueryVariables>(GetTopicDraftsDocument, options);
        }
export type GetTopicDraftsQueryHookResult = ReturnType<typeof useGetTopicDraftsQuery>;
export type GetTopicDraftsLazyQueryHookResult = ReturnType<typeof useGetTopicDraftsLazyQuery>;
export type GetTopicDraftsSuspenseQueryHookResult = ReturnType<typeof useGetTopicDraftsSuspenseQuery>;
export type GetTopicDraftsQueryResult = Apollo.QueryResult<GetTopicDraftsQuery, GetTopicDraftsQueryVariables>;
export const GetKeywordStatisticsDocument = gql`
    query getKeywordStatistics($keywordId: ID!, $scraper: Scraper!) {
  getKeywordStatistics(filter: {keywordId: $keywordId, scraper: $scraper}) {
    scraper
    keyword {
      id
      title
    }
    data {
      datetime
      value
      category
    }
  }
}
    `;

/**
 * __useGetKeywordStatisticsQuery__
 *
 * To run a query within a React component, call `useGetKeywordStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKeywordStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKeywordStatisticsQuery({
 *   variables: {
 *      keywordId: // value for 'keywordId'
 *      scraper: // value for 'scraper'
 *   },
 * });
 */
export function useGetKeywordStatisticsQuery(baseOptions: Apollo.QueryHookOptions<GetKeywordStatisticsQuery, GetKeywordStatisticsQueryVariables> & ({ variables: GetKeywordStatisticsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKeywordStatisticsQuery, GetKeywordStatisticsQueryVariables>(GetKeywordStatisticsDocument, options);
      }
export function useGetKeywordStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKeywordStatisticsQuery, GetKeywordStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKeywordStatisticsQuery, GetKeywordStatisticsQueryVariables>(GetKeywordStatisticsDocument, options);
        }
export function useGetKeywordStatisticsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetKeywordStatisticsQuery, GetKeywordStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetKeywordStatisticsQuery, GetKeywordStatisticsQueryVariables>(GetKeywordStatisticsDocument, options);
        }
export type GetKeywordStatisticsQueryHookResult = ReturnType<typeof useGetKeywordStatisticsQuery>;
export type GetKeywordStatisticsLazyQueryHookResult = ReturnType<typeof useGetKeywordStatisticsLazyQuery>;
export type GetKeywordStatisticsSuspenseQueryHookResult = ReturnType<typeof useGetKeywordStatisticsSuspenseQuery>;
export type GetKeywordStatisticsQueryResult = Apollo.QueryResult<GetKeywordStatisticsQuery, GetKeywordStatisticsQueryVariables>;
export const GetScheduledDatesDocument = gql`
    query getScheduledDates {
  getScheduledDates {
    scheduledDate
    status
    id
  }
}
    `;

/**
 * __useGetScheduledDatesQuery__
 *
 * To run a query within a React component, call `useGetScheduledDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledDatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetScheduledDatesQuery(baseOptions?: Apollo.QueryHookOptions<GetScheduledDatesQuery, GetScheduledDatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScheduledDatesQuery, GetScheduledDatesQueryVariables>(GetScheduledDatesDocument, options);
      }
export function useGetScheduledDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScheduledDatesQuery, GetScheduledDatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScheduledDatesQuery, GetScheduledDatesQueryVariables>(GetScheduledDatesDocument, options);
        }
export function useGetScheduledDatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetScheduledDatesQuery, GetScheduledDatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetScheduledDatesQuery, GetScheduledDatesQueryVariables>(GetScheduledDatesDocument, options);
        }
export type GetScheduledDatesQueryHookResult = ReturnType<typeof useGetScheduledDatesQuery>;
export type GetScheduledDatesLazyQueryHookResult = ReturnType<typeof useGetScheduledDatesLazyQuery>;
export type GetScheduledDatesSuspenseQueryHookResult = ReturnType<typeof useGetScheduledDatesSuspenseQuery>;
export type GetScheduledDatesQueryResult = Apollo.QueryResult<GetScheduledDatesQuery, GetScheduledDatesQueryVariables>;
export const GetStagesDocument = gql`
    query getStages {
  stages {
    title
    states
    lockedStates
  }
}
    `;

/**
 * __useGetStagesQuery__
 *
 * To run a query within a React component, call `useGetStagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStagesQuery(baseOptions?: Apollo.QueryHookOptions<GetStagesQuery, GetStagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStagesQuery, GetStagesQueryVariables>(GetStagesDocument, options);
      }
export function useGetStagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStagesQuery, GetStagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStagesQuery, GetStagesQueryVariables>(GetStagesDocument, options);
        }
export function useGetStagesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetStagesQuery, GetStagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStagesQuery, GetStagesQueryVariables>(GetStagesDocument, options);
        }
export type GetStagesQueryHookResult = ReturnType<typeof useGetStagesQuery>;
export type GetStagesLazyQueryHookResult = ReturnType<typeof useGetStagesLazyQuery>;
export type GetStagesSuspenseQueryHookResult = ReturnType<typeof useGetStagesSuspenseQuery>;
export type GetStagesQueryResult = Apollo.QueryResult<GetStagesQuery, GetStagesQueryVariables>;
export const GetSupermetricsTaskDocument = gql`
    query getSupermetricsTask($id: ID!) {
  getSupermetricsTask(id: $id) {
    ...SupermetricsSyncTaskFragment
  }
}
    ${SupermetricsSyncTaskFragmentFragmentDoc}`;

/**
 * __useGetSupermetricsTaskQuery__
 *
 * To run a query within a React component, call `useGetSupermetricsTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupermetricsTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupermetricsTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSupermetricsTaskQuery(baseOptions: Apollo.QueryHookOptions<GetSupermetricsTaskQuery, GetSupermetricsTaskQueryVariables> & ({ variables: GetSupermetricsTaskQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupermetricsTaskQuery, GetSupermetricsTaskQueryVariables>(GetSupermetricsTaskDocument, options);
      }
export function useGetSupermetricsTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupermetricsTaskQuery, GetSupermetricsTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupermetricsTaskQuery, GetSupermetricsTaskQueryVariables>(GetSupermetricsTaskDocument, options);
        }
export function useGetSupermetricsTaskSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSupermetricsTaskQuery, GetSupermetricsTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSupermetricsTaskQuery, GetSupermetricsTaskQueryVariables>(GetSupermetricsTaskDocument, options);
        }
export type GetSupermetricsTaskQueryHookResult = ReturnType<typeof useGetSupermetricsTaskQuery>;
export type GetSupermetricsTaskLazyQueryHookResult = ReturnType<typeof useGetSupermetricsTaskLazyQuery>;
export type GetSupermetricsTaskSuspenseQueryHookResult = ReturnType<typeof useGetSupermetricsTaskSuspenseQuery>;
export type GetSupermetricsTaskQueryResult = Apollo.QueryResult<GetSupermetricsTaskQuery, GetSupermetricsTaskQueryVariables>;
export const GetSupermetricsTasksDocument = gql`
    query getSupermetricsTasks {
  getSupermetricsTasks {
    ...SupermetricsSyncTaskFragment
  }
}
    ${SupermetricsSyncTaskFragmentFragmentDoc}`;

/**
 * __useGetSupermetricsTasksQuery__
 *
 * To run a query within a React component, call `useGetSupermetricsTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupermetricsTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupermetricsTasksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSupermetricsTasksQuery(baseOptions?: Apollo.QueryHookOptions<GetSupermetricsTasksQuery, GetSupermetricsTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupermetricsTasksQuery, GetSupermetricsTasksQueryVariables>(GetSupermetricsTasksDocument, options);
      }
export function useGetSupermetricsTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupermetricsTasksQuery, GetSupermetricsTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupermetricsTasksQuery, GetSupermetricsTasksQueryVariables>(GetSupermetricsTasksDocument, options);
        }
export function useGetSupermetricsTasksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSupermetricsTasksQuery, GetSupermetricsTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSupermetricsTasksQuery, GetSupermetricsTasksQueryVariables>(GetSupermetricsTasksDocument, options);
        }
export type GetSupermetricsTasksQueryHookResult = ReturnType<typeof useGetSupermetricsTasksQuery>;
export type GetSupermetricsTasksLazyQueryHookResult = ReturnType<typeof useGetSupermetricsTasksLazyQuery>;
export type GetSupermetricsTasksSuspenseQueryHookResult = ReturnType<typeof useGetSupermetricsTasksSuspenseQuery>;
export type GetSupermetricsTasksQueryResult = Apollo.QueryResult<GetSupermetricsTasksQuery, GetSupermetricsTasksQueryVariables>;
export const GetTopicDocument = gql`
    query getTopic($id: ID!) {
  getTopic(id: $id) {
    ...TopicFragment
  }
}
    ${TopicFragmentFragmentDoc}`;

/**
 * __useGetTopicQuery__
 *
 * To run a query within a React component, call `useGetTopicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTopicQuery(baseOptions: Apollo.QueryHookOptions<GetTopicQuery, GetTopicQueryVariables> & ({ variables: GetTopicQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTopicQuery, GetTopicQueryVariables>(GetTopicDocument, options);
      }
export function useGetTopicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopicQuery, GetTopicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTopicQuery, GetTopicQueryVariables>(GetTopicDocument, options);
        }
export function useGetTopicSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTopicQuery, GetTopicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTopicQuery, GetTopicQueryVariables>(GetTopicDocument, options);
        }
export type GetTopicQueryHookResult = ReturnType<typeof useGetTopicQuery>;
export type GetTopicLazyQueryHookResult = ReturnType<typeof useGetTopicLazyQuery>;
export type GetTopicSuspenseQueryHookResult = ReturnType<typeof useGetTopicSuspenseQuery>;
export type GetTopicQueryResult = Apollo.QueryResult<GetTopicQuery, GetTopicQueryVariables>;
export const GetTopicClustersDocument = gql`
    query getTopicClusters($topicId: ID!) {
  getTopic(id: $topicId) {
    clusterBuilderResult
    clusters {
      id
      description
      keywords {
        id
        scrapers
        title
      }
      version
    }
  }
}
    `;

/**
 * __useGetTopicClustersQuery__
 *
 * To run a query within a React component, call `useGetTopicClustersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopicClustersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopicClustersQuery({
 *   variables: {
 *      topicId: // value for 'topicId'
 *   },
 * });
 */
export function useGetTopicClustersQuery(baseOptions: Apollo.QueryHookOptions<GetTopicClustersQuery, GetTopicClustersQueryVariables> & ({ variables: GetTopicClustersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTopicClustersQuery, GetTopicClustersQueryVariables>(GetTopicClustersDocument, options);
      }
export function useGetTopicClustersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopicClustersQuery, GetTopicClustersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTopicClustersQuery, GetTopicClustersQueryVariables>(GetTopicClustersDocument, options);
        }
export function useGetTopicClustersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTopicClustersQuery, GetTopicClustersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTopicClustersQuery, GetTopicClustersQueryVariables>(GetTopicClustersDocument, options);
        }
export type GetTopicClustersQueryHookResult = ReturnType<typeof useGetTopicClustersQuery>;
export type GetTopicClustersLazyQueryHookResult = ReturnType<typeof useGetTopicClustersLazyQuery>;
export type GetTopicClustersSuspenseQueryHookResult = ReturnType<typeof useGetTopicClustersSuspenseQuery>;
export type GetTopicClustersQueryResult = Apollo.QueryResult<GetTopicClustersQuery, GetTopicClustersQueryVariables>;
export const GetTopicCommentsDocument = gql`
    query getTopicComments($id: ID!) {
  getTopic(id: $id) {
    id
    comments {
      ...CommentFragment
    }
  }
}
    ${CommentFragmentFragmentDoc}`;

/**
 * __useGetTopicCommentsQuery__
 *
 * To run a query within a React component, call `useGetTopicCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopicCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopicCommentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTopicCommentsQuery(baseOptions: Apollo.QueryHookOptions<GetTopicCommentsQuery, GetTopicCommentsQueryVariables> & ({ variables: GetTopicCommentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTopicCommentsQuery, GetTopicCommentsQueryVariables>(GetTopicCommentsDocument, options);
      }
export function useGetTopicCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopicCommentsQuery, GetTopicCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTopicCommentsQuery, GetTopicCommentsQueryVariables>(GetTopicCommentsDocument, options);
        }
export function useGetTopicCommentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTopicCommentsQuery, GetTopicCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTopicCommentsQuery, GetTopicCommentsQueryVariables>(GetTopicCommentsDocument, options);
        }
export type GetTopicCommentsQueryHookResult = ReturnType<typeof useGetTopicCommentsQuery>;
export type GetTopicCommentsLazyQueryHookResult = ReturnType<typeof useGetTopicCommentsLazyQuery>;
export type GetTopicCommentsSuspenseQueryHookResult = ReturnType<typeof useGetTopicCommentsSuspenseQuery>;
export type GetTopicCommentsQueryResult = Apollo.QueryResult<GetTopicCommentsQuery, GetTopicCommentsQueryVariables>;
export const GetTopicHistoryDocument = gql`
    query getTopicHistory($id: ID!) {
  getTopic(id: $id) {
    id
    history {
      ...TopicHistoryFragment
    }
  }
}
    ${TopicHistoryFragmentFragmentDoc}`;

/**
 * __useGetTopicHistoryQuery__
 *
 * To run a query within a React component, call `useGetTopicHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopicHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopicHistoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTopicHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetTopicHistoryQuery, GetTopicHistoryQueryVariables> & ({ variables: GetTopicHistoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTopicHistoryQuery, GetTopicHistoryQueryVariables>(GetTopicHistoryDocument, options);
      }
export function useGetTopicHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopicHistoryQuery, GetTopicHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTopicHistoryQuery, GetTopicHistoryQueryVariables>(GetTopicHistoryDocument, options);
        }
export function useGetTopicHistorySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTopicHistoryQuery, GetTopicHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTopicHistoryQuery, GetTopicHistoryQueryVariables>(GetTopicHistoryDocument, options);
        }
export type GetTopicHistoryQueryHookResult = ReturnType<typeof useGetTopicHistoryQuery>;
export type GetTopicHistoryLazyQueryHookResult = ReturnType<typeof useGetTopicHistoryLazyQuery>;
export type GetTopicHistorySuspenseQueryHookResult = ReturnType<typeof useGetTopicHistorySuspenseQuery>;
export type GetTopicHistoryQueryResult = Apollo.QueryResult<GetTopicHistoryQuery, GetTopicHistoryQueryVariables>;
export const GetTopicsDocument = gql`
    query getTopics($filter: TopicFilter!, $withAuthor: Boolean!, $skip: Int, $limit: Int, $sort: TopicSort) {
  getTopics(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
    items {
      id
      createdAt
      title
      status
      clusterBuilderStatus
      createdBy @include(if: $withAuthor) {
        id
        fullName
        picture
      }
      state
    }
  }
}
    `;

/**
 * __useGetTopicsQuery__
 *
 * To run a query within a React component, call `useGetTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopicsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      withAuthor: // value for 'withAuthor'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetTopicsQuery(baseOptions: Apollo.QueryHookOptions<GetTopicsQuery, GetTopicsQueryVariables> & ({ variables: GetTopicsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTopicsQuery, GetTopicsQueryVariables>(GetTopicsDocument, options);
      }
export function useGetTopicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopicsQuery, GetTopicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTopicsQuery, GetTopicsQueryVariables>(GetTopicsDocument, options);
        }
export function useGetTopicsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTopicsQuery, GetTopicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTopicsQuery, GetTopicsQueryVariables>(GetTopicsDocument, options);
        }
export type GetTopicsQueryHookResult = ReturnType<typeof useGetTopicsQuery>;
export type GetTopicsLazyQueryHookResult = ReturnType<typeof useGetTopicsLazyQuery>;
export type GetTopicsSuspenseQueryHookResult = ReturnType<typeof useGetTopicsSuspenseQuery>;
export type GetTopicsQueryResult = Apollo.QueryResult<GetTopicsQuery, GetTopicsQueryVariables>;
export const GetTopicsCountDocument = gql`
    query getTopicsCount($period: TopicsCountPeriod!) {
  topicsCount(input: {period: $period})
}
    `;

/**
 * __useGetTopicsCountQuery__
 *
 * To run a query within a React component, call `useGetTopicsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopicsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopicsCountQuery({
 *   variables: {
 *      period: // value for 'period'
 *   },
 * });
 */
export function useGetTopicsCountQuery(baseOptions: Apollo.QueryHookOptions<GetTopicsCountQuery, GetTopicsCountQueryVariables> & ({ variables: GetTopicsCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTopicsCountQuery, GetTopicsCountQueryVariables>(GetTopicsCountDocument, options);
      }
export function useGetTopicsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopicsCountQuery, GetTopicsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTopicsCountQuery, GetTopicsCountQueryVariables>(GetTopicsCountDocument, options);
        }
export function useGetTopicsCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTopicsCountQuery, GetTopicsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTopicsCountQuery, GetTopicsCountQueryVariables>(GetTopicsCountDocument, options);
        }
export type GetTopicsCountQueryHookResult = ReturnType<typeof useGetTopicsCountQuery>;
export type GetTopicsCountLazyQueryHookResult = ReturnType<typeof useGetTopicsCountLazyQuery>;
export type GetTopicsCountSuspenseQueryHookResult = ReturnType<typeof useGetTopicsCountSuspenseQuery>;
export type GetTopicsCountQueryResult = Apollo.QueryResult<GetTopicsCountQuery, GetTopicsCountQueryVariables>;
export const GetUserDocument = gql`
    query getUser($id: ID!) {
  user(id: $id) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables> & ({ variables: GetUserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export function useGetUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserSuspenseQueryHookResult = ReturnType<typeof useGetUserSuspenseQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUsersListDocument = gql`
    query getUsersList {
  users {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useGetUsersListQuery__
 *
 * To run a query within a React component, call `useGetUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersListQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersListQuery, GetUsersListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersListQuery, GetUsersListQueryVariables>(GetUsersListDocument, options);
      }
export function useGetUsersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersListQuery, GetUsersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersListQuery, GetUsersListQueryVariables>(GetUsersListDocument, options);
        }
export function useGetUsersListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsersListQuery, GetUsersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersListQuery, GetUsersListQueryVariables>(GetUsersListDocument, options);
        }
export type GetUsersListQueryHookResult = ReturnType<typeof useGetUsersListQuery>;
export type GetUsersListLazyQueryHookResult = ReturnType<typeof useGetUsersListLazyQuery>;
export type GetUsersListSuspenseQueryHookResult = ReturnType<typeof useGetUsersListSuspenseQuery>;
export type GetUsersListQueryResult = Apollo.QueryResult<GetUsersListQuery, GetUsersListQueryVariables>;
export const IsSubscribedToStateDocument = gql`
    query isSubscribedToState($state: String!) {
  isSubscribedToState(input: {state: $state})
}
    `;

/**
 * __useIsSubscribedToStateQuery__
 *
 * To run a query within a React component, call `useIsSubscribedToStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsSubscribedToStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsSubscribedToStateQuery({
 *   variables: {
 *      state: // value for 'state'
 *   },
 * });
 */
export function useIsSubscribedToStateQuery(baseOptions: Apollo.QueryHookOptions<IsSubscribedToStateQuery, IsSubscribedToStateQueryVariables> & ({ variables: IsSubscribedToStateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsSubscribedToStateQuery, IsSubscribedToStateQueryVariables>(IsSubscribedToStateDocument, options);
      }
export function useIsSubscribedToStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsSubscribedToStateQuery, IsSubscribedToStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsSubscribedToStateQuery, IsSubscribedToStateQueryVariables>(IsSubscribedToStateDocument, options);
        }
export function useIsSubscribedToStateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IsSubscribedToStateQuery, IsSubscribedToStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IsSubscribedToStateQuery, IsSubscribedToStateQueryVariables>(IsSubscribedToStateDocument, options);
        }
export type IsSubscribedToStateQueryHookResult = ReturnType<typeof useIsSubscribedToStateQuery>;
export type IsSubscribedToStateLazyQueryHookResult = ReturnType<typeof useIsSubscribedToStateLazyQuery>;
export type IsSubscribedToStateSuspenseQueryHookResult = ReturnType<typeof useIsSubscribedToStateSuspenseQuery>;
export type IsSubscribedToStateQueryResult = Apollo.QueryResult<IsSubscribedToStateQuery, IsSubscribedToStateQueryVariables>;
export const LoginDocument = gql`
    query login($email: String!) {
  login(input: {email: $email})
}
    `;

/**
 * __useLoginQuery__
 *
 * To run a query within a React component, call `useLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useLoginQuery(baseOptions: Apollo.QueryHookOptions<LoginQuery, LoginQueryVariables> & ({ variables: LoginQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
      }
export function useLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoginQuery, LoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
        }
export function useLoginSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LoginQuery, LoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
        }
export type LoginQueryHookResult = ReturnType<typeof useLoginQuery>;
export type LoginLazyQueryHookResult = ReturnType<typeof useLoginLazyQuery>;
export type LoginSuspenseQueryHookResult = ReturnType<typeof useLoginSuspenseQuery>;
export type LoginQueryResult = Apollo.QueryResult<LoginQuery, LoginQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export function useMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const RunSupermetricsTaskNowDocument = gql`
    mutation runSupermetricsTaskNow($taskId: ID!, $dateRange: DateRange!) {
  runSupermetricsTaskNow(input: {taskId: $taskId, dateRange: $dateRange}) {
    query {
      getSupermetricsTasks {
        ...SupermetricsSyncTaskFragment
      }
    }
    error {
      message
    }
  }
}
    ${SupermetricsSyncTaskFragmentFragmentDoc}`;
export type RunSupermetricsTaskNowMutationFn = Apollo.MutationFunction<RunSupermetricsTaskNowMutation, RunSupermetricsTaskNowMutationVariables>;

/**
 * __useRunSupermetricsTaskNowMutation__
 *
 * To run a mutation, you first call `useRunSupermetricsTaskNowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunSupermetricsTaskNowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runSupermetricsTaskNowMutation, { data, loading, error }] = useRunSupermetricsTaskNowMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      dateRange: // value for 'dateRange'
 *   },
 * });
 */
export function useRunSupermetricsTaskNowMutation(baseOptions?: Apollo.MutationHookOptions<RunSupermetricsTaskNowMutation, RunSupermetricsTaskNowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunSupermetricsTaskNowMutation, RunSupermetricsTaskNowMutationVariables>(RunSupermetricsTaskNowDocument, options);
      }
export type RunSupermetricsTaskNowMutationHookResult = ReturnType<typeof useRunSupermetricsTaskNowMutation>;
export type RunSupermetricsTaskNowMutationResult = Apollo.MutationResult<RunSupermetricsTaskNowMutation>;
export type RunSupermetricsTaskNowMutationOptions = Apollo.BaseMutationOptions<RunSupermetricsTaskNowMutation, RunSupermetricsTaskNowMutationVariables>;
export const SaveDraftTopicsDocument = gql`
    mutation saveDraftTopics($input: SaveDraftTopicsInput!) {
  saveDraftTopics(input: $input) {
    query {
      drafts
    }
  }
}
    `;
export type SaveDraftTopicsMutationFn = Apollo.MutationFunction<SaveDraftTopicsMutation, SaveDraftTopicsMutationVariables>;

/**
 * __useSaveDraftTopicsMutation__
 *
 * To run a mutation, you first call `useSaveDraftTopicsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDraftTopicsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDraftTopicsMutation, { data, loading, error }] = useSaveDraftTopicsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveDraftTopicsMutation(baseOptions?: Apollo.MutationHookOptions<SaveDraftTopicsMutation, SaveDraftTopicsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveDraftTopicsMutation, SaveDraftTopicsMutationVariables>(SaveDraftTopicsDocument, options);
      }
export type SaveDraftTopicsMutationHookResult = ReturnType<typeof useSaveDraftTopicsMutation>;
export type SaveDraftTopicsMutationResult = Apollo.MutationResult<SaveDraftTopicsMutation>;
export type SaveDraftTopicsMutationOptions = Apollo.BaseMutationOptions<SaveDraftTopicsMutation, SaveDraftTopicsMutationVariables>;
export const ScheduleTaskDocument = gql`
    mutation scheduleTask($taskId: ID!) {
  scheduleTask(input: {taskId: $taskId}) {
    query {
      getSupermetricsTasks {
        ...SupermetricsSyncTaskFragment
      }
    }
  }
}
    ${SupermetricsSyncTaskFragmentFragmentDoc}`;
export type ScheduleTaskMutationFn = Apollo.MutationFunction<ScheduleTaskMutation, ScheduleTaskMutationVariables>;

/**
 * __useScheduleTaskMutation__
 *
 * To run a mutation, you first call `useScheduleTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleTaskMutation, { data, loading, error }] = useScheduleTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useScheduleTaskMutation(baseOptions?: Apollo.MutationHookOptions<ScheduleTaskMutation, ScheduleTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScheduleTaskMutation, ScheduleTaskMutationVariables>(ScheduleTaskDocument, options);
      }
export type ScheduleTaskMutationHookResult = ReturnType<typeof useScheduleTaskMutation>;
export type ScheduleTaskMutationResult = Apollo.MutationResult<ScheduleTaskMutation>;
export type ScheduleTaskMutationOptions = Apollo.BaseMutationOptions<ScheduleTaskMutation, ScheduleTaskMutationVariables>;
export const StopSupermetircsTaskDocument = gql`
    mutation stopSupermetircsTask($taskId: ID!) {
  stopSupermetricsTask(input: {taskId: $taskId}) {
    query {
      getSupermetricsTasks {
        ...SupermetricsSyncTaskFragment
      }
    }
  }
}
    ${SupermetricsSyncTaskFragmentFragmentDoc}`;
export type StopSupermetircsTaskMutationFn = Apollo.MutationFunction<StopSupermetircsTaskMutation, StopSupermetircsTaskMutationVariables>;

/**
 * __useStopSupermetircsTaskMutation__
 *
 * To run a mutation, you first call `useStopSupermetircsTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopSupermetircsTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopSupermetircsTaskMutation, { data, loading, error }] = useStopSupermetircsTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useStopSupermetircsTaskMutation(baseOptions?: Apollo.MutationHookOptions<StopSupermetircsTaskMutation, StopSupermetircsTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StopSupermetircsTaskMutation, StopSupermetircsTaskMutationVariables>(StopSupermetircsTaskDocument, options);
      }
export type StopSupermetircsTaskMutationHookResult = ReturnType<typeof useStopSupermetircsTaskMutation>;
export type StopSupermetircsTaskMutationResult = Apollo.MutationResult<StopSupermetircsTaskMutation>;
export type StopSupermetircsTaskMutationOptions = Apollo.BaseMutationOptions<StopSupermetircsTaskMutation, StopSupermetircsTaskMutationVariables>;
export const SubscribeOnStateDocument = gql`
    mutation subscribeOnState($state: String!) {
  subscribeOnState(input: {state: $state}) {
    error {
      message
    }
    query {
      me {
        subscriptions
      }
    }
  }
}
    `;
export type SubscribeOnStateMutationFn = Apollo.MutationFunction<SubscribeOnStateMutation, SubscribeOnStateMutationVariables>;

/**
 * __useSubscribeOnStateMutation__
 *
 * To run a mutation, you first call `useSubscribeOnStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeOnStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeOnStateMutation, { data, loading, error }] = useSubscribeOnStateMutation({
 *   variables: {
 *      state: // value for 'state'
 *   },
 * });
 */
export function useSubscribeOnStateMutation(baseOptions?: Apollo.MutationHookOptions<SubscribeOnStateMutation, SubscribeOnStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscribeOnStateMutation, SubscribeOnStateMutationVariables>(SubscribeOnStateDocument, options);
      }
export type SubscribeOnStateMutationHookResult = ReturnType<typeof useSubscribeOnStateMutation>;
export type SubscribeOnStateMutationResult = Apollo.MutationResult<SubscribeOnStateMutation>;
export type SubscribeOnStateMutationOptions = Apollo.BaseMutationOptions<SubscribeOnStateMutation, SubscribeOnStateMutationVariables>;
export const GetTopicClustersCountDocument = gql`
    query getTopicClustersCount($topicId: ID!) {
  getTopic(id: $topicId) {
    id
    clustersCount
  }
}
    `;

/**
 * __useGetTopicClustersCountQuery__
 *
 * To run a query within a React component, call `useGetTopicClustersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopicClustersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopicClustersCountQuery({
 *   variables: {
 *      topicId: // value for 'topicId'
 *   },
 * });
 */
export function useGetTopicClustersCountQuery(baseOptions: Apollo.QueryHookOptions<GetTopicClustersCountQuery, GetTopicClustersCountQueryVariables> & ({ variables: GetTopicClustersCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTopicClustersCountQuery, GetTopicClustersCountQueryVariables>(GetTopicClustersCountDocument, options);
      }
export function useGetTopicClustersCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopicClustersCountQuery, GetTopicClustersCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTopicClustersCountQuery, GetTopicClustersCountQueryVariables>(GetTopicClustersCountDocument, options);
        }
export function useGetTopicClustersCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTopicClustersCountQuery, GetTopicClustersCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTopicClustersCountQuery, GetTopicClustersCountQueryVariables>(GetTopicClustersCountDocument, options);
        }
export type GetTopicClustersCountQueryHookResult = ReturnType<typeof useGetTopicClustersCountQuery>;
export type GetTopicClustersCountLazyQueryHookResult = ReturnType<typeof useGetTopicClustersCountLazyQuery>;
export type GetTopicClustersCountSuspenseQueryHookResult = ReturnType<typeof useGetTopicClustersCountSuspenseQuery>;
export type GetTopicClustersCountQueryResult = Apollo.QueryResult<GetTopicClustersCountQuery, GetTopicClustersCountQueryVariables>;
export const UnscheduleTaskDocument = gql`
    mutation unscheduleTask($taskId: ID!) {
  unscheduleTask(input: {taskId: $taskId}) {
    query {
      getSupermetricsTasks {
        ...SupermetricsSyncTaskFragment
      }
    }
  }
}
    ${SupermetricsSyncTaskFragmentFragmentDoc}`;
export type UnscheduleTaskMutationFn = Apollo.MutationFunction<UnscheduleTaskMutation, UnscheduleTaskMutationVariables>;

/**
 * __useUnscheduleTaskMutation__
 *
 * To run a mutation, you first call `useUnscheduleTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnscheduleTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unscheduleTaskMutation, { data, loading, error }] = useUnscheduleTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useUnscheduleTaskMutation(baseOptions?: Apollo.MutationHookOptions<UnscheduleTaskMutation, UnscheduleTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnscheduleTaskMutation, UnscheduleTaskMutationVariables>(UnscheduleTaskDocument, options);
      }
export type UnscheduleTaskMutationHookResult = ReturnType<typeof useUnscheduleTaskMutation>;
export type UnscheduleTaskMutationResult = Apollo.MutationResult<UnscheduleTaskMutation>;
export type UnscheduleTaskMutationOptions = Apollo.BaseMutationOptions<UnscheduleTaskMutation, UnscheduleTaskMutationVariables>;
export const UnsubscribeFromStateDocument = gql`
    mutation unsubscribeFromState($state: String!) {
  unsubscribeFromState(input: {state: $state}) {
    error {
      message
    }
    query {
      me {
        subscriptions
      }
    }
  }
}
    `;
export type UnsubscribeFromStateMutationFn = Apollo.MutationFunction<UnsubscribeFromStateMutation, UnsubscribeFromStateMutationVariables>;

/**
 * __useUnsubscribeFromStateMutation__
 *
 * To run a mutation, you first call `useUnsubscribeFromStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeFromStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeFromStateMutation, { data, loading, error }] = useUnsubscribeFromStateMutation({
 *   variables: {
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUnsubscribeFromStateMutation(baseOptions?: Apollo.MutationHookOptions<UnsubscribeFromStateMutation, UnsubscribeFromStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnsubscribeFromStateMutation, UnsubscribeFromStateMutationVariables>(UnsubscribeFromStateDocument, options);
      }
export type UnsubscribeFromStateMutationHookResult = ReturnType<typeof useUnsubscribeFromStateMutation>;
export type UnsubscribeFromStateMutationResult = Apollo.MutationResult<UnsubscribeFromStateMutation>;
export type UnsubscribeFromStateMutationOptions = Apollo.BaseMutationOptions<UnsubscribeFromStateMutation, UnsubscribeFromStateMutationVariables>;
export const UpdateCommentDocument = gql`
    mutation updateComment($input: UpdateCommentInput!) {
  updateComment(input: $input) {
    id
    text
    createdAt
  }
}
    `;
export type UpdateCommentMutationFn = Apollo.MutationFunction<UpdateCommentMutation, UpdateCommentMutationVariables>;

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommentMutation, UpdateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommentMutation, UpdateCommentMutationVariables>(UpdateCommentDocument, options);
      }
export type UpdateCommentMutationHookResult = ReturnType<typeof useUpdateCommentMutation>;
export type UpdateCommentMutationResult = Apollo.MutationResult<UpdateCommentMutation>;
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<UpdateCommentMutation, UpdateCommentMutationVariables>;
export const UpdateTopicMetaDocument = gql`
    mutation updateTopicMeta($input: UpdateTopicMetaInput!) {
  updateTopicMeta(input: $input) {
    ...TopicFragment
  }
}
    ${TopicFragmentFragmentDoc}`;
export type UpdateTopicMetaMutationFn = Apollo.MutationFunction<UpdateTopicMetaMutation, UpdateTopicMetaMutationVariables>;

/**
 * __useUpdateTopicMetaMutation__
 *
 * To run a mutation, you first call `useUpdateTopicMetaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTopicMetaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTopicMetaMutation, { data, loading, error }] = useUpdateTopicMetaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTopicMetaMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTopicMetaMutation, UpdateTopicMetaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTopicMetaMutation, UpdateTopicMetaMutationVariables>(UpdateTopicMetaDocument, options);
      }
export type UpdateTopicMetaMutationHookResult = ReturnType<typeof useUpdateTopicMetaMutation>;
export type UpdateTopicMetaMutationResult = Apollo.MutationResult<UpdateTopicMetaMutation>;
export type UpdateTopicMetaMutationOptions = Apollo.BaseMutationOptions<UpdateTopicMetaMutation, UpdateTopicMetaMutationVariables>;
export const UpdateTopicStagesDocument = gql`
    mutation updateTopicStages($input: UpdateTopicStates!) {
  updateTopicStates(input: $input) {
    error {
      message
    }
  }
}
    `;
export type UpdateTopicStagesMutationFn = Apollo.MutationFunction<UpdateTopicStagesMutation, UpdateTopicStagesMutationVariables>;

/**
 * __useUpdateTopicStagesMutation__
 *
 * To run a mutation, you first call `useUpdateTopicStagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTopicStagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTopicStagesMutation, { data, loading, error }] = useUpdateTopicStagesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTopicStagesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTopicStagesMutation, UpdateTopicStagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTopicStagesMutation, UpdateTopicStagesMutationVariables>(UpdateTopicStagesDocument, options);
      }
export type UpdateTopicStagesMutationHookResult = ReturnType<typeof useUpdateTopicStagesMutation>;
export type UpdateTopicStagesMutationResult = Apollo.MutationResult<UpdateTopicStagesMutation>;
export type UpdateTopicStagesMutationOptions = Apollo.BaseMutationOptions<UpdateTopicStagesMutation, UpdateTopicStagesMutationVariables>;
export const UpdateUserProfileDocument = gql`
    mutation updateUserProfile($input: UpdateUserProfileInput!) {
  updateUserProfile(input: $input) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument, options);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    