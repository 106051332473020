import { blue } from '@ant-design/colors';
import {
  LockOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined
} from '@ant-design/icons';
import { useBoolean, useDrop } from 'ahooks';
import {
  Badge,
  Button,
  Checkbox,
  List,
  Popover,
  Space,
  Typography
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  GetTopicsQuery,
  Topic as TopicType,
  TopicSort
} from '../../generated/graphql';
import { useChangeTopicStatus } from '../../hooks/useChangeTopicStatus';
// import { SubscriptionBell } from '../SubscriptionBell';
import { Topic } from '../Topic';
import styles from './TopicList.module.css';

interface TopicListProps {
  status: string;
  topics?: GetTopicsQuery['getTopics']['items'];
  onSelectTopics: (items: GetTopicsQuery['getTopics']['items']) => void;
  onUnSelectTopics: (items: GetTopicsQuery['getTopics']['items']) => void;
  selectedTopics: GetTopicsQuery['getTopics']['items'];
  locked: boolean;
}

export function TopicList(props: TopicListProps) {
  const [dragEntered, { setTrue: dragEnter, setFalse: dragLeave }] =
    useBoolean(false);
  const navigate = useNavigate();
  const dropRef = useRef<any>(null);
  const updateTopicsStatus = useChangeTopicStatus();
  const [sort, setSort] = useState<
    TopicSort.CreationDateAsc | TopicSort.CreationDateDesc
  >(TopicSort.CreationDateAsc);

  useDrop(dropRef, {
    onDragEnter: () => dragEnter(),
    onDragLeave: () => dragLeave(),
    onDom: (topics: GetTopicsQuery['getTopics']['items']) => {
      props.onUnSelectTopics(topics);
      dragLeave();
      updateTopicsStatus(topics, props.status);
    }
  });

  const topicIsSelected = (topicId: TopicType['id']) => {
    return props.selectedTopics.some((item) => item.id === topicId);
  };

  function onChange(event: CheckboxChangeEvent) {
    if (event.target.checked) {
      props.onSelectTopics(props.topics || []);
    } else {
      props.onUnSelectTopics(props.topics || []);
    }
  }

  const checkedAll =
    props.selectedTopics.filter((item) => item.status === props.status)
      .length === (props.topics || []).length &&
    (props.topics || []).length > 0;

  function toggleSort() {
    setSort(
      sort === TopicSort.CreationDateDesc
        ? TopicSort.CreationDateAsc
        : TopicSort.CreationDateDesc
    );
  }

  let dataSource = useMemo(
    () =>
      (props.topics || []).sort((topicA, topicB) => {
        let a = new Date(topicA.createdAt).getTime();
        let b = new Date(topicB.createdAt).getTime();
        return sort === TopicSort.CreationDateDesc ? a - b : b - a;
      }),
    [props.topics, sort]
  );

  return (
    <>
      <Badge.Ribbon
        text={(props.topics || []).length}
        className={styles.ribbon}
      />
      <Typography.Title level={5} className={styles.title} ellipsis>
        <Space size={'small'}>
          {props.locked ? (
            <>
              <div>{props.status}</div>
              <Popover content={'This state locked by Asana'}>
                <LockOutlined />
              </Popover>
            </>
          ) : (
            <>
              <Popover content={'Select all'}>
                <Checkbox
                  onChange={(event) => onChange(event)}
                  checked={checkedAll}
                />
              </Popover>
              <div>{props.status}</div>
            </>
          )}
        </Space>
      </Typography.Title>
      <Button type="link" onClick={toggleSort}>
        {sort === TopicSort.CreationDateAsc ? (
          <>
            From newest to oldest<> </>
            <SortAscendingOutlined />
          </>
        ) : (
          <>
            From oldest to newest<> </>
            <SortDescendingOutlined />
          </>
        )}
      </Button>
      <div
        className={
          (props.topics || []).length === 0 ? styles.dropPlace : undefined
        }
        ref={dropRef}
        style={{ backgroundColor: dragEntered ? blue[0] : '' }}
      >
        <List
          style={{
            overflow: 'auto',
            height: 'calc(100vh - 234px)'
          }}
          dataSource={dataSource}
          split={false}
          size="small"
          className={styles.list}
          renderItem={(item) => (
            <List.Item onClick={() => navigate(`${item.id}`)} key={item.id}>
              <Topic
                item={item}
                onSelect={() => {
                  props.onSelectTopics([item]);
                }}
                selected={topicIsSelected(item.id)}
                onUnselect={() => {
                  props.onUnSelectTopics([item]);
                }}
                locked={props.locked}
              />
            </List.Item>
          )}
        />
      </div>
    </>
  );
}
