import { CopyOutlined, EditOutlined } from '@ant-design/icons';
import { ApolloError } from '@apollo/client';
import { useBoolean, useDebounceFn, useSetState } from 'ahooks';
import {
  Button,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  message,
  Modal,
  notification,
  PageHeader,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
  Tabs,
  Typography
} from 'antd';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CategorySelector } from '../components/CategorySelector';
import { ChangesList } from '../components/ChangesList';
import { ClustersList } from '../components/ClustersList';
import { CommentsList } from '../components/CommentsList';
import { ContentEditableText } from '../components/ContentEditableText';
import { CourseLevel } from '../components/CourseLevel';
import { DeleteTopic } from '../components/DeleteTopic';
import { EditableLink } from '../components/EditableLink';
import { EditableText } from '../components/EditableText';
import { TopicStateLabel } from '../components/TopicStateLabel';
import { TopicStateSelector } from '../components/TopicStateSelector';
import {
  GetTopicDocument,
  GetTopicHistoryDocument,
  GetTopicQuery,
  Topic,
  TopicState,
  useChangeTopicCategoryMutation,
  useChangeTopicCourseSkillMutation,
  useChangeTopicCourseTypeMutation,
  useChangeTopicCreatorsLinksMutation,
  useChangeTopicDescriptionMutation,
  useChangeTopicHowDidYouComeToItMutation,
  useChangeTopicHowTosMutation,
  useChangeTopicImportantLinksMutation,
  useChangeTopicMarketingCampaignMutation,
  useChangeTopicRedditLinkMutation,
  useChangeTopicSeedKeywordsMutation,
  useChangeTopicStateMutation,
  useChangeTopicTitleMutation,
  useGetTopicLazyQuery
} from '../generated/graphql';

interface TopicPageProps {
  topicId?: Topic['id'];
  onDeleted?: () => void;
}

export function TopicPage(props: TopicPageProps) {
  const navigate = useNavigate();
  const params = useParams<string>();
  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const [updateTopicTitle] = useChangeTopicTitleMutation({
    refetchQueries: [
      {
        query: GetTopicHistoryDocument,
        variables: { id: props.topicId || params.id! }
      }
    ],
    async onCompleted() {
      await message.success('Topic updated');
    },
    onError(error: ApolloError) {
      console.error(error);
      notification.error({ message: 'Changes does not applied' });
    }
  });
  const [updateTopicCampaigns] = useChangeTopicMarketingCampaignMutation();
  const [updateTopicCategory] = useChangeTopicCategoryMutation();
  const [updateTopicCourseSkill] = useChangeTopicCourseSkillMutation();
  const [updateTopicCourseType] = useChangeTopicCourseTypeMutation();
  const [updateTopicCreatorsLinks] = useChangeTopicCreatorsLinksMutation();
  const [updateTopicDescription] = useChangeTopicDescriptionMutation();
  const [updateTopicHowDidYouComeUp] =
    useChangeTopicHowDidYouComeToItMutation();
  const [updateTopicHowTos] = useChangeTopicHowTosMutation();
  const [updateTopicImportantLinks] = useChangeTopicImportantLinksMutation();
  const [updateTopicRedditLink] = useChangeTopicRedditLinkMutation();
  const [updateTopicSeedKeywords] = useChangeTopicSeedKeywordsMutation();

  const [
    isShowReasonModal,
    { setTrue: showReasonModal, setFalse: hideReasonModal }
  ] = useBoolean(false);
  const changedKey = useRef<
    | 'title'
    | 'campaigns'
    | 'category'
    | 'courseSkill'
    | 'courseType'
    | 'creatorsLinks'
    | 'description'
    | 'howDidYouComeUp'
    | 'howTos'
    | 'importantLinks'
    | 'redditLink'
    | 'seedKeywords'
    | null
  >();
  const [changeTopicState, { loading: stateUpdating }] =
    useChangeTopicStateMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GetTopicDocument,
          variables: {
            id: props.topicId
          }
        }
      ]
    });

  const [newStateReason, setNewStateReasonDescription] = useState('');
  const [newState, setNewState] = useState<TopicState>(TopicState.Na);
  const [topicMeta, setTopicMeta] = useSetState<
    Exclude<GetTopicQuery['getTopic'], undefined>
  >({} as Exclude<GetTopicQuery['getTopic'], undefined>);
  const [fetchTopic, { data, loading: loadingTopic }] = useGetTopicLazyQuery();

  useEffect(() => {
    fetchTopic({
      variables: { id: props.topicId || params.id! },
      fetchPolicy: 'cache-and-network',
      onCompleted({ getTopic }) {
        getTopic && setTopicMeta({ ...getTopic });
      }
    })
      .then()
      .catch();
  }, [fetchTopic, params.id, props.topicId, setTopicMeta]);

  const { run: persistChanges } = useDebounceFn(
    async () => {
      const refetchQueries = [
        {
          query: GetTopicHistoryDocument,
          variables: { id: props.topicId || params.id! }
        }
      ];

      try {
        switch (changedKey.current) {
          case 'title':
            await updateTopicTitle({
              variables: {
                input: {
                  topicId: props.topicId || params.id!,
                  title: topicMeta.title || ''
                }
              },
              awaitRefetchQueries: true,
              refetchQueries
            });
            break;
          case 'description':
            await updateTopicDescription({
              variables: {
                input: {
                  topicId: props.topicId || params.id!,
                  description: topicMeta.description || ''
                }
              },
              awaitRefetchQueries: true,
              refetchQueries
            });
            break;
          case 'campaigns':
            await updateTopicCampaigns({
              variables: {
                input: {
                  topicId: props.topicId || params.id!,
                  campaigns: topicMeta.campaigns || []
                }
              },
              awaitRefetchQueries: true,
              refetchQueries
            });
            break;
          case 'category':
            await updateTopicCategory({
              variables: {
                input: {
                  topicId: props.topicId || params.id!,
                  category: topicMeta.category || []
                }
              },
              awaitRefetchQueries: true,
              refetchQueries
            });
            break;
          case 'courseSkill':
            await updateTopicCourseSkill({
              variables: {
                input: {
                  topicId: props.topicId || params.id!,
                  courseSkill: topicMeta.courseSkill
                }
              },
              awaitRefetchQueries: true,
              refetchQueries
            });
            break;
          case 'courseType':
            await updateTopicCourseType({
              variables: {
                input: {
                  topicId: props.topicId || params.id!,
                  courseType: topicMeta.courseType
                }
              },
              awaitRefetchQueries: true,
              refetchQueries
            });
            break;
          case 'creatorsLinks':
            await updateTopicCreatorsLinks({
              variables: {
                input: {
                  topicId: props.topicId || params.id!,
                  creatorsLinks: topicMeta.creatorsLinks || []
                }
              },
              awaitRefetchQueries: true,
              refetchQueries
            });
            break;
          case 'howDidYouComeUp':
            await updateTopicHowDidYouComeUp({
              variables: {
                input: {
                  topicId: props.topicId || params.id!,
                  howDidYouComeUp: topicMeta.howDidYouComeUp || ''
                }
              },
              awaitRefetchQueries: true,
              refetchQueries
            });
            break;
          case 'howTos':
            await updateTopicHowTos({
              variables: {
                input: {
                  topicId: props.topicId || params.id!,
                  howTos: topicMeta.howTos || ''
                }
              },
              awaitRefetchQueries: true,
              refetchQueries
            });
            break;
          case 'importantLinks':
            await updateTopicImportantLinks({
              variables: {
                input: {
                  topicId: props.topicId || params.id!,
                  importantLinks: topicMeta.importantLinks || []
                }
              },
              awaitRefetchQueries: true,
              refetchQueries
            });
            break;
          case 'redditLink':
            await updateTopicRedditLink({
              variables: {
                input: {
                  topicId: props.topicId || params.id!,
                  redditLink: topicMeta.redditLink || ''
                }
              },
              awaitRefetchQueries: true,
              refetchQueries
            });
            break;
          case 'seedKeywords':
            await updateTopicSeedKeywords({
              variables: {
                input: {
                  topicId: props.topicId || params.id!,
                  seedKeywords: topicMeta.seedKeywords || []
                }
              },
              awaitRefetchQueries: true,
              refetchQueries
            });
            break;
        }
        message.success('Changes saved');
      } catch (e) {
        message.error('Error saving changes');
      }
    },
    {
      wait: 700
    }
  );

  function onChangeTopicMeta(
    key:
      | 'title'
      | 'campaigns'
      | 'category'
      | 'courseSkill'
      | 'courseType'
      | 'creatorsLinks'
      | 'description'
      | 'howDidYouComeUp'
      | 'howTos'
      | 'importantLinks'
      | 'redditLink'
      | 'seedKeywords'
  ) {
    return (value: any) => {
      changedKey.current = key;
      switch (key) {
        case 'seedKeywords':
          setTopicMeta((prevState) => ({
            ...prevState,
            seedKeywords: value as Array<string>
          }));
          break;
        case 'creatorsLinks':
          setTopicMeta((prevState) => ({
            ...prevState,
            creatorsLinks: (value as string).split('\n')
          }));
          break;
        case 'importantLinks':
          setTopicMeta((prevState) => ({
            ...prevState,
            importantLinks: (value as string).split('\n')
          }));
          break;
        case 'campaigns':
          setTopicMeta((prevState) => ({
            ...prevState,
            campaigns: (value as string).split('\n')
          }));
          break;
        default:
          setTopicMeta((prevState) => ({
            ...prevState,
            [key]: value
          }));
          break;
      }
      persistChanges();
    };
  }

  async function copySeedKeywordsToClipboard() {
    if (data?.getTopic?.seedKeywords) {
      await navigator.clipboard.writeText(data.getTopic.seedKeywords.join(','));
      message.success('Copied to clipboard');
    }
  }

  function handleTopicStateChanging(newState: TopicState) {
    setNewState(newState);
    showReasonModal();
  }

  async function handleChangeTopicStateModal(ok: boolean) {
    hideReasonModal();
    if (ok) {
      await changeTopicState({
        variables: {
          topicId: data?.getTopic?.id!,
          newState: newState,
          reason: newStateReason
        },
        refetchQueries: [
          { query: GetTopicHistoryDocument, variables: { id: props.topicId } },
          { query: GetTopicDocument, variables: { id: props.topicId } }
        ]
      });
      message.success('Updated');
    }
  }

  return (
    <>
      <PageHeader
        title={
          <Input
            value={topicMeta.title}
            bordered={false}
            style={{
              fontSize: '30px',
              fontWeight: 600
            }}
            onChange={(event) => {
              onChangeTopicMeta('title')(event.target.value);
            }}
            onPressEnter={(event) => event.currentTarget.blur()}
          />
        }
        {...(!props.topicId ? { onBack: goBack } : undefined)}
      >
        <Tabs defaultValue="info-tab">
          <Tabs.TabPane key="info-tab" tab="Info">
            <Spin spinning={loadingTopic} delay={500}>
              {data?.getTopic ? (
                <Descriptions
                  labelStyle={{
                    fontSize: '15px',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Descriptions.Item
                    contentStyle={{ fontSize: '20px' }}
                    label={'id'}
                  >
                    {
                      <EditableText copyable={{ text: data?.getTopic?.id }}>
                        {data?.getTopic?.id}
                      </EditableText>
                    }
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="created at"
                    contentStyle={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    {DateTime.fromISO(
                      data?.getTopic?.createdAt
                    ).toLocaleString()}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="updated at"
                    contentStyle={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    {data?.getTopic?.updatedAt
                      ? DateTime.fromISO(
                          data?.getTopic?.updatedAt
                        ).toLocaleString()
                      : '--'}
                  </Descriptions.Item>
                </Descriptions>
              ) : (
                <Skeleton />
              )}
              <Divider dashed />
              <Descriptions
                bordered
                labelStyle={{ width: 135 }}
                size="small"
                column={{
                  xxl: 1,
                  xl: 1,
                  lg: 1,
                  md: 1,
                  sm: 1,
                  xs: 1
                }}
              >
                <Descriptions.Item label="Creator">
                  {data?.getTopic?.createdBy?.fullName}
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                  <TopicStateSelector
                    loading={loadingTopic || stateUpdating}
                    value={data?.getTopic?.state || TopicState.NotStarted}
                    onChange={handleTopicStateChanging}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Step name">
                  <Descriptions.Item>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <div>{data?.getTopic?.status || '--'}</div>
                      {data?.getTopic?.statusChangedAt && (
                        <Space
                          style={{
                            color: 'grey',
                            alignItems: 'right'
                          }}
                        >
                          <div>last changed at:</div>
                          <div>
                            {DateTime.fromISO(
                              data.getTopic.statusChangedAt
                            ).toRelative()}
                          </div>
                        </Space>
                      )}
                    </div>
                  </Descriptions.Item>
                </Descriptions.Item>

                <Descriptions.Item
                  contentStyle={{ whiteSpace: 'break-spaces' }}
                  label="Description"
                >
                  <ContentEditableText
                    defaultValue={data?.getTopic?.description || ''}
                    onChange={onChangeTopicMeta('description')}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Seed Keywords">
                  <Row>
                    <Col flex={3}>
                      <Select
                        open={false}
                        mode="tags"
                        placeholder={'Tags'}
                        style={{
                          width: '100%',
                          maxWidth: '600px'
                        }}
                        value={topicMeta?.seedKeywords}
                        tokenSeparators={[',', '\n']}
                        onChange={onChangeTopicMeta('seedKeywords')}
                      >
                        {topicMeta?.seedKeywords?.map((keyword) => (
                          <Select.Option key={keyword}>{keyword}</Select.Option>
                        ))}
                      </Select>
                    </Col>
                    <Col flex={2}>
                      <Button onClick={copySeedKeywordsToClipboard} type="text">
                        <CopyOutlined />
                      </Button>
                    </Col>
                  </Row>
                </Descriptions.Item>
                <Descriptions.Item
                  label="Reddit link"
                  contentStyle={{ whiteSpace: 'break-spaces' }}
                >
                  <EditableLink
                    href={topicMeta?.redditLink}
                    target="_blank"
                    editable={{
                      onChange: onChangeTopicMeta('redditLink'),
                      icon: <EditOutlined />
                    }}
                  >
                    {topicMeta?.redditLink}
                  </EditableLink>
                </Descriptions.Item>
                <Descriptions.Item
                  label="How did you come up with the topic?"
                  contentStyle={{ whiteSpace: 'break-spaces' }}
                >
                  <ContentEditableText
                    defaultValue={topicMeta?.howDidYouComeUp || ''}
                    onChange={onChangeTopicMeta('howDidYouComeUp')}
                  />
                </Descriptions.Item>
                <Descriptions.Item
                  label="Course Skill"
                  contentStyle={{ whiteSpace: 'break-spaces' }}
                >
                  <ContentEditableText
                    defaultValue={topicMeta?.courseSkill || ''}
                    onChange={onChangeTopicMeta('courseSkill')}
                  />
                </Descriptions.Item>
                <Descriptions.Item
                  label="Important links"
                  contentStyle={{ whiteSpace: 'break-spaces' }}
                >
                  <ContentEditableText
                    defaultValue={topicMeta?.importantLinks?.join('\n') || ''}
                    onChange={onChangeTopicMeta('importantLinks')}
                  />
                </Descriptions.Item>
                <Descriptions.Item
                  label="How to's"
                  contentStyle={{ whiteSpace: 'break-spaces' }}
                >
                  <ContentEditableText
                    defaultValue={topicMeta.howTos || ''}
                    onChange={onChangeTopicMeta('howTos')}
                  />
                </Descriptions.Item>
                <Descriptions.Item
                  label="Potential Creators"
                  contentStyle={{ whiteSpace: 'break-spaces' }}
                >
                  <ContentEditableText
                    defaultValue={topicMeta.creatorsLinks?.join('\n') || ''}
                    onChange={onChangeTopicMeta('creatorsLinks')}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Course type">
                  <CourseLevel
                    style={{ minWidth: 200 }}
                    onChange={onChangeTopicMeta('courseType')}
                    value={
                      topicMeta.courseType as Array<
                        'Beginners' | 'Intermediate' | 'Advanced'
                      >
                    }
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Category">
                  <CategorySelector
                    value={topicMeta?.category}
                    onChange={onChangeTopicMeta('category')}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Marketing Campaigns">
                  <ContentEditableText
                    defaultValue={data?.getTopic?.campaigns?.join('\n') || ''}
                    onChange={onChangeTopicMeta('campaigns')}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Asana link">
                  <Typography.Link>
                    {data?.getTopic?.asanaTaskLink}
                  </Typography.Link>
                </Descriptions.Item>
              </Descriptions>
            </Spin>
          </Tabs.TabPane>
          <Tabs.TabPane key="cluster-tab" tab="Cluster">
            <ClustersList topicId={props.topicId || params.id!} />
          </Tabs.TabPane>
          <Tabs.TabPane key="comments-tab" tab="Comments">
            <CommentsList topicId={props.topicId || params.id!} />
          </Tabs.TabPane>
          <Tabs.TabPane key="changes-tab" tab="Changes">
            <ChangesList topicId={props.topicId || params.id!} />
          </Tabs.TabPane>
          <Tabs.TabPane key="other-tab" tab="Other">
            <DeleteTopic
              topicId={props.topicId || params.id!}
              onDeleted={props.onDeleted}
            />
          </Tabs.TabPane>
        </Tabs>
      </PageHeader>
      <Modal
        open={isShowReasonModal}
        onOk={() => handleChangeTopicStateModal(true)}
        title="Change topic's state description"
        onCancel={() => handleChangeTopicStateModal(false)}
        destroyOnClose
      >
        <Form>
          <Form.Item>
            NewState: <TopicStateLabel value={newState} />
          </Form.Item>
          <Form.Item>
            <Input.TextArea
              onChange={(e) => setNewStateReasonDescription(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
