import { Button, Card, Dropdown, Menu, Typography } from "antd"
import { GetTopicsQuery } from "../../generated/graphql"
import { useDrag } from "ahooks";
import { useRef } from "react";
import { useChangeTopicStatus } from "../../hooks/useChangeTopicStatus";

interface SelectedTopicsProps {
    topics: GetTopicsQuery['getTopics']['items'];
    visible: boolean;
    states?: string[];
    onDrop: () => void
}

export function SelectedTopics({ topics, visible, states, onDrop }: SelectedTopicsProps) {
    const topicRef = useRef<any>(null);
    const updateTopicStatus = useChangeTopicStatus();

    useDrag(topics, topicRef);

    const menu = (
        <Menu
            items={states?.map((status) => {
                return {
                    label: <Typography.Text>{status}</Typography.Text>,
                    key: status,
                    onClick: () => { updateTopicStatus(topics, status); onDrop() }
                }
            })}>
        </Menu>
    )

    return (
        <div ref={topicRef} style={{
            visibility: visible ? 'visible' : 'hidden',
            width: '230px',
            height: visible ? '100%' : '0px',
            cursor: 'pointer'
        }} >
            <Card
                style={{
                    width: '260px',
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
                bodyStyle={{
                    padding: '10px 10px 10px 10px',
                    width: '100%',
                    display: "flex",
                    justifyContent: 'space-between',
                    alignContent: 'center',
                }}>
                <div>
                    <Typography.Text
                        style={{
                            fontSize: '23px',
                            fontWeight: '400',
                            textAlign: 'center',
                        }}>
                        {topics.length}
                    </Typography.Text>
                    <Typography.Text style={{ margin: '10px' }}> selected topics</Typography.Text>
                </div>
                <Dropdown placement="bottom" overlay={menu} trigger={['click']} >
                    <Button style={{ marginTop: '5px' }}>Move to</Button>
                </Dropdown>
            </Card>
        </div>
    )
}