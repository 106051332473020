import * as Yup from 'yup';

export const ValidationSchema = Yup.array()
  .of(
    Yup.object().shape({
      query:    Yup.string()
                  .min(2, 'To short')
                  .matches(/^[a-z0-9\s]+/gi, 'Cannot use special characters'),
      scrapers: Yup.array()
                  .of(Yup.string())
                  .min(1, 'At least one scraper needed')
    })
  )
  .min(1)
  .required();
