import { FileOutlined } from '@ant-design/icons';
import { ID } from '@bynyk/domain';
import { useBoolean } from 'ahooks';
import { Button, List, Modal, notification, Space } from 'antd';
import { useState } from 'react';
import {
  GetTopicDocument,
  Scalars,
  useCreateClusterMutation,
  useGetTopicClustersQuery
} from '../../generated/graphql';
import { Cluster } from '../Cluster';
import { ClusterForm, OnSubmitParams } from '../ClusterForm';

interface ClustersListProps {
  topicId: ID;
}

function showError(message?: string) {
  notification.error({
    message: 'Creating keywords cluster',
    description: message || 'Somethings goes wrong. Retry it again.'
  });
}

export function ClustersList(props: ClustersListProps) {
  const [createCluster, { loading: saving }] = useCreateClusterMutation();
  const [editClusterId, setEditCluster] = useState<
    Scalars['ID']['input'] | null
  >(null);
  const [isModalVisible, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean();
  const { data, loading } = useGetTopicClustersQuery({
    variables: { topicId: props.topicId }
  });

  const clusterToEdit =
    data?.getTopic?.clusters
      .find((item) => item.id === editClusterId)
      ?.keywords.map((item) => ({
        query: item.title,
        scrapers: item.scrapers
      })) || [];

  function createNewCluster() {
    setEditCluster(null);
    showModal();
  }
  function handleSubmit(value: OnSubmitParams) {
    createCluster({
      variables: {
        input: {
          topic: props.topicId,
          keywords: value
        }
      },
      refetchQueries: [GetTopicDocument]
    })
      .then(() => {
        hideModal();
      })
      .catch((e) => {
        showError(e.message);
      });
  }

  return (
    <>
      <Space style={{ margin: '1em 0' }}>
        <Button
          key="create cluster btn"
          type="primary"
          onClick={createNewCluster}
        >
          Create cluster
        </Button>
        {data?.getTopic?.clusterBuilderResult && (
          <a
            download={`topic_${props.topicId}.json`}
            type="application/json"
            href={`data:application/json;base64,${data?.getTopic?.clusterBuilderResult}`}
          >
            <Button>
              <FileOutlined />
              Download Cluster JSON
            </Button>
          </a>
        )}
      </Space>
      <List
        dataSource={data?.getTopic?.clusters}
        loading={loading}
        renderItem={(cluster, index) => (
          <List.Item>
            <Cluster
              key={props.topicId}
              topicId={props.topicId}
              value={cluster}
              version={(data?.getTopic?.clusters.length || 0) - index}
            />
          </List.Item>
        )}
      />
      <Modal
        open={isModalVisible}
        title="Create cluster"
        onCancel={hideModal}
        footer={null}
        width="50%"
        destroyOnClose
      >
        <ClusterForm
          initialValues={clusterToEdit}
          onSubmit={handleSubmit}
          disabled={saving}
        />
      </Modal>
    </>
  );
}
