import {
  ApolloClient,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache
} from '@apollo/client';
import { ErrorLink } from '@apollo/client/link/error';
import { useCreation } from 'ahooks';
import { Outlet, Route, Routes } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import './App.css';
import { ConfirmProfileInfoDialog } from './components/ConfirmProfileInfoDialog';
import { FastTopicPreview } from './FastTopicPreview';
import { useLogout } from './hooks/useLogout';
import {
  Dashboard,
  MainPageLayout,
  SettingsPage,
  StagePage,
  SupermetricsPage,
  TopicsPage
} from './pages';
import { SupermetricsTask } from './pages/SupermetricsTask';
import { authToken, preReleaseMode } from './state';

function App() {
  const isPreProduction = useRecoilValue(preReleaseMode);
  const logout = useLogout();
  if (isPreProduction) {
    const meta = document.querySelector('#theme-color');
    meta?.setAttribute('content', '#D03E36');
  }
  const [token] = useRecoilState(authToken);

  const client = useCreation(
    () =>
      new ApolloClient({
        link: from([
          new ErrorLink((error) => {
            // @ts-ignore
            if (error.networkError?.statusCode === 401) {
              localStorage.clear();
              client.clearStore().then(logout);
            }
          }),
          new HttpLink({
            uri: process.env.REACT_APP_API_URL,
            headers: {
              Authentication: `Bearer ${token}`
            }
          })
        ]),
        cache: new InMemoryCache({
          typePolicies: {
            Setting: {
              keyFields: ['key']
            },
            KeywordStatistic: {
              keyFields: ['scraper', 'keyword', ['id']]
            },
            TopicCategory: {
              keyFields: ['__typename', 'value']
            }
          }
        })
      }),
    [token]
  );

  return (
    <ApolloProvider client={client}>
      <ConfirmProfileInfoDialog />
      <Routes>
        <Route path="/" element={<MainPageLayout />}>
          <Route path="/" element={<Dashboard />}>
            <Route path="details/:topicId" element={<FastTopicPreview />} />
          </Route>
          <Route path="/settings" element={<SettingsPage />}>
            <Route path="users/:userId" />
          </Route>
          <Route path="/stages">
            <Route path=":stage" element={<StagePage />}>
              <Route path=":topicId" />
            </Route>
          </Route>
          <Route path="/topics" element={<TopicsPage />}>
            <Route path=":topicId" element={<Outlet />} />
          </Route>
          <Route path="/supermetrics" element={<SupermetricsPage />}>
            <Route
              path="tasks/:taskId/:dialogTab"
              element={<SupermetricsTask />}
            />
            <Route path="tasks/:taskId/" element={<SupermetricsTask />} />
          </Route>
        </Route>
      </Routes>
    </ApolloProvider>
  );
}

export default App;
