import { ID } from '@bynyk/domain';
import { List, message, notification, Spin } from 'antd';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  GetTopicCommentsDocument,
  useAddCommentMutation,
  useGetTopicCommentsLazyQuery
} from '../../generated/graphql';
import { Comment as TopicComment } from '../Comment';
import { Editor } from '../Editor';

interface CommentsListProps {
  topicId: ID;
}

export function CommentsList(props: CommentsListProps) {
  const [comment, setComment] = useState('');
  const [fetchComments, { data: comments, loading: loadingComments }] =
    useGetTopicCommentsLazyQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        id: props.topicId
      }
    });
  const [addComment, { loading: submitting }] = useAddCommentMutation({
    onCompleted: () => {
      setComment('');
      message.success('Updated');
    },
    onError: (error) => {
      notification.error({ message: error.message });
    },
    refetchQueries: [
      {
        query: GetTopicCommentsDocument,
        variables: {
          id: props.topicId
        }
      }
    ]
  });

  useEffect(() => {
    fetchComments({
      variables: { id: props.topicId }
    })
      .then()
      .catch();
  }, [fetchComments, props.topicId]);

  async function onAddComment() {
    await addComment({
      variables: {
        input: {
          text: comment,
          topicId: props.topicId
        }
      }
    });
  }

  return (
    <Spin spinning={loadingComments} delay={500} style={{ display: 'flex' }}>
      <List
        itemLayout="horizontal"
        style={{ overflowY: 'scroll', height: 'calc(100vh - 450px)' }}
        dataSource={comments?.getTopic?.comments}
        renderItem={(item) => (
          <li>
            <TopicComment
              markdownComponent={(text) => <ReactMarkdown children={text} />}
              comment={item}
              onUpdated={fetchComments}
              onDeleted={fetchComments}
              key={item.id}
            />
          </li>
        )}
      />
      <div>
        <Editor
          onSubmit={onAddComment}
          submitting={submitting}
          value={comment}
          onChange={(event) => setComment(event.target.value)}
        />
      </div>
    </Spin>
  );
}
