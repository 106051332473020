import { UserAddOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { useBoolean } from 'ahooks';
import { Button, Card, Col, Drawer, List, Row, Switch, Typography } from 'antd';
import { useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AddUserModal } from '../components/AddUserModal';
import { Loader } from '../components/Loader';
import { useGetUsersListQuery, useMeQuery } from '../generated/graphql';
import { UserPage } from './UserPage';

export function UsersPage() {
  const navigation = useNavigate();
  const [showDeletedUsers, setDeletedUsers] = useState(true);
  const { data: me } = useMeQuery();
  const [
    visibleAddNewUserDialog,
    {
      setTrue: openAddNewUserDialog,
      setFalse: hideAddNewUserDialog
    }
  ] = useBoolean(false);
  const { userId } = useParams();
  const { data, loading } = useGetUsersListQuery();

  const filteredUsers = useMemo(() => {
    if (showDeletedUsers) {
      return data?.users;
    } else {
      return data?.users.filter((user) => {
        return null === user.deletedAt;
      });
    }
  }, [
    data?.users,
    showDeletedUsers
  ]);

  function onCloseDrawer() {
    navigation('.');
  }

  return (
    <>
      <Drawer open={!!userId} onClose={onCloseDrawer}>
        {userId && <UserPage userId={userId} />}
      </Drawer>
      <Typography.Title level={3}>
        <UsergroupAddOutlined /> Users
      </Typography.Title>
      <AddUserModal
        visible={visibleAddNewUserDialog}
        onCancel={hideAddNewUserDialog}
        onCreated={hideAddNewUserDialog}
      />
      <Row justify={'space-between'} style={{ margin: '20px 0' }}>
        <Col>
          <Button type={'primary'} onClick={openAddNewUserDialog}>
            <UserAddOutlined />
            {` Add user`}
          </Button>
        </Col>
        <Col>
          <Typography.Text style={{ float: 'right' }}>
            Show deleted{' '}
            <Switch
              defaultChecked={showDeletedUsers}
              onChange={setDeletedUsers}
            />
          </Typography.Text>
        </Col>
      </Row>
      {loading && !data ?
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Loader />
        </div> :
        <List
          grid={{
            gutter: 16,
            column: 4
          }}
          dataSource={filteredUsers || []}
          renderItem={(user) => {
            return (
              <>
                <Link to={`users/${user.id}`}>
                  <List.Item>
                    <Card>
                      <Typography.Text
                        type={!!user.deletedAt ? 'secondary' : undefined}
                        ellipsis
                      >
                        {user.fullName || user.email}
                      </Typography.Text>
                      <Typography.Text type={'secondary'}>
                        {me?.me?.id === user.id && ` (me)`}
                      </Typography.Text>
                    </Card>
                  </List.Item>
                </Link>
              </>
            );
          }}
        />}

    </>
  );
}
