import { SettingOutlined } from '@ant-design/icons';
import { Divider, PageHeader, Space, Typography } from 'antd';
import React from 'react';
import { StatusesPage } from './StatusesPage';
import { UsersPage } from './UsersPage';

export function SettingsPage() {
  return (
    <PageHeader
      title={
        <Typography.Title level={2}>
          <Space>
            <SettingOutlined /> Settings
          </Space>
        </Typography.Title>
      }
    >
      <UsersPage />
      <Divider />
      <StatusesPage />
    </PageHeader>
  );
}
