import { BarChartOutlined, DeleteFilled } from '@ant-design/icons';
import { useBoolean } from 'ahooks';
import {
  Button,
  Card,
  Col,
  Divider,
  List,
  message,
  PageHeader,
  Popconfirm,
  Row,
  Space,
  Table,
  Tooltip,
  Typography
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { SyntheticEvent, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { CreatedAt } from '../components/CreatedAt';
import { NewTopicForm } from '../components/NewTopicForm';
import { TotalTopics } from '../components/TotalTopics';
import { useDimCoursesQuery } from '../generated/graphql';
import { useDraftTopics } from '../hooks/useDraftTopics';
import '../index.css';

export function Dashboard() {
  const [
    newTopicDialogVisible,
    { setTrue: showNewTopicDialog, setFalse: hideNewTopicDialog }
  ] = useBoolean(false);
  const [topics, { delete: deleteDraftTopic, add }] = useDraftTopics();
  const [activeDraftIndex, setActiveDraftIndex] = useState<number>(0);
  const { data, loading: loadingDimCourses } = useDimCoursesQuery();

  function handleDelete(index: number) {
    return async (event: SyntheticEvent) => {
      event.stopPropagation();
      await deleteDraftTopic(index);
      handleClose();
      message.success({
        content: 'Draft deleted',
        key: 'Draft deleted'
      });
    };
  }

  function continueEditDraft(index: number) {
    return () => {
      setActiveDraftIndex(index);
      showNewTopicDialog();
    };
  }

  function handleClose() {
    hideNewTopicDialog();
  }

  async function newDraft() {
    await add({});
    setActiveDraftIndex(0);
    showNewTopicDialog();
  }

  const dataSource = data?.getMongoDbCollectionData?.rows?.filter(
    (row) => row.long_name !== undefined
  );

  return (
    <PageHeader
      title={
        <Typography.Title level={2}>
          <Space>
            <BarChartOutlined /> General Statistics
          </Space>
        </Typography.Title>
      }
    >
      <Row>
        <Col>
          <Space>
            <Card>
              <TotalTopics />
            </Card>
            <Card>
              <CreatedAt />
            </Card>
          </Space>
        </Col>
      </Row>
      <Divider />
      <Typography.Title level={3}>Main courses</Typography.Title>
      <Table
        loading={loadingDimCourses}
        dataSource={dataSource}
        rowKey={'_id'}
        columns={[
          {
            dataIndex: 'tms_course_id',
            key: 'tms_course_id',
            title: 'tms course id',
            render: (item) => {
              return <Link to={`details/${item}`}>{item}</Link>;
            },
            sorter: (a, b) => a.tms_course_id - b.tms_course_id
          },
          {
            dataIndex: 'teachable_course_id',
            title: 'teachable course id',
            key: 'teachable_course_id',
            sorter: (a, b) => a.teachable_course_id - b.teachable_course_id
          },
          {
            dataIndex: 'short_name',
            title: 'short name - Teachable, Stripe',
            key: 'short_name',
            sorter: (a, b) => a.short_name.localeCompare(b.short_name)
          },
          {
            dataIndex: 'long_name',
            title: 'long name - Gifting',
            key: 'long_name',
            sorter: true
          },
          {
            dataIndex: 'tms_name',
            title: 'tms name - Mailchimp, Asana, Dropbox',
            key: 'tms_name',
            sorter: (a, b) => a.tms_name.localeCompare(b.tms_name)
          },
          {
            dataIndex: 'h1_descriptive_name',
            title: 'h1 descriptive name - LP',
            key: 'h1_descriptive_name',
            sorter: (a, b) =>
              (a.h1_descriptive_name || 'a').localeCompare(
                b.h1_descriptive_name || 'a'
              )
          }
        ]}
      />
      <Divider />
      <Typography.Title level={3}>My draft topics</Typography.Title>
      <NewTopicForm
        draftIndex={activeDraftIndex}
        visible={newTopicDialogVisible}
        values={
          activeDraftIndex || activeDraftIndex === 0
            ? topics.at(activeDraftIndex)
            : {}
        }
        onClose={hideNewTopicDialog}
      />

      <List
        style={{}}
        grid={{
          gutter: 16,
          column: 4,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 3,
          xl: 4,
          xxl: 5
        }}
        dataSource={topics}
        header={
          <Button
            key="new-topic-dialog-button"
            onClick={newDraft}
            type="primary"
          >
            New topic
          </Button>
        }
        renderItem={(item, index) => (
          <List.Item key={index}>
            <Card
              hoverable
              onClick={continueEditDraft(index)}
              className="card"
              style={{ display: 'flex', justifyContent: 'space-between' }}
              bodyStyle={{ width: '100%' }}
            >
              <div
                style={{
                  margin: '0 auto',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Card.Meta
                  style={{ minWidth: '80%', display: 'inline-block' }}
                  title={
                    <Space>
                      <Title level={5}>
                        {item?.title?.length! > 20
                          ? `${item?.title?.slice(0, 20)}...`
                          : item.title || 'noname'}
                      </Title>
                    </Space>
                  }
                  description={item.description}
                />
                <Popconfirm
                  style={{ height: 14 }}
                  key="Are you sure want to delete draft topic?"
                  title="Are you sure want to delete draft topic?"
                  okText="Delete"
                  placement="right"
                  onCancel={(event) => event?.stopPropagation()}
                  onConfirm={(event) =>
                    handleDelete(index)(event as unknown as SyntheticEvent)
                  }
                >
                  <DeleteFilled
                    className="draftIcon"
                    onClick={(event) => event.stopPropagation()}
                  />
                </Popconfirm>
              </div>
            </Card>
          </List.Item>
        )}
      />
      <Outlet />
    </PageHeader>
  );
}
