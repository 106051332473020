import { useRecoilValue } from "recoil";
import {
  GetTopicsDocument,
  GetTopicsQuery,
  Topic,
  useChangeTopicStatusMutation
} from '../generated/graphql';
import { topicFiltersForQuery } from '../state';

export function useChangeTopicStatus() {
  const filtersForQuery = useRecoilValue(topicFiltersForQuery);
  const [changeStatus, { client }] = useChangeTopicStatusMutation({
    refetchQueries: [
      {
        query: GetTopicsDocument,
        variables: {
          filter: filtersForQuery
        }
      }
    ]
  });

  return (
    topics: GetTopicsQuery['getTopics']['items'],
    status: Topic['status']
  ) => {
    topics.forEach(async (topic) => {
      if (topic.status !== status) {
        // Optimistic modification local cache,
        // because fetching new topics with updated statuses maybe slow.
        client.cache.modify({
          id: client.cache.identify(topic),
          fields: {
            status: () => status
          }
        });
        await changeStatus({
          variables: {
            input: {
              newStatus: status,
              topicId: topic.id!
            },
            filter: {}
          }
        });
      }
    });
  };
}