import {Checkbox, Form, Input, message, Modal} from 'antd';
import {useAddUserMutation} from '../../generated/graphql';

interface AddUserModalProps {
  visible: boolean;
  onCancel: () => void;
  onCreated: (values: { email: string; sendMagicLink?: boolean }) => void;
}

export function AddUserModal(props: AddUserModalProps) {
  const [form] = Form.useForm();
  const [addUser] = useAddUserMutation();

  async function onOk() {
    try {
      const values: {
        email: string;
        sendMagicLink: boolean;
      } = await form.validateFields();
      form.resetFields();
      await addUser({variables: {input: values}});
      message.success('User added');
      props.onCreated(values);
    } catch (e) {
      message.error('Sorry, try again');
    }
  }

  function onCancel() {
    form.resetFields();
    props.onCancel();
  }

  return (
    <Modal
      open={props.visible}
      destroyOnClose
      title={'Add new user'}
      okText={'Add'}
      onCancel={onCancel}
      onOk={onOk}
    >
      <Form form={form}>
        <Form.Item
          label={'Email'}
          name="email"
          rules={[
            {
              required: true,
              type:     'email'
            }
          ]}
        >
          <Input type="email" placeholder="user@example.com"/>
        </Form.Item>
        <Form.Item
          name="sendMagicLink"
          valuePropName="checked"
          wrapperCol={{offset: 3}}
        >
          <Checkbox value={true}>Send MagicLink</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
}
