import { DeleteOutlined, EditOutlined, FlagOutlined } from '@ant-design/icons';
import { useDebounceFn, useDynamicList } from 'ahooks';
import {
  Button,
  Card,
  Form,
  Input,
  message,
  Popconfirm,
  Space,
  Typography
} from 'antd';
import { useEffect, useState } from 'react';
import {
  GetStagesDocument,
  Stage,
  useGetStagesQuery,
  useUpdateTopicStagesMutation
} from '../generated/graphql';

export function StatusesPage() {
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const { data } = useGetStagesQuery({});
  const [updateTopicStates] = useUpdateTopicStagesMutation({
    refetchQueries: [
      {
        query: GetStagesDocument
      }
    ]
  });
  const { list, resetList, push, getKey, remove, replace } =
    useDynamicList<Stage>([]);
  const [form] = Form.useForm();

  useEffect(() => {
    resetList(
      data?.stages.map(({ title, states, lockedStates }) => ({
        title,
        states,
        lockedStates
      })) || []
    );
  }, [data, resetList]);

  const { run } = useDebounceFn(() => {
    updateTopicStates({
      variables: {
        input: {
          states: list
        }
      }
    })
      .then(() => message.success('Updated'))
      .catch(console.warn);
  });

  function onSubmit(value: {
    title: string;
    states: string;
    lockedStates: string;
  }) {
    push({
      title: value.title,
      states: value.states.split('\n').map((s) => s.trim()),
      lockedStates: value.lockedStates.split('\n').map((s) => s.trim())
    });
    run();
    form.resetFields();
  }

  function handleDelete(index: number) {
    return () => {
      remove(index);
      run();
    };
  }

  function handleEdit(index: number) {
    return () => setEditIndex(index);
  }

  function onUpdate(index: number) {
    return (value: { title: string; states: string; lockedStates: string }) => {
      replace(index, {
        ...value,
        states: value.states.split('\n').map((s) => s.trim()),
        lockedStates: value.lockedStates.split('\n').map((s) => s.trim())
      });
      setEditIndex(null);
      run();
    };
  }

  return (
    <>
      <Typography.Title level={3}>
        <FlagOutlined /> Topic steps
      </Typography.Title>
      <Space direction="vertical" style={{ width: '100%' }}>
        {list.map((stage, index) => {
          if (editIndex === index) {
            return (
              <Card key={getKey(index)}>
                <Form
                  onFinish={onUpdate(index)}
                  initialValues={{
                    title: stage.title,
                    states: stage.states.join('\n'),
                    lockedStates: stage.lockedStates.join('\n')
                  }}
                >
                  <Form.Item
                    label="Stage name"
                    key="stage-name"
                    name="title"
                    labelCol={{ xs: 3 }}
                    rules={[
                      {
                        required: true,
                        message: 'Stage name is required'
                      }
                    ]}
                  >
                    <Input placeholder="Stage name" />
                  </Form.Item>
                  <Form.Item
                    label="States"
                    key="stages"
                    name="states"
                    labelCol={{ xs: 3 }}
                    rules={[
                      {
                        required: true,
                        message: 'List of stages is required'
                      }
                    ]}
                  >
                    <Input.TextArea
                      rows={stage.states.length}
                      placeholder={`States in stage`}
                      defaultValue={stage.states.join('\n')}
                    />
                  </Form.Item>
                  <Form.Item
                    key="lockedStates"
                    label="Asana locked states"
                    name="lockedStates"
                    labelCol={{ xs: 3 }}
                  >
                    <Input.TextArea
                      rows={3}
                      placeholder="States controlled by Asana"
                    ></Input.TextArea>
                  </Form.Item>
                  <Form.Item key="submit" wrapperCol={{ offset: 3 }}>
                    <Space>
                      <Button type="primary" htmlType="submit">
                        Save
                      </Button>
                      <Button onClick={() => setEditIndex(null)}>Cancel</Button>
                    </Space>
                  </Form.Item>
                </Form>
              </Card>
            );
          } else
            return (
              <Card
                type="inner"
                key={getKey(index)}
                title={
                  <Typography.Title level={3}>{stage.title}</Typography.Title>
                }
                extra={[
                  <Space size={'large'}>
                    <EditOutlined onClick={handleEdit(index)} />
                    <Popconfirm
                      title={'Are you sure?'}
                      onConfirm={handleDelete(index)}
                    >
                      <DeleteOutlined />
                    </Popconfirm>
                  </Space>
                ]}
              >
                <Typography.Text>Steps:</Typography.Text>
                <ul>
                  {stage.states.map((state) => (
                    <li key={state}>{state}</li>
                  ))}
                </ul>
              </Card>
            );
        })}
        <Card key={'add-new-stage'}>
          <Form onFinish={onSubmit} form={form}>
            <Form.Item
              key="stage-name"
              label="Stage name"
              name="title"
              labelCol={{ xs: 3 }}
              rules={[
                {
                  required: true,
                  message: 'Stage name is required'
                }
              ]}
            >
              <Input placeholder="Stage name" />
            </Form.Item>
            <Form.Item
              key="states"
              label="States"
              name="states"
              rules={[
                {
                  required: true,
                  message: 'List of stages is required'
                }
              ]}
              labelCol={{ xs: 3 }}
            >
              <Input.TextArea rows={4} placeholder={`States in stage`} />
            </Form.Item>
            <Form.Item
              key="lockedStates"
              label="Asana locked states"
              name="lockedStates"
              labelCol={{ xs: 3 }}
            >
              <Input.TextArea
                rows={3}
                placeholder="States controlled by Asana"
              ></Input.TextArea>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 3 }} key="submit">
              <Button type="primary" htmlType="submit">
                Create
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Space>
    </>
  );
}
