import { TopicState } from '../../generated/graphql';

const labels = {
  [TopicState.Done]: 'Done',
  [TopicState.InProgress]: 'In progress',
  [TopicState.NotStarted]: 'Not started',
  [TopicState.OnHold]: 'On hold',
  [TopicState.Na]: 'NA'
} as Record<TopicState, string>;

interface TopicStateLabelProps {
  value: TopicState;
}

export function TopicStateLabel(props: TopicStateLabelProps) {
  return <>{labels[props.value]}</>;
}
