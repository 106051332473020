import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { authToken } from '../state';

export function useLogout() {
  const navigate = useNavigate();
  const setToken = useSetRecoilState(authToken);

  return async () => {
    setToken(null);
    //@ts-ignore
    global.google.accounts.id.disableAutoSelect();
    navigate('/', {
      replace: true
    });
    window.location.reload();
  };
}
