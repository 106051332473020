import { useBoolean, useDynamicList } from 'ahooks';
import { Button, Divider } from 'antd';
import { useCallback, useEffect, useMemo } from 'react';
import { Scraper } from '../../generated/graphql';
import { Row } from './Row';
import { ValidationSchema } from './ValidationSchema';

export type OnSubmitParams = Array<{
  query: string;
  scrapers: Array<Scraper>;
}>;

export interface ClusterFormProps {
  disabled: boolean;
  initialValues: Array<{
    query: string;
    scrapers: Array<Scraper>;
  }>;

  onSubmit(value: OnSubmitParams): void;
}

export function ClusterForm(props: ClusterFormProps) {
  const [isDataValid, { setTrue: isValid, setFalse: isInvalid }] =
    useBoolean(false);
  const { list, remove, push, replace } = useDynamicList<{
    query: string;
    scrapers: Array<Scraper>;
  }>(props.initialValues);

  function handleSubmit() {
    if (isDataValid) props.onSubmit(list);
  }

  useEffect(() => {
    ValidationSchema.validate(list).then(isValid).catch(isInvalid);
  }, [list, isValid, isInvalid]);

  const addKeyword = useCallback(
    () =>
      push({
        query: '',
        scrapers: []
      }),
    [push]
  );

  const allowAddNew = useMemo(() => {
    if (list.length === 0) {
      return true;
    }
    return isDataValid;
  }, [list, isDataValid]);

  return (
    <form>
      {list.map((item, index) => (
        <Row
          item={item}
          key={index}
          onRemove={() => remove(index)}
          onUpdate={(data) => replace(index, data)}
        />
      ))}
      <Divider />
      <Button onClick={addKeyword} disabled={!allowAddNew}>
        Add keyword
      </Button>
      <Divider />
      <Button type="primary" disabled={props.disabled} onClick={handleSubmit}>
        Create cluster
      </Button>
    </form>
  );
}
