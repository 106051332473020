import { CloseCircleOutlined } from '@ant-design/icons';
import { CreatedByFilter } from '../CreatedByFilter';
import { SubmittedAtFilter } from '../SubmittedAtFilter';
import { TopicCategoryFilter } from '../TopicCategoryFilter';
import { TopicIdFilter } from '../TopicIdFilter';
import { TopicStateFilter } from '../TopicStateFilter';
import { TopicTitleFilter } from '../TopicTitleFilter';
import styles from './Filter.module.css';

export interface FilterProps {
  type: string;
  value: any;
  onDelete: () => void;
  onChange: (value: { type: string; value: any }) => void;
}

export function Filter(props: FilterProps) {
  let FilterInput = null;
  switch (props.type) {
    case TopicIdFilter.type:
      FilterInput = TopicIdFilter;
      break;
    case CreatedByFilter.type:
      FilterInput = CreatedByFilter;
      break;
    case SubmittedAtFilter.type:
      FilterInput = SubmittedAtFilter;
      break;
    case TopicTitleFilter.type:
      FilterInput = TopicTitleFilter;
      break;
    case TopicCategoryFilter.type:
      FilterInput = TopicCategoryFilter;
      break;
    case TopicStateFilter.type:
      FilterInput = TopicStateFilter;
      break;
  }

  return (
    <div className={styles.root}>
      <span className={styles.label}>{FilterInput?.label}</span>
      <div className={styles.input}>
        {FilterInput !== null && <FilterInput {...props} />}
        <CloseCircleOutlined
          onClick={props.onDelete}
          className={styles.closeButton}
        />
      </div>
    </div>
  );
}
