import { Button, Form, Input, Space, Tabs } from 'antd';
import { ChangeEvent } from 'react';
import ReactMarkdown from 'react-markdown';

const { TextArea } = Input;

interface EditorProps {
  submitting: boolean;
  value: string;
  buttonText?: string;

  onChange(event: ChangeEvent<HTMLTextAreaElement>): void;

  onCancel?(): void;

  onSubmit(): void;
}

export function Editor({
  onChange,
  onSubmit,
  submitting,
  value,
  buttonText,
  onCancel
}: EditorProps) {
  return (
    <>
      <Tabs
        size="small"
        defaultActiveKey="0"
        items={[
          {
            label: 'Edit',
            key: '0',
            children: (
              <Form.Item
                extra={
                  <>
                    This editor supports{' '}
                    <a
                      target="_blank"
                      href="https://enterprise.github.com/downloads/en/markdown-cheatsheet.pdf"
                      rel="noreferrer"
                    >
                      Markdown
                    </a>
                  </>
                }
              >
                <TextArea
                  placeholder=""
                  rows={2}
                  onChange={onChange}
                  value={value}
                />
              </Form.Item>
            )
          },
          {
            label: 'Preview',
            key: '1',
            children: <ReactMarkdown children={value} />
          }
        ]}
      />
      <Space>
        <Button
          htmlType="submit"
          loading={submitting}
          onClick={onSubmit}
          type="primary"
        >
          {buttonText || 'Add Comment'}
        </Button>
        {onCancel && <Button onClick={onCancel}>Cancel</Button>}
      </Space>
    </>
  );
}
