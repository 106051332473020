import { Input, InputProps } from 'antd';

interface TopicIdFilterProps {
  value?: string;
  onChange: (value: { type: string; value: any }) => void;
}
export function TopicIdFilter(props: TopicIdFilterProps) {
  const onChange: InputProps['onChange'] = ({ target: { value } }) => {
    props.onChange({
      type: TopicIdFilter.type,
      value
    });
  };

  return (
    <Input
      autoFocus
      onChange={onChange}
      bordered={false}
      defaultValue={props.value}
    />
  );
}

TopicIdFilter.type = 'TOPIC_ID';
TopicIdFilter.label = 'topic id';
