import Avatar from 'boring-avatars';

interface UserAvatarProps {
  name: string;
  size?: number;
  src?: string;
}

export function UserAvatar(props: UserAvatarProps) {
  return (
    <Avatar
      {...props}
      variant="bauhaus"
      colors={['#FC284F', '#FF824A', '#FEA887', '#F6E7F7', '#D1D0D7']}
    />
  );
}

UserAvatar.defaultProps = {
  size: 40
};
