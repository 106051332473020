import { useBoolean } from 'ahooks';
import { Comment as CommentComponent, Popconfirm } from 'antd';
import { DateTime } from 'luxon';
import { ChangeEvent, ReactComponentElement, useState } from 'react';
import { CommentFragmentFragment, useDeleteCommentMutation, useUpdateCommentMutation } from '../../generated/graphql';
import { Editor } from '../Editor';
import styles from './Comment.module.css';

interface CommentProps {
  comment: CommentFragmentFragment;
  markdownComponent: (text: string) => ReactComponentElement<any>;
  onDeleted(): void;

  onUpdated(): void;
}

export function Comment(props: CommentProps) {
  const [commentText, updateCommentText] =
    useState<string | undefined>(undefined);
  const [deleteComment] = useDeleteCommentMutation({
    variables: {
      input: {
        commentId: props.comment.id
      }
    },
    onCompleted: props.onDeleted
  });
  const [updateComment, { loading: submitting }] = useUpdateCommentMutation({
    variables: {
      input: {
        commentId: props.comment.id,
        text: commentText || props.comment.text
      }
    },
    onCompleted: () => {
      props.onUpdated();
      cancelEditMode();
    }
  });

  const [editMode, { setTrue: setEditMode, setFalse: cancelEditMode }] =
    useBoolean(false);

  function onChange(event: ChangeEvent<HTMLTextAreaElement>) {
    updateCommentText(event.target.value);
  }

  return (
    <CommentComponent
      author={props.comment.author.fullName}
      className={styles.comment}
      actions={[
        editMode || (
          <span className={styles.removeCommentButton} onClick={setEditMode}>
            edit
          </span>
        ),
        editMode || (
          <Popconfirm
            key="delete-comment"
            className="delete-comment"
            title={'Are you sure?'}
            onConfirm={() => deleteComment()}
          >
            <span className={styles.removeCommentButton}>remove</span>
          </Popconfirm>
        )
      ]}
      datetime={
        <>
          {DateTime.fromISO(props.comment.createdAt).toLocaleString(
            DateTime.DATETIME_MED
          )}
        </>
      }
      content={
        editMode ? (
          <Editor
            onCancel={cancelEditMode}
            buttonText={'Save'}
            submitting={submitting}
            value={commentText ?? props.comment.text}
            onChange={onChange}
            onSubmit={updateComment}
          />
        ) : (
          props.markdownComponent(props.comment.text)
        )
      }
    />
  );
}
