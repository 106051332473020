import { Button, Select, Space } from 'antd';
import { Scraper } from '../../generated/graphql';

const values = [
  {
    title: Scraper.Amazonbooks,
    value: Scraper.Amazonbooks,
    tag: 'shopping'
  },
  {
    title: Scraper.Ebay,
    value: Scraper.Ebay,
    tag: 'shopping'
  },
  {
    title: Scraper.Googleshopping,
    value: Scraper.Googleshopping,
    tag: 'shopping'
  },
  {
    title: Scraper.Instagram,
    value: Scraper.Instagram,
    tag: 'social'
  },
  {
    title: Scraper.Reddit,
    value: Scraper.Reddit,
    tag: 'social'
  },
  {
    title: Scraper.Searchvolume,
    value: Scraper.Searchvolume,
    tag: 'social'
  },
  {
    title: Scraper.Skillshare,
    value: Scraper.Skillshare,
    tag: 'course'
  },
  {
    title: Scraper.Udemy,
    value: Scraper.Udemy,
    tag: 'course'
  },
  {
    title: Scraper.Youtube,
    value: Scraper.Youtube,
    tag: 'social'
  },
  {
    title: Scraper.Twitter,
    value: Scraper.Twitter,
    tag: 'social'
  },
  {
    title: Scraper.Tiktok,
    value: Scraper.Tiktok,
    tag: 'social'
  },
  {
    title: Scraper.Pinterest,
    value: Scraper.Pinterest,
    tag: 'social'
  },
  {
    title: Scraper.Domestika,
    value: Scraper.Domestika,
    tag: 'course'
  }
];

interface ScraperSelectParams {
  value: Array<Scraper>;

  onChange(scrapers: Array<Scraper>): void;
}

export function ScraperSelect(props: ScraperSelectParams) {
  function chooseByTag(tag: 'all' | 'social' | 'course' | 'shopping') {
    switch (tag) {
      case 'all':
        props.onChange(values.map(({ value }) => value));
        break;
      case 'course':
        props.onChange(
          values.filter((value) => value.tag === tag).map(({ value }) => value)
        );
        break;
      case 'social':
        props.onChange(
          values.filter((value) => value.tag === tag).map(({ value }) => value)
        );
        break;
      case 'shopping':
        props.onChange(
          values.filter((value) => value.tag === tag).map(({ value }) => value)
        );
        break;
    }
  }

  return (
    <Space direction="horizontal" align={'start'}>
      <Select
        {...props}
        mode="multiple"
        allowClear
        placeholder="Set list of scrapers"
        style={{ width: '500px' }}
      >
        {values.map(({ title, value }) => (
          <Select.Option key={value} value={value}>
            {title}
          </Select.Option>
        ))}
      </Select>
      <Space direction={'vertical'}>
        <Button onClick={() => chooseByTag('all')}>All</Button>
        <Button onClick={() => chooseByTag('social')}>All social</Button>
        <Button onClick={() => chooseByTag('course')}>All courses</Button>
        <Button onClick={() => chooseByTag('shopping')}>All shopping</Button>
      </Space>
    </Space>
  );
}
