import {
  Avatar,
  Button,
  Divider,
  PageHeader,
  Skeleton,
  Space,
  Typography
} from 'antd';
import {DateTime} from 'luxon';
import {
  useActivateUserMutation,
  useDisableUserMutation,
  useGetUserQuery,
  useMeQuery,
  User
} from '../generated/graphql';

const {
  Text,
  Paragraph
} = Typography;

interface UserPageProps {
  userId: User['id'];
}

export function UserPage(props: UserPageProps) {
  const {data: me} = useMeQuery();
  const {
    data,
    loading
  } = useGetUserQuery({
    variables: {
      id: props.userId
    }
  });
  const [activateUser] = useActivateUserMutation({
    variables: {
      id: props.userId
    }
  });

  const [disableUser] = useDisableUserMutation({
    variables: {
      id: props.userId
    }
  });

  return (
    <Skeleton loading={loading}>
      <PageHeader
        title={
          <>
            <Avatar srcSet={data?.user?.picture}>
              {data?.user?.firstName[0] || 'N'}
              {data?.user?.lastName[0] || 'N'}
            </Avatar>
            {data?.user?.fullName || 'No name'}{' '}
            {me?.me?.id === data?.user?.id && `(me)`}
          </>
        }
      >
        <Paragraph>
          <Text type={'secondary'}>ID:</Text>{' '}
          <Text copyable>{data?.user?.id}</Text>
        </Paragraph>
        <Paragraph>
          <Text type={'secondary'}>Email:</Text>{' '}
          <Text copyable>{data?.user?.email}</Text>
        </Paragraph>
        <Paragraph>
          <Text type={'secondary'}>Created:</Text>
          <span> </span>
          {DateTime.fromISO(data?.user?.createdAt).toLocaleString()}
        </Paragraph>
        {data?.user?.deletedAt && (
          <Paragraph>
            <Text type={'secondary'}>Deactivated:</Text>
            <span> </span>
            {DateTime.fromISO(data?.user?.deletedAt).toLocaleString()}
          </Paragraph>
        )}
        <Divider/>
        {me?.me?.id !== data?.user?.id && (
          <Space>
            {data?.user?.deletedAt ? (
              <Button onClick={() => activateUser()}>Activate</Button>
            ) : (
              <Button onClick={() => disableUser()}>Disable</Button>
            )}
          </Space>
        )}
      </PageHeader>
    </Skeleton>
  );
}
