import { useCreation, useDebounceEffect, useDynamicList } from 'ahooks';
import { Select, Typography } from 'antd';
import { CreatedByFilter } from '../CreatedByFilter';
import { Filter } from '../Filter';
import { SubmittedAtFilter } from '../SubmittedAtFilter';
import { TopicCategoryFilter } from '../TopicCategoryFilter';
import { TopicIdFilter } from '../TopicIdFilter';
import { TopicStateFilter } from '../TopicStateFilter';
import { TopicTitleFilter } from '../TopicTitleFilter';
import styles from './GlobalSearchInput.module.css';

interface GlobalSearchInputProps {
  values: Array<{ type: string; value: any }>;
  onChange: (value: any) => void;
}

const defaultFiltersLists = [
  {
    label: CreatedByFilter.label,
    value: CreatedByFilter.type
  },
  {
    label: SubmittedAtFilter.label,
    value: SubmittedAtFilter.type
  },
  {
    label: TopicTitleFilter.label,
    value: TopicTitleFilter.type
  },
  {
    label: TopicCategoryFilter.label,
    value: TopicCategoryFilter.type
  },
  {
    label: TopicIdFilter.label,
    value: TopicIdFilter.type
  },
  {
    label: TopicStateFilter.label,
    value: TopicStateFilter.type
  }
];

export function GlobalSearchInput(props: GlobalSearchInputProps) {
  const {
    list: filters,
    remove,
    push,
    getKey,
    replace
  } = useDynamicList<{ type: string; value: any }>(props.values);

  useDebounceEffect(
    () => {
      props.onChange(filters);
    },
    [filters],
    {
      wait: 1000
    }
  );

  function onSelectFilter(value: string) {
    const defaultFilterData = { type: value };
    switch (value) {
      case TopicIdFilter.type:
        push({
          ...defaultFilterData,
          value: ''
        });
        break;
      case CreatedByFilter.type:
        push({
          ...defaultFilterData,
          value: ''
        });
        break;
      case SubmittedAtFilter.type:
        push({
          ...defaultFilterData,
          value: []
        });
        break;
      case TopicTitleFilter.type:
        push({
          ...defaultFilterData,
          value: ''
        });
        break;
      case TopicCategoryFilter.type:
        push({
          ...defaultFilterData,
          value: ''
        });
        break;
      case TopicStateFilter.type:
        push({
          ...defaultFilterData,
          value: ''
        });
        break;
    }
  }

  const options = useCreation(() => {
    return defaultFiltersLists.filter(
      ({ value }) =>
        !props.values.find(({ type }) => {
          return type === value;
        })
    );
  }, [props.values]);

  return (
    <div className={styles.root}>
      <Typography.Title level={5} className={styles.label}>
        Find by
      </Typography.Title>
      {filters.map((filter, index) => (
        <Filter
          key={getKey(index)}
          {...filter}
          onChange={(value) => replace(index, value)}
          onDelete={() => remove(index)}
        />
      ))}
      <Select
        bordered={false}
        placeholder={'Filter by'}
        className={styles.input}
        showSearch
        defaultActiveFirstOption={false}
        dropdownMatchSelectWidth={false}
        autoClearSearchValue
        showArrow={false}
        value={''}
        options={options}
        onSelect={onSelectFilter}
      />
    </div>
  );
}
