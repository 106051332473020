import { useState } from "react";
import { Typography } from "antd";
import { TextProps } from "antd/lib/typography/Text";

const { Text } = Typography;


export function EditableText(props: TextProps) {
    const [visibleIcons, setVisibleIcons] = useState(false);

    const onShowIcons = () => {
        setVisibleIcons(true)
    }

    const onHideIcons = () => {
        setVisibleIcons(false)
    }

    let editableProps: typeof props.editable;

    if (typeof props.editable == 'object') {
        editableProps = { ...props.editable, icon: visibleIcons ? props.editable.icon : <div></div> };
    } else if (typeof props.editable == 'boolean') {
        editableProps = props.editable
    }

    let copyableProps: typeof props.copyable;

    if (typeof props.copyable == 'object') {
        copyableProps = { ...props.copyable, icon: visibleIcons ? props.copyable.icon : <div></div> };
    } else if (typeof props.editable == 'boolean') {
        copyableProps = props.copyable
    }

    return (
        <div onMouseEnter={onShowIcons}
            onMouseLeave={onHideIcons}>
            <Text {...props}
                editable={editableProps}
                copyable={copyableProps}>{props.children}</Text>
        </div>

    )
}