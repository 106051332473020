import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Cascader,
  Form,
  Input,
  message,
  Modal,
  Select,
  Space,
  Typography
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  AddTopicInput,
  GetTopicsCountDocument,
  GetTopicsDocument,
  TopicsCountPeriod,
  useAddTopicMutation,
  useGetCategoriesQuery
} from '../../generated/graphql';
import { useDraftTopics } from '../../hooks/useDraftTopics';
import { topicFiltersForQuery } from '../../state';
import './NewTopicForm.css';

const { Text } = Typography;

const styles = {
  wrongExample: {
    color: 'rgb(255 77 79 / 85%)'
  },
  rightExample: {
    color: 'rgb(82 196 26 / 85%)'
  },
  normal: {
    color: 'rgb(0 0 0 / 85%)'
  }
};

const default_reasons = [
  'I came up with the idea',
  'A friend/family told me about it',
  'Found an influencer who could teach this course',
  'Took inspiration from a competitor course',
  'The topic is related to one of our courses'
];

interface NewTopicFormProps {
  visible?: boolean;
  values?: Partial<AddTopicInput>;
  onClose: () => void;
  draftIndex: number;
}

function updateReasonSelectionOptions(customOtherValue?: string) {
  return [
    ...default_reasons.map((item) => ({
      value: item,
      label: item
    })),
    {
      label: 'Other',
      value: customOtherValue || 'other'
    }
  ];
}

export function NewTopicForm(props: NewTopicFormProps) {
  const [form] = Form.useForm();
  const { data: categories } = useGetCategoriesQuery();
  const filtersForQuery = useRecoilValue(topicFiltersForQuery);
  const [, { delete: deleteDraftTopic, update }] = useDraftTopics();
  const [reasonOptions, setReasonsOptions] = useState(() => {
    return updateReasonSelectionOptions();
  });
  const [showOtherInput, setShowOtherInput] = useState<boolean>(
    !default_reasons.includes(props.values?.howDidYouComeUp as string)
  );

  const [createTopic] = useAddTopicMutation({
    refetchQueries: [
      {
        query: GetTopicsDocument,
        variables: {
          filter: filtersForQuery,
          withAuthor: false
        }
      },
      {
        query: GetTopicsCountDocument,
        variables: {
          period: TopicsCountPeriod.All
        }
      },
      {
        query: GetTopicsCountDocument,
        variables: {
          period: TopicsCountPeriod.Last_30Days
        }
      }
    ]
  });

  useEffect(() => {
    if (props.visible && props.values) {
      form.setFieldsValue(props.values as any);

      if (
        props.values.howDidYouComeUp &&
        !default_reasons.includes(props.values.howDidYouComeUp)
      ) {
        setReasonsOptions(
          updateReasonSelectionOptions(props.values.howDidYouComeUp)
        );
      }
    }
  }, [form, props.values, props.visible]);

  const onOk = async () => {
    message.loading({ content: 'Loading...', key: 'createTopic' });
    try {
      await form.validateFields();
      // split links
      let data = {
        ...form.getFieldsValue(),
        importantLinks: form.getFieldValue('importantLinks')
          ? form
              .getFieldValue('importantLinks')
              .split('\n')
              .map((i: string) => i.trim())
          : []
      };

      await createTopic({
        variables: {
          input: data
        }
      });
      await deleteDraftTopic(props.draftIndex);
      props.onClose();
      form.resetFields();
      message.success({
        content: 'Topic created',
        key: 'createTopic'
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onCancel = async () => {
    update(props.draftIndex, form.getFieldsValue());

    form.resetFields();
    props.onClose();
    message.success({
      content: 'Draft updated',
      key: 'draft'
    });
  };

  function setOtherReason(value: string) {
    form.setFieldValue('howDidYouComeUp', value);
    setReasonsOptions(
      reasonOptions.map((item) => {
        if (item.label === 'Other') {
          return { ...item, value };
        } else {
          return item;
        }
      })
    );
  }

  return (
    <Modal
      width={700}
      open={props.visible}
      title="Create new topic"
      onOk={onOk}
      onCancel={onCancel}
      destroyOnClose
    >
      <Form<AddTopicInput>
        form={form}
        layout="vertical"
        name="nest-messages"
        scrollToFirstError
      >
        <Form.Item
          label={<Text strong>Topic Name</Text>}
          name="title"
          requiredMark
          required
          rules={[
            {
              required: true,
              validator: (_, topicName) => {
                const splitName = topicName.split(' ');
                if (
                  splitName.some(
                    (item: string) =>
                      item.charAt(0) !== item.charAt(0).toUpperCase()
                  )
                ) {
                  return Promise.reject(
                    new Error('Use capital letters properly')
                  );
                } else if (
                  splitName.some((item: string) => /(making|diy)/gi.test(item))
                ) {
                  return Promise.reject(
                    new Error(`Can't contain "making" or "DIY" `)
                  );
                } else if (topicName.toLowerCase().includes('at home')) {
                  return Promise.reject(new Error(`Can't contain "at home" `));
                } else if (
                  splitName.some((item: string) => /courses?/gi.test(item))
                ) {
                  return Promise.reject(new Error(`Can't contain "course" `));
                } else {
                  return Promise.resolve();
                }
              }
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Text type="secondary">
          <ul>
            <li>Check your spelling.</li>
            <li>Use capital letters properly.</li>
            <li>
              Use nouns. Wrong:{' '}
              <span style={styles.wrongExample}>"Baking Croissants"</span>{' '}
              Right: <span style={styles.rightExample}>"Croissants"</span>.
            </li>
            <li>
              Use "homemade" only when it's something that is unlikely to make
              at home.
              <br />
              Wrong:{' '}
              <span style={styles.wrongExample}>
                "Homemade Pizza", "Homemade Lasagna"
              </span>{' '}
              Right:{' '}
              <span style={styles.rightExample}>
                "Homemade Marshmallow", "Homemade Beer".{' '}
              </span>
            </li>
            <li>
              {' '}
              Don't use creatives. Wrong:{' '}
              <span style={styles.wrongExample}>
                "The Complete Art of Cookies"
              </span>{' '}
              Right: <span style={styles.rightExample}>"Cookies"</span>.
            </li>
            <li>
              Don't use <span style={styles.wrongExample}>"making"</span> or{' '}
              <span style={styles.wrongExample}>"DIY"</span>. Wrong:{' '}
              <span style={styles.wrongExample}>"Stuffed Dishes Making"</span>{' '}
              Right: <span style={styles.rightExample}>"Stuffed Dishes"</span>.
            </li>
            <li>
              Don't use <span style={styles.wrongExample}>"at home"</span>.
              Wrong:{' '}
              <span style={styles.wrongExample}>"Roasting nuts at home"</span>{' '}
              Right: <span style={styles.rightExample}>"Nuts Roasting"</span>.
            </li>
            <li>
              Don't use verbs. Wrong:{' '}
              <span style={styles.wrongExample}>"Creating aged vessels"</span>{' '}
              Right: <span style={styles.rightExample}>"Aged Vessels"</span>.
            </li>
            <li>
              Don't add <span style={styles.wrongExample}>"course"</span>.
              Wrong:{' '}
              <span style={styles.wrongExample}>
                "Anti-inflammatory Foods course"
              </span>{' '}
              Right:{' '}
              <span style={styles.rightExample}>"Anti-inflammatory Food"</span>.
            </li>
          </ul>
        </Text>
        <Form.Item
          name="description"
          label={
            <Text>
              <Text strong>Context</Text>
              <br />
              <Text>
                Give some context to the idea by explaining your subject in 1-2
                sentences.
                <br />
                <i>
                  For example: Learn basic recipes, tips and tricks and flavors
                  combinations for making and shaking cocktails
                </i>
              </Text>
            </Text>
          }
          required
          requiredMark
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          name="courseSkill"
          label={
            <Text>
              <Text strong>Course skill</Text>
              <br />
              <Text italic>
                For example: At the end of the course, the student will know how
                to make professional cocktails at home.
              </Text>
            </Text>
          }
          required
          requiredMark
          initialValue="At the end of the course, the student will know how to… "
          rules={[{ required: true }]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label={
            <Text>
              <Text strong>Keywords</Text>
              <br />
              <Text>
                Write 3-5 <b>synonyms</b> or <b>variations</b> of the{' '}
                <b>main topic</b>.
              </Text>
              <br />
              <Text italic>
                For example: Mixology, Homemade Cocktails, Cocktails Making,
                Alcoholic Drinks Recipes
              </Text>
            </Text>
          }
          required
          requiredMark
          rules={[{ required: true }]}
        >
          <Form.List
            name="seedKeywords"
            rules={[
              {
                validator: async (_, keywords) => {
                  if (!keywords || keywords.length < 3) {
                    return Promise.reject(new Error('At least 3 keywords'));
                  }
                }
              }
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field) => (
                  <Form.Item required={false} key={field.key}>
                    <Space>
                      <Form.Item
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'required'
                          }
                        ]}
                        noStyle
                      >
                        <Input />
                      </Form.Item>
                      {fields.length > 0 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                    </Space>
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '60%' }}
                    icon={<PlusOutlined />}
                  >
                    Add
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item
          name="category"
          label={
            <Text>
              <Text strong>Category</Text>
              <br />
              <Text>
                Choose a <Text strong>category</Text> and a{' '}
                <Text strong>subcategory</Text> that most closely match the
                topic.
              </Text>
            </Text>
          }
          required
          requiredMark
          rules={[
            {
              required: true
            }
          ]}
        >
          <Cascader options={categories?.categories} />
        </Form.Item>
        <Form.Item
          required={false}
          name="importantLinks"
          label={
            <Text>
              <Text strong>Links (optional)</Text>
              <br />
              Insert YouTube videos, social media profiles, blogs, other online
              courses, and any other links that helped you come up with ideas
              for the topic.
            </Text>
          }
        >
          <Input.TextArea
            placeholder={'https://example.com\r\nhttps://example.net'}
          />
        </Form.Item>
        <Form.Item
          label={
            <Text strong>How did you come up with the topic? (optional)</Text>
          }
          name="howDidYouComeUp"
        >
          <Select
            options={reasonOptions}
            onChange={(value) => {
              form.setFieldValue('howDidYouComeUp', value);
              setShowOtherInput(!default_reasons.includes(value));
            }}
          />
        </Form.Item>
        {showOtherInput && (
          <Form.Item>
            <Input
              placeholder="Other description here.."
              value={form.getFieldValue('howDidYouComeUp')}
              onChange={(event) => setOtherReason(event.target.value)}
            />
          </Form.Item>
        )}
        <Form.Item
          name="creatorsLinks"
          label={
            <Text>
              <Text strong>Potential Creators (optional)</Text>
              <br />
              <Text>
                You may add a creator who would be a good fit to teach this
                course - if you have a close relationship with them and/or they
                are interested in working with us. Please make sure they meet
                the following requirements:
              </Text>
              <ul>
                <li>Between 30K-600K followers on Instagram/Tiktok</li>
                <li>More followers than following</li>
                <li>Proficient English</li>
              </ul>
            </Text>
          }
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          name="howTos"
          label={
            <Text>
              <Text strong>How To's (optional)</Text>
              <br />
              <Text>
                Write the “How to...” that is most relevant to your topic.
                <br />
                <i>For example: How to make cocktails at home</i>
              </Text>
            </Text>
          }
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          name="redditLink"
          label={
            <Text>
              <Text strong>Reddit community (optional)</Text>
              <br />
              <Text>
                Attach the most suitable subreddit for this topic.
                <br />
                For example:
                <a
                  href="https://www.reddit.com/r/cocktails/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.reddit.com/r/cocktails/
                </a>
              </Text>
            </Text>
          }
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
