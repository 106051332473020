import {
  AddTopicInput,
  useGetTopicDraftsQuery,
  useSaveDraftTopicsMutation
} from '../generated/graphql';

type DraftTopicsHandler = [
  Partial<AddTopicInput>[],
  {
    add: (newTopic: Partial<AddTopicInput>) => Promise<void>;
    update: (
      index: number,
      newTopicData: Partial<AddTopicInput>
    ) => Promise<void>;
    delete: (index: number) => Promise<void>;
  }
];

export function useDraftTopics(): DraftTopicsHandler {
  const [save] = useSaveDraftTopicsMutation();
  const { data: draftTopics } = useGetTopicDraftsQuery();

  const saveTopicCallback = async (
    index: number,
    topic: Partial<AddTopicInput>
  ) => {
    const updatedList = draftTopics?.drafts.map((item, _index) => {
      if (_index === index) {
        return topic;
      } else {
        return item;
      }
    });

    await save({
      variables: {
        input: {
          topics: updatedList || []
        }
      }
    });
  };

  const addTopicCallback = async (data: Partial<AddTopicInput>) => {
    const topics = [data, ...(draftTopics?.drafts || [])];
    await save({ variables: { input: { topics } } });
  };

  const deleteTopicCallback = async (index: number) => {
    const topics = (draftTopics?.drafts || []).reduce(
      (previousValue, currentValue, currIndex) => {
        if (currIndex !== index) {
          return [...previousValue, currentValue];
        } else {
          return previousValue;
        }
      },
      [] as Partial<AddTopicInput>[]
    );

    await save({ variables: { input: { topics } } });
  };

  return [
    draftTopics?.drafts || [],
    {
      add: addTopicCallback,
      update: saveTopicCallback,
      delete: deleteTopicCallback
    }
  ];
}
